import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../data/base";
import gsap from "gsap";
import play_icon from "../../assets/icon/play_icon.svg";
import styles from "./ARShades3dsTamplate.module.css"; // Import del CSS Module

const ARShades3dsTemplate = forwardRef((props, ref) => {
  const { templateId, variantId } = useParams();
  const modelViewerRef = useRef(null);
  const hotspotContentRef = useRef(null);
  const [steps, setSteps] = useState([]);
  const [modelUrl, setModelUrl] = useState("");
  const [animationAvailable, setAnimationAvailable] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [currentHotspotIndex, setCurrentHotspotIndex] = useState(0);
  const [visibleContentIndex, setVisibleContentIndex] = useState(null);
  const [visibleHotspots, setVisibleHotspots] = useState([]);
  const [animationButtonText, setAnimationButtonText] = useState("APPLY CLIP ON");
  const [animationButtonTextBack, setAnimationButtonTextBack] = useState("REMOVE CLIP-ONS");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const templateDoc = await db
          .collection("3DViewerAnimation")
          .doc(templateId)
          .get();

        if (templateDoc.exists) {
          const variantDoc = await db
            .collection("3DViewerAnimation")
            .doc(templateId)
            .collection("Variant")
            .doc(variantId)
            .get();

          if (variantDoc.exists) {
            const variantData = variantDoc.data();
            setSteps(variantData.steps || []);
            setModelUrl(variantData.modelUrl || "");
          } else {
            console.error("Variant not found");
          }
        } else {
          console.error("Template not found");
        }
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchTemplateData();
  }, [templateId, variantId]);

  useEffect(() => {
    if (modelViewerRef.current) {
      const modelViewer = modelViewerRef.current;
      const handleInteraction = () => {
        modelViewer.removeEventListener("pointerdown", handleInteraction);
      };

      modelViewer.addEventListener("pointerdown", handleInteraction);

      return () => {
        modelViewer.removeEventListener("pointerdown", handleInteraction);
      };
    }
  }, [modelViewerRef]);

  const startAnimation = async () => {
    const modelViewer = modelViewerRef.current;
    modelViewer.addEventListener("finished", handleAnimationFinished);
    if (!animationStarted && animationAvailable) {
      modelViewer.animationName = "explosion_glasses_an_1";
      modelViewer.timeScale = 1;
      modelViewer.interpolationDecay = 200;

      modelViewer.cameraOrbit = "0deg 147.6deg 0.6727m";
      modelViewer.fieldOfView = "37deg";
      modelViewer.cameraTarget = "0.0m 0.03m 0.030m";

      setTimeout(() => {
        setAnimationStarted(true);
        setVisibleHotspots([0]);
        setIsButtonDisabled(true);
        modelViewer.play({ repetitions: 1 });
        setTimeout(() => {
          modelViewer.pause();
          setIsButtonDisabled(false);
          modelViewer.currentTime = 3.000000000000003;
        }, 3000);
      }, 400);
    } else {
      modelViewer.currentTime = 3.000000000000003;
      await modelViewer.updateComplete;

      modelViewer.play({ repetitions: 1 });
      setIsButtonDisabled(true);

      modelViewer.cameraOrbit = "20.26deg 87.4deg 0.4259m";
      modelViewer.fieldOfView = "45deg";
      modelViewer.cameraTarget = "0.0m 0.03m 0.01m";

      setVisibleHotspots([]);
      setVisibleContentIndex(null);
    }
  };

  const handleAnimationFinished = () => {
    setIsButtonDisabled(false);
    setAnimationStarted(false);
  };

  useEffect(() => {
    if (visibleContentIndex !== null && hotspotContentRef.current) {
      // Animazione di ingresso quando il contenuto viene montato
      animateContentIn(hotspotContentRef.current);
    }
  }, [visibleContentIndex]);
  

  const handleHotspotClick = (index) => {
    setCurrentHotspotIndex(index);
  
    if (visibleContentIndex !== null && hotspotContentRef.current) {
      // Animazione di uscita
      animateContentOut(hotspotContentRef.current, () => {
        setVisibleContentIndex(index);
        // Animazione di ingresso
        animateContentIn(hotspotContentRef.current);
      });
    } else {
      // Nessun contenuto visibile, mostra direttamente il nuovo contenuto con animazione
      setVisibleContentIndex(index);
      animateContentIn(hotspotContentRef.current);
    }
  
    // Aggiorna gli hotspot visibili in base all'indice corrente
    if (index === 0) {
      setVisibleHotspots([0, 1]); // Mostra hotspot 1 e 2
    } else if (index === steps.length - 1) {
      setVisibleHotspots([index - 1]); // Mostra solo l'hotspot precedente
    } else {
      setVisibleHotspots([index - 1, index + 1]); // Mostra gli hotspot precedente e successivo
    }
  
    // Imposta le proprietà della fotocamera in base allo step selezionato
    if (modelViewerRef.current && steps[index]) {
      const modelViewer = modelViewerRef.current;
      const step = steps[index];
      modelViewer.cameraOrbit = step.cameraOrbit;
      modelViewer.cameraTarget = step.cameraTarget;
      modelViewer.fieldOfView = step.fieldOfView;
    }
  };
  
  const animateContentIn = (element) => {
    gsap.fromTo(
      element,
      { x: "100vw", opacity: 0 }, // Partenza fuori schermo a destra
      { x: "-50%", opacity: 1, duration: 1, ease: "power3.out" } // Entrata e centratura tramite GSAP
    );
  };
  
  const animateContentOut = (element, onComplete) => {
    gsap.to(element, {
      x: "-100vw", // Uscita verso sinistra, fuori schermo
      opacity: 0,
      duration: 0.5,
      ease: "power3.in",
      onComplete: onComplete,
    });
  };
  
  
  
  
  

  useEffect(() => {
    const detectTablet = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      return width >= 768 && height >= 600;
    };
    setIsTablet(detectTablet());
  }, []);

  useEffect(() => {
    if (modelViewerRef.current !== null) {
      const modelViewer = modelViewerRef.current;

      modelViewer.addEventListener("load", () => {
        if (modelViewer.cameraOrbit) {
          modelViewer.cameraOrbit = "20.26deg 87.4deg 0.4259m";
          modelViewer.fieldOfView = "45deg";
        }

        modelViewer.maxCameraOrbit = "auto auto 0.5m";
        modelViewer.minCameraOrbit = "auto auto 0.3m";

        if (modelViewer.availableAnimations[0]) {
          if (modelViewer.availableAnimations[0] === "explosion_glasses_an_1") {
            modelViewer.animationName = "explosion_glasses_an_1";
            setAnimationButtonText("EXPAND");
            setAnimationButtonTextBack("COME BACK");
          } else {
            modelViewer.animationName = "Clip on full";
          }
          setAnimationAvailable(true);
        }
      });
    }
  }, []);

  return (
    <div className={`${styles.containerModelviewer} ${animationStarted ? styles.expandedContainer : ""}`}>
    <model-viewer
  ref={modelViewerRef}
  className={animationStarted ? styles.expandedModelViewer : ""}
  style={{
    width: "100%",
    position: "absolute",
    height: "100%",
    transition: "height 0.5s ease",
    display: "absolute",
  }}
  interaction-prompt="none"
  disable-pan
  exposure="1.1"
  src={modelUrl}
  tone-mapping="commerce"
  shadow-intensity="0"
  interpolation-decay="1000"
          camera-controls
  camera-orbit="0deg 117.6deg 0.6727m"
  field-of-view="45deg"
  camera-target="0.0m 0.03m 0.01m"
>

  <div slot="progress-bar">
    <div className={styles.updateBar}></div>
  </div>

  {steps.length > 0 &&
    steps.map((step, index) => (
      <button
        key={step.id}
        className={`${styles.pulsingCircle} ${
          visibleHotspots.includes(index) ? "" : styles.hotspotHidden
        }`}
        slot={`hotspot-${step.id}`}
        data-visibility-attribute="visible"
        data-surface={step?.dataPosition}
        onClick={() => handleHotspotClick(index)}
      >
        {step.id}
      </button>
    ))}
</model-viewer>

      {animationAvailable && (
        <button
          className={`${styles.clippedButton}`}
          onClick={startAnimation}
          disabled={isButtonDisabled}
        >
          <img src={play_icon} alt="Play Icon" className={styles.clippedIconButton} />
          {!animationStarted ? animationButtonText : animationButtonTextBack}
        </button>
      )}
      {visibleContentIndex !== null && steps[visibleContentIndex]?.content && (
        <div ref={hotspotContentRef} className={styles.hotspotContent}>
          {steps[visibleContentIndex]?.content}
        </div>
      )}
    </div>
  );
});

export default ARShades3dsTemplate;
