import React, { useEffect, useMemo, useState } from 'react';
import { Row, Spinner } from 'reactstrap';
import { getDurationFromSecond } from '../../../utils';
import { getLinearGraphData } from '../../charts/chartjscharts/chartjsData';
import SummaryGraphs from '../../appnew/analytics/SummaryGraphs';
import SummaryCard from '../../appnew/analytics/SummaryCard';
import _ from 'lodash';
import { getTimeInSecs } from './analyticsHelper';

const overviewLabels = [
  'totalUser',
  'totalSession',
  'totalNewUser',
  'totalAvgTime',
  'totalAvgEndTime',
  'totalAvgSpU',
];
const AnalyticsOverviewCard = ({ getData, loading, aggregateList = [] }) => {
  const [userData, setUserData] = useState({});
  const [newUserData, setNewUserData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [avgTimeData, setAvgTimeData] = useState({});
  const [avgEngTimeData, setAvgEngTimeData] = useState({});
  const [avgSessionPerUserData, setAvgSessionPerUserData] = useState({});
  const [selectedDataIndex, setSelectedDataIndex] = useState(0);
  const handleDataIndexChange = (index) => () => {
    setSelectedDataIndex(index);
  };

  const sortArrByDate = ({ data, labels }) => {
    const dataArray = labels.map((label, index) => ({
      label,
      value: data[index],
    }));
    dataArray.sort((a, b) => Date.parse(a.label) - Date.parse(b.label));
    return {
      data: dataArray.map((item) => item.value),
      labels: dataArray.map((item) => item.label),
    };
  };

  const sumDuplicates = ({ data, labels }) => {
    const labelSumMap = {};

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const value = data[i];
      if (labelSumMap[label]) {
        labelSumMap[label] += value;
      } else {
        labelSumMap[label] = value;
      }
    }

    const uniqueLabels = Object.keys(labelSumMap);
    return {
      data: uniqueLabels.map((label) => labelSumMap[label]),
      labels: uniqueLabels,
    };
  };

  const cleanData = ({ data, labels }) => {
    // sum duplicates
    const uniqueData = sumDuplicates({ data, labels });
    // sort by date
    const sortedData = sortArrByDate({
      data: uniqueData.data,
      labels: uniqueData.labels,
    });
    return sortedData;
  };

  function convertiSecondiAMinuti(secondi) {
    var minuti = Math.floor(secondi / 60); // Calcola il numero intero dei minuti
    var secondiRimanenti = secondi % 60; // Calcola i secondi rimanenti

    var risultato = minuti + secondiRimanenti / 100; // Combina i minuti e i secondi

    var risultatoLimitato = risultato.toFixed(2); // Limita il risultato ai primi due numeri decimali
    return parseFloat(risultatoLimitato); // Restituisce il valore numerico come float
  }
  

  useEffect(() => {
    const userRecord = {};
    const newUserRecord = {};
    const sessionRecord = {};
    const avgTimeRecord = {};
    const avgEngTimeRecord = {};
    const avgSessionPerUserRecord = {};
    // const dateCount = {};
    let date = new Date();
    let key = '';
    let data = null;
    let sortedData = {};
    // const userSet = new Set();
    // const userVisitTracker = new Set();
    // const userIsNew = new Set();
    let totalUsers = 0;
    let totalNewUsers = 0;
    let totalSession = 0;
    let totalAvgEngTime = 0;
    let totalAvgSessionTime = 0;
    let totalSessionPeruser = 0;
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
  
    aggregateList.forEach((item) => {
      const dateF = new Date(item.date);
      date = `${dateF.getDate()}/${months[dateF.getMonth()]}/${dateF.getFullYear()}`;
      
      totalUsers += item.totalUniqueUsers;
      totalSession += item.totalSessions;
      totalNewUsers += item.newUsersCount;
      totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
      totalSessionPeruser += item.averageSessionsPerUser;
      totalAvgEngTime += getTimeInSecs(item.avgEngagementTime);
      
      key = `${date}`;
      userRecord[date] = userRecord[date] ? userRecord[date] + item.totalUniqueUsers : item.totalUniqueUsers;
      newUserRecord[date] = newUserRecord[date] ? newUserRecord[date] + item.newUsersCount : item.newUsersCount;
      sessionRecord[date] = sessionRecord[date] ? sessionRecord[date] + item.totalSessions : item.totalSessions;
  
      if (!avgTimeRecord[date]) {
        avgTimeRecord[date] = { totalSeconds: 0, totalSessions: 0 };
      }
      avgTimeRecord[date].totalSeconds += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
      avgTimeRecord[date].totalSessions += item.totalSessions;
  
      if (!avgEngTimeRecord[date]) {
        avgEngTimeRecord[date] = 0;
      }
      avgEngTimeRecord[date] += getTimeInSecs(item.avgEngagementTime) / item.totalUniqueUsers;
  
      avgSessionPerUserRecord[date] = (avgSessionPerUserRecord[date] || 0) + (item.totalUniqueUsers === 0 ? 0 : (item.totalSessions / item.totalUniqueUsers));
    });
  
    const avgTimeRecordCopy = JSON.parse(JSON.stringify(avgTimeRecord));
    
    // Calculate final average times after the loop
    Object.keys(avgTimeRecord).forEach(date => {
      avgTimeRecord[date] = convertiSecondiAMinuti(avgTimeRecord[date].totalSeconds / avgTimeRecord[date].totalSessions);
    });
  
    // Convert avgEngTimeRecord to minutes and fix to 2 decimal places
    Object.keys(avgEngTimeRecord).forEach(date => {
      avgEngTimeRecord[date] = convertiSecondiAMinuti(avgTimeRecordCopy[date].totalSeconds / userRecord[date]);
    });
  
    // set user data
    data = getLinearGraphData('User', userRecord);
  
    // sum duplicates and sort
    sortedData = cleanData({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
  
    setUserData({ data, total: totalUsers });
  
    // set new user data
    data = getLinearGraphData('New User', newUserRecord);
  
    // sum duplicates and sort
    sortedData = cleanData({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
  
    setNewUserData({ data, total: totalNewUsers });
  
    // set session data
    data = getLinearGraphData('Session', sessionRecord);
    // sort by date
    sortedData = sortArrByDate({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
    setSessionData({ data, total: totalSession });
  
    // set avg time data
    data = getLinearGraphData('Avg Time', avgTimeRecord);
  
    // sum duplicates and sort
    sortedData = cleanData({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
    
    setAvgTimeData({
      data,
      total: convertiSecondiAMinuti(totalAvgSessionTime / totalSession)
    });
  
    // set avg eng time data
    data = getLinearGraphData('Avg Eng Time', avgEngTimeRecord);
    
    // sum duplicates and sort
    sortedData = cleanData({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
  
    setAvgEngTimeData({
      data,
      total: convertiSecondiAMinuti(totalAvgSessionTime / totalUsers),
    });
  
    data = getLinearGraphData('Avg S/U', avgSessionPerUserRecord);
  
    // sum duplicates and sort
    sortedData = cleanData({
      data: data.datasets[0].data,
      labels: data.labels,
    });
    data.labels = sortedData.labels;
    data.datasets[0].data = sortedData.data;
  
    setAvgSessionPerUserData({
      data,
      total: totalSession !== 0 && totalUsers !== 0 ? (totalSession / totalUsers).toFixed(2) : 0,
    });
  }, [aggregateList]);
  
  
  

  const graphList = useMemo(
    () => [
      userData,
      sessionData,
      newUserData,
      avgTimeData,
      avgEngTimeData,
      avgSessionPerUserData,
    ],
    [
      userData,
      sessionData,
      newUserData,
      avgTimeData,
      avgEngTimeData,
      
      ,
    ]
  );

  const graphData = graphList[selectedDataIndex].data;

  // const generateTooltip = (label) => {
  //   return `Questo è il tooltip per ${label}`;
  // };

  useEffect(() => {
    const data = {
      user: userData?.data?.datasets[0].data,
      session: sessionData?.data?.datasets[0].data,
      newUser: newUserData?.data?.datasets[0].data,
      avgTime: avgTimeData?.data?.datasets[0].data,
      avgEngTime: avgEngTimeData?.data?.datasets[0].data,
      avgSpU: avgSessionPerUserData?.data?.datasets[0].data,
      dates: userData?.data?.labels,
    };
    for (let i = 0; i < overviewLabels.length; i++) {
      data[overviewLabels[i]] = graphList[i].total ?? 0;
    }
    getData(data);
  }, [graphData, graphList]);
  return (
    <>
      <Row style={{ margin: '2rem 0rem', gap: '2rem 0rem' }}>
        {graphList.map((item, idx) => (
          <SummaryCard
            key={`${item?.total || 0}_${idx}`}
            loading={loading}
            value={item?.total || 0}
            active={idx === selectedDataIndex}
            label={item.data?.datasets[0].label}
            onClick={handleDataIndexChange(idx)}
          />
        ))}
      </Row>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center p-5 '>
          <Spinner />
        </div>
      ) : (
        <SummaryGraphs data={graphData || { datasets: [], labels: [] }} />
      )}
    </>
  );
};

export default AnalyticsOverviewCard;
