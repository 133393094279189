import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ButtonArshades.module.css"
import { isMobile } from "react-device-detect";
import { db } from "../data/base"
import ModalQR from "../component/ui-element/ModalQR";

function ButtonArshades() {
    const params = useParams();
    const bgColor = "#fff";
    const fgColor = "#000";
    
    function closeModal() {
        setIsModalOpen(false)
       
    }
    const [isModalOpen, setIsModalOpen] = useState(false);  
      const history = useHistory();
    const [userLanguage, setLanguage] = useState("en-EN")
    const [docRefQr,setdocRefQr] = useState("")
    const [link, setLink] = useState("")

      useEffect(async () => {
        let document
         const docRef = db.collection("Occhiale").doc(params.TokenArshades);
         await docRef.get().then((doc) => {
             document = doc.data()
            setLink(document.url_shadow);
            if(!isMobile){
              setdocRefQr(db.collection("QrCodeSession").doc())
            }
          });
        //   console.log(link)
        setLanguage(window.navigator.userLanguage || window.navigator.language);

        
      },[]);

      function actionOpenModal(){
           setIsModalOpen(true)
          //  console.log(docRefQr.id)
           let data= {link:link}
           db.collection("QrCodeSession").doc(docRefQr.id).set(data)
       }

    return (
        <>
        <div className={styles.content}>
        {isMobile && (
             <a href={link} target="blank">
             <div className={styles.containerButton}>
                 <div className={styles.itemIcon}>
                 <img
                src={require("../assets/images/logo/ARSHADES-2.svg")}
                alt=""
                className={styles.iconArshades}/>
                 </div>
                 <div className={styles.itemText}>
                     {userLanguage === "it-IT" && (
                         <p>Prova Questo Occhiale</p>
                     )}
                      {userLanguage !== "it-IT" && (
                    <p> Try this glass on </p>
                      )}
                 </div>    
                 <div className={styles.itemTextHovered}>
                 {userLanguage === "it-IT" && (
                         <p>Con il nostro virtual try-on</p>
                     )}
                      {userLanguage !== "it-IT" && (
                    <p> With our virtual try-on
                    </p>
                      )}
                 </div>    
             </div>
         </a>
        )}
        {!isMobile && (
        
             <div className={styles.containerButton} onMouseOver={actionOpenModal} onMouseLeave={closeModal}>
                 <div className={styles.itemIcon}>
                 <img
                src={require("../assets/images/logo/ARSHADES-2.svg")}
                alt=""
                className={styles.iconArshades}/>
                 </div>
                 <div className={styles.itemText}>
                 {userLanguage === "it-IT" ? 
                         <p>Prova Questo Occhiale</p>
                     :
                     <p> Try this glass on </p>
                     }
                    
                 </div>    
                 <div className={styles.itemTextHovered}>

                 {userLanguage === "it-IT" ?
                         <p>Con il nostro virtual try-on</p>
                     :   
                    <p> With our virtual try-on </p>
                      }
                 </div>    
             </div>
         
        )}
        {isModalOpen && (
            <ModalQR fgColor={fgColor} bgColor={bgColor} link={`https://studio.arshades.it/ArshadesButtonQr/${docRefQr.id}`} message={userLanguage === "it-IT" ? "scannerizza il Qr-Code e provalo sul tuo dispositivo mobile" : "scan with your mobile and try this glass"}
            />
        )

        }
        </div>
        </>

    );
}

export default ButtonArshades;