import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import React from "react";
// Funzione per ottenere i dati della sidebar
export const getSidebarData = () => {
  const canPlaceOrders = localStorage.getItem('canPlaceOrders') === 'true';

  const sidebarData = [
    {
      title: "Home",
      path: "/home",
      icon: <RiIcons.RiHomeLine className="nav-icon h4" />,
      hoverIcon: <RiIcons.RiHomeLine className="" />,
      cName: "nav-text",
      isAdmin: false
    },
    {
      title: "3D AR Viewer",
      path: `${process.env.PUBLIC_URL}/3dviewers`,
      icon: <MdIcons.MdOutlineViewInAr className="nav-icon h4" />,
      hoverIcon: <MdIcons.MdOutlineViewInAr className="" />,
      cName: "nav-text",
      isAdmin: false
    },
    {
      title: "Frame Validation",
      path: `${process.env.PUBLIC_URL}/validation`,
      icon: <MdIcons.MdVerified className="nav-icon h4" />,
      hoverIcon: <MdIcons.MdVerified className="" />,
      cName: "nav-text",
      hasNotifications: true,
      notificationCount: 10,
      isAdmin: false
    },
    {
      title: "Analytics",
      path: `${process.env.PUBLIC_URL}/analytics`,
      icon: <FaIcons.FaChartLine className="nav-icon h4" />,
      hoverIcon: <FaIcons.FaChartLine className="" />,
      cName: "nav-text",
      isAdmin: false
    },
    {
      title: "Data Consumption",
      path: "/dataConsumption",
      icon: <MdIcons.MdDataSaverOn className="nav-icon h4" />,
      hoverIcon: <MdIcons.MdDataSaverOn className="" />,
      cName: "nav-text",
      isAdmin: false
    },
    {
      title: "Account",
      path: `${process.env.PUBLIC_URL}/profile`,
      icon: <AiIcons.AiFillSetting className="nav-icon h4" />,
      hoverIcon: <AiIcons.AiFillSetting className="" />,
      cName: "nav-text",
      isAdmin: false
    },
    {
      title: "Admin",
      path: `${process.env.PUBLIC_URL}/admin/configureDb`,
      icon: <FaIcons.FaDatabase className="nav-icon h4" />,
      hoverIcon: <FaIcons.FaDatabase className="" />,
      cName: "nav-text",
      isAdmin: true
    }
  ];

  if (canPlaceOrders) {
    sidebarData.push({
      title: "Order Page",
      path: `${process.env.PUBLIC_URL}/orderPage`,
      icon: <MdIcons.MdShoppingCart className="nav-icon h4" />,
      hoverIcon: <MdIcons.MdShoppingCart className="" />,
      cName: "nav-text",
      isAdmin: false
    });
  }

  return sidebarData;
};
