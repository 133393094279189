import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aste: "",
  frame_width: "",
  lenti_height: "",
  lenti_width: "",
  size: "",
  ponte: "",
  lista_ref_model: [],
  model_list: [],
};

const taglia = createSlice({
  name: "taglia",
  initialState,
  reducers: {
    setAste(state, action) {
      state.aste = action.payload;
    },
    setFrameWidth(state, action) {
      state.frame_width = action.payload;
    },
    setLentiHeight(state, action) {
      state.lenti_height = action.payload;
    },
    setLentiWidth(state, action) {
      state.lenti_width = action.payload;
    },
    setListaRefModel(state, action) {
      state.lista_ref_model = action.payload;
    },
    setModelList(state, action) {
      state.model_list = action.payload;
    },
    pushToModelList(state, action) {
      state.model_list.push(action.payload);
    },
    setModelListElement(state, action) {
      const {index, value} = action.payload;
      state.model_list[index] = value;
    },
    spliceToModelList(state) {
      const i = state.model_list.length;
      state.model_list.splice(i - i - 1);
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setPonte(state, action) {
      state.ponte = action.payload;
    }
  },
});

export const tagliaActions = taglia.actions;

export default taglia.reducer;
