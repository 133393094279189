import React from 'react';
import styles from "./ModalIframe.module.css";
import ReactDom from 'react-dom';

function ModalIframe(props) {
  return ReactDom.createPortal(
    <div className={styles["modal"]}>
      <div className={styles["modal-dialog"]}>
        <div className={styles["modal-body"]}>{props.children}</div>
      </div>
    </div>,  document.getElementById('portal')
  )
}

export default ModalIframe;