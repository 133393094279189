import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { getHdr } from './HDRConstant.js'; // Aggiusta il percorso del file se necessario
import ButtonAr from "./ButtonAr";
import BackDropTransparent from "./BackDropTransparent";
import styles from "./ProjectModelView.module.css";
import "../../index.css";
import playIcon from '../../assets/icon/play_icon.svg';

function ProjectModelViewer(props) {
  const project = useSelector((state) => state.brandViewer);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const mirror = queryParams?.get('mirror');
  const selected = props.initialModel;
  const isEditModeOn = props.isEditModeOn;

  const [isWebView, setIsWebView] = useState(false);
  const modelViewerRef = useRef();

  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const [isReverseMode, setIsReverseMode] = useState(false);
  const isAnimated = queryParams?.get('animationSettings');
  const [isUsingProjectSettings, setIsUsingProjectSettings] = useState(false);
  const [isValidatedMode, setIsValidateMode] = useState(false);

  const [isFirstAnimation, setIsFirstAnimation] = useState(false);
  const [isAnimatedOn, setIsAnimatedOn] = useState(false);
  const [hasAnimations, setHasAnimations] = useState(false); // Stato per verificare se ci sono animazioni disponibili
  // const animationName = "explosion_glasses_an_1"; // Nome dell'animazione
  // const animationDuration = 3.0; // Durata dell'animazione in secondi
  const [animationDuration , setAnimationDuration] = useState(0) 
  const [ animationName , setAnimationName ] = useState("")
  const [animationTitle , setAnimationTitle] = useState("EXPAND");
  const modelAnimations = props.modelAnimations;
  
  // Cerca l'animazione corrente in base a isAnimated
  const currentAnimation = modelAnimations?.find(animation =>
    animation.animationName === isAnimated || isValidatedMode
  );
 
  useEffect(() => {
    if (queryParams?.get('sceneSettings') === 'neutral' && !shouldHideSwitchButton()) {
      setIsValidateMode(true);
    }
  }, [isValidatedMode]);

  // Verifica la presenza di animazioni solo dopo che il modello è stato caricato
  useEffect(() => {
    const modelViewer = modelViewerRef.current;
    
    const handleLoad = () => {
      // Controlla se ci sono animazioni disponibili
      const animationsAvailable = modelViewer.availableAnimations && modelViewer.availableAnimations.length > 0;
      setHasAnimations(animationsAvailable);
      modelViewer.animationName = modelViewer.availableAnimations[0];
      setAnimationName(modelViewer.availableAnimations[0]);
      if(modelViewer.availableAnimations[0] === "explosion_glasses_an_1"){
        setAnimationDuration(3.0)
        setAnimationTitle("EXPAND")
      }else {
        setAnimationDuration(1.2)
        setAnimationTitle("APPLY CLIP-ONS")

      }
      console.log("Animazioni disponibili:", animationsAvailable);
    };

    if (modelViewer) {
      modelViewer.addEventListener('load', handleLoad);
    }

    return () => {
      if (modelViewer) {
        modelViewer.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  const toggleSettings = () => {
    setIsUsingProjectSettings((prevState) => !prevState);
  };

  const startAnimation = async () => {
 
 
    const modelViewer = modelViewerRef.current;
    console.log(modelViewer.duration)
    // setAnimationDuration(modelViewer.duration);

    modelViewer.addEventListener('finished', handleAnimationFinished);

    if (!isFirstAnimation) {
      // Avvia l'animazione
      // modelViewer.animationName = animationName;
      modelViewer.timeScale = 1;
      modelViewer.interpolationDecay = 200;

      // modelViewer.cameraOrbit = "0deg 147.6deg 0.6727m";
      // modelViewer.fieldOfView = "37deg";
      // modelViewer.cameraTarget = "0.0m 0.03m 0.030m";
      setTimeout(() => {
        if (props.onAnimationStateChange) {
          props.onAnimationStateChange(true);
        }

        setTimeout(async () => {
          setIsAnimatedOn(true);
          await modelViewer.updateComplete;
          modelViewer.play({ repetitions: 1 });
          setIsFirstAnimation(true);
          // Ferma l'animazione a 3 secondi
          setTimeout(() => {
            modelViewer.pause();
            console.log(animationDuration)
            modelViewer.currentTime = animationDuration; // Ferma alla fine dell'animazione
            setAnimationTitle("Come Back");
            setIsAnimatedOn(false);
          }, (1000 * animationDuration));
        }, 500);
      }, 400);
    } else {
      // Ripristina l'animazione all'indietro
      modelViewer.currentTime = animationDuration; // Inizia dall'ultimo frame
      await modelViewer.updateComplete;
      // modelViewer.cameraOrbit = "20.26deg 87.4deg 0.4259m";
      // modelViewer.fieldOfView = "25deg"; 
      modelViewer.timeScale = -1; // Riproduzione all'indietro
      setIsAnimatedOn(true);
      modelViewer.play({ repetitions: 1 });
    

    }
  };

  const handleAnimationFinished = () => {
    console.log("Animation Finished");
    if (props.onAnimationStateChange) {
      props.onAnimationStateChange(false);
    }
    console.log("FINISH")
    setIsFirstAnimation(false);
    setAnimationTitle("Expand");
    setIsAnimatedOn(false);
  };
  
  async function loadModel(url) {
    const modelViewer = modelViewerRef.current;
    try {
      if (!url) {
        return;
      }
      const response = await fetch(url);
      if (!response.ok) {
        console.error(`Errore nel caricamento del modello. Status: ${response.status}`);
        const errorText = await response.text();
        console.error('Corpo della risposta:', errorText);
        return;
      }
      const contentType = response.headers.get('Content-Type');
      if (!contentType || (!contentType.includes('model/gltf-binary') && !contentType.includes('application/octet-stream'))) {
        if (contentType.includes('text/html')) {
          console.warn('Contenuto HTML ricevuto invece di un modello');
          return;
        }
        console.error('Tipo di contenuto inaspettato:', contentType);
        const responseText = await response.text();
        console.error('Corpo della risposta:', responseText);
        return;
      }
      const blob = await response.blob();
      const urlBlob = URL.createObjectURL(blob);
    
      if (modelViewer) {
        modelViewer.src = urlBlob;
      }
    } catch (error) {
      console.error('Errore nel caricamento del modello:', error);
    }
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isWebView = /\bwv\b/.test(userAgent);
    if (isWebView || mirror) {
      setIsWebView(true);
    }
  });

  const modelInView = useSelector((state) =>
    state.modelGlasses.find((model) => model.id === selected)
  );

  const [positionMenu, setPositionMenu] = useState("bottom");
  useEffect(() => {
    setPositionMenu(project[0]?.menu_position);
  }, []);

  const [url, setUrl] = useState("");
  const [fieldOfView, setFieldOfView] = useState(
    project[0]?.fieldOfView ?? "22deg"
  );

  const urlVista = props.urlView;

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setFieldOfView("32deg");
    }
  }, []);

  useEffect(() => {
    if (!props.singleView) {
      if (modelInView?.urlGlbComplete) {
        loadModel(modelInView?.urlGlbComplete);
      }
    }
    if (project && project[0]) {
      loadModel(project[0].url);
    }
  }, [project]);

  useEffect(() => {
    if (props.singleView) {
      loadModel(props.singleSrc);
      setUrl(props.singleSrc);
    }
  }, [props.singleSrc]);

  const hdrNum = project[0]?.hdr;
  const hdrPath = getHdr(hdrNum);

  const defaultValues = {
    backgroundColor: "#ffffff",
    background_image: "",
    background_boolean_image: false,
  };

  const shouldHideSwitchButton = () => {
    return (
      project[0]?.background_color === defaultValues.backgroundColor &&
      project[0]?.background_image === defaultValues.background_image &&
      ((project[0]?.background_boolean_image === "true") === defaultValues.background_boolean_image) && hdrPath === 0
    );
  };

  const MODEL_INSTANCE = {
    src: isEditModeOn ? "" : project[0]?.url,
    alt: project[0]?.alt,
    cameraControls: isWebView ? true : project[0]?.cameraControls,
    autoRotate: isWebView ? false : project[0]?.autoRotate,
    disableZoom: isWebView ? false : project[0]?.disableZoom,
    lightExposition: project[0]?.lightExposition,
    shadowSmooth: project[0]?.shadowSmooth,
    shadowIntensity: project[0]?.shadowIntensity,
    poster: modelInView?.poster,
    ios: modelInView?.ios,
    hdr: isValidatedMode && !isUsingProjectSettings ? null : hdrPath,
    ar: "true",
    background_image: isValidatedMode && !isUsingProjectSettings ? "" : project[0]?.background_image,
    skybox: project[0]?.transparent ? "" : project[0]?.skybox,
    fieldOfView: project[0]?.fieldOfView,
    backgroundColor:
      isValidatedMode && !isUsingProjectSettings
        ? "#ffffff"
        : !project[0]?.transparent && !isWebView
        ? project[0]?.background_color ?? "#ffffff00"
        : "#ffffff00",
    background_boolean_image:
      isValidatedMode && !isUsingProjectSettings
        ? false
        : project[0]?.background_boolean_image === "true",
    title: project[0]?.modelTitle ?? false,
    exposure: project[0]?.lightExposition ?? "1.0",
    ref: modelViewerRef,
    cameraOrbit: project[0]?.cameraOrbit ?? "",
  };

  function castValues(value, type) {
    return !value ? null : value;
  }

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      :root {
        --poster-color: rgba(255, 255, 255, 0);
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    function onProgress(e) {
      setProgress(Math.ceil(e.detail.totalProgress * 100));
    }

    function onLoad() {
      setIsLoaded(true);
    }

    const modelViewer = document.querySelector("model-viewer");
    modelViewer.addEventListener("progress", onProgress);
    modelViewer.addEventListener("load", onLoad);

    return () => {
      modelViewer.removeEventListener("progress", onProgress);
      modelViewer.removeEventListener("load", onLoad);
    };
  }, []);

  useEffect(() => {
    const modelViewer = modelViewerRef.current;

    const handleLoad = () => {
      if (modelViewer) {
        modelViewer.cameraOrbit = project[0]?.cameraOrbit || "auto auto 120deg";
        modelViewer.cameraControls = true;
      }
    };

    if (modelViewer) {
      modelViewer.addEventListener('load', handleLoad);
      return () => modelViewer.removeEventListener('load', handleLoad);
    }
  }, [project[0]?.cameraOrbit]);

  return (
    <>
      {progress < 100 && !isLoaded && (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <div className={styles.message}>Loading...</div>
        </div>
      )}

      {progress < 100 && <BackDropTransparent white />}
      {window.innerWidth < 700 && (
        <model-viewer
          src={isEditModeOn ? MODEL_INSTANCE.src : ""}
          disable-tap
          disable-pan
          alt={MODEL_INSTANCE.alt}
          ref={MODEL_INSTANCE.ref}
          camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-control")}
          auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
          rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
          disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
          exposure={MODEL_INSTANCE.exposure}
          shadow-intensity={MODEL_INSTANCE.shadowIntensity}
          shadow-softness={MODEL_INSTANCE.shadowSmooth}
          field-of-view={MODEL_INSTANCE.fieldOfView}
          min-camera-orbit='auto auto 200%'
          max-camera-orbit='auto auto 200%'
          min-field-of-view='24deg'
          max-field-of-view='30deg'
          ar={isWebView}
          ar-modes={"webxr quick-look scene-viewer"}
          environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}
          skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
          allow="xr-spatial-tracking"
          progress
          style={
            isMobile && !props.singleView
              ? {
                height: "83%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundPosition: 'bottom center',
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",
                backgroundSize: 'cover',
                backgroundPosition: 'bottom center',
              }
              : {
                height: "100%",
                width: "100%",
                backgroundColor: MODEL_INSTANCE.backgroundColor,
                backgroundPosition: 'bottom center',
                backgroundSize: 'cover',
                backgroundImage: MODEL_INSTANCE.background_boolean_image
                  ? `url(${MODEL_INSTANCE.background_image})`
                  : "",

              }
          }
          id={
            props?.project?.menu_position === "left" ||
              props?.project?.menu_position === "right"
              ? "projectTwo"
              : "project"
          }
        >
          <div id="progress-bar-id" slot="progress-bar"></div>
          <div id="lazy-load-poster" slot="poster"></div>

          {props.multiViewer && MODEL_INSTANCE.title && (
            <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
          )}

          <ButtonAr project={project} urlView={urlVista} />
          {hasAnimations && (
              <div className={styles.animationContainer}  style={{ display: isAnimatedOn ? 'none' : 'block' }}>
                <button onClick={startAnimation} className={styles.animation_button}>
                <img 
  src={playIcon} 
  className={styles.clipped_icon_button} 
 
/>
                    {animationTitle}
                </button>
              </div>
            )}
        </model-viewer>
      )}
      {window.innerWidth > 700 && (
        <>
          <div className={styles.viewAction}>
            {isValidatedMode && !shouldHideSwitchButton() && (
              <button onClick={toggleSettings} className={styles.validationButton}>
                {isUsingProjectSettings
                  ? "Validation Mode"
                  : "Public Mode"}
              </button>
            )}
          </div>

          <model-viewer
            disable-tap
            src={isEditModeOn ? MODEL_INSTANCE.src : ""}
            disable-pan
            alt={MODEL_INSTANCE.alt}
            ref={MODEL_INSTANCE.ref}
            camera-controls={castValues(MODEL_INSTANCE.cameraControls, "camera-controls")}
            auto-rotate={castValues(MODEL_INSTANCE.autoRotate, "auto-rotate")}
            rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
            disable-zoom={MODEL_INSTANCE.disableZoom || undefined}
            exposure={MODEL_INSTANCE.exposure}
            shadow-intensity={MODEL_INSTANCE.shadowIntensity}
            shadow-softness={MODEL_INSTANCE.shadowSmooth}
            camera-orbit={isEditModeOn ? MODEL_INSTANCE.cameraOrbit : ""}
            field-of-view={MODEL_INSTANCE.fieldOfView}
            min-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
            max-camera-orbit={window.innerWidth < 1224 ? 'auto auto 180%' : 'auto auto 120%'}
            min-field-of-view='6deg'
            max-field-of-view='32deg'
            ar={isWebView}
            ar-modes={"webxr quick-look scene-viewer"}
            environment-image={MODEL_INSTANCE.hdr ? MODEL_INSTANCE.hdr : null}
            xr-environment
            skybox-image={MODEL_INSTANCE.skybox ? MODEL_INSTANCE.hdr : null}
            allow="xr-spatial-tracking"
            progress
            style={
              props.singleView ||
                props?.project?.menu_position === "left" ||
                props?.project?.menu_position === "right"
                ? {
                  height: "100%",
                  width: "100%",
                  backgroundColor: MODEL_INSTANCE.backgroundColor,
                  backgroundSize: 'cover',
                  backgroundImage: MODEL_INSTANCE.background_boolean_image
                    ? `url(${MODEL_INSTANCE.background_image})`
                    : "",
                }
                : {
                  height: "85%",
                  width: "100%",
                  backgroundColor: MODEL_INSTANCE.backgroundColor,
                  backgroundSize: 'cover',
                  backgroundImage: MODEL_INSTANCE.background_boolean_image
                    ? `url(${MODEL_INSTANCE.background_image})`
                    : "",
                }
            }
            id={
              props?.project?.menu_position === "left" ||
                props?.project?.menu_position === "right"
                ? "projectTwo"
                : "project"
            }
          >
            <div id="progress-bar-id" slot="progress-bar"></div>

            {props.multiViewer && MODEL_INSTANCE.title && (
              <h3 className={styles.nomeOcchiale}>{modelInView?.nomeOcchiale}</h3>
            )}

            <ButtonAr project={project} urlView={urlVista} />

            {/* Visualizzazione dei pulsanti di animazione */}
            {hasAnimations && (
              <div className={styles.animationContainer}  style={{ display: isAnimatedOn ? 'none' : 'block' }}>
                <button onClick={startAnimation} className={styles.animation_button}>
                <img 
  src={playIcon} 
  className={styles.clipped_icon_button} 
 
/>
                    {animationTitle}
                </button>
              </div>
            )}
          </model-viewer>
        </>
      )}
    </>
  );
}

export default ProjectModelViewer;
