import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Input, Table } from "reactstrap";
import { ActionButtons } from "../../common/ActionButtons";
import { toast } from "react-toastify";
import app, { db } from "../../../data/base";
import { Accordion } from '../../cards/Accordion';

export const AssociatedBrands = ({ userInfo }) => {
  const [loading, setLoading] = useState(false);
  const [associatedBrands, setAssociatedBrands] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [editRow, setEditRow] = useState(-1);
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const getData = async () => {
    try {
      fetchSubs();
    } catch {
      // toast.error("Error while fetching data!");
    }
  };

  const fetchSubs = async () => {
    const refClient = localStorage.getItem('ref_cliente');
    const refEmail = localStorage.getItem('email');
    const profileCatalogsRef = localStorage.getItem('brands');

    try {
      const profileRef = await db.collection("Profile").doc(refEmail.toLowerCase()).get();
      const profileData = profileRef.data();
      let subsSnapshot = null;

      if (profileData.role === "Admin") {
        subsSnapshot = await db.collection("ARShades_Subscription")
          .where("isRefactored", "==", true)
          .get();
      } else {
        subsSnapshot = await db.collection("ARShades_Subscription")
          .where("isRefactored", "==", true)
          .where("refClient", "==", refClient)
          .get();
      }
    
      const clientRef = await db.collection("Client").doc(refClient).get();
      const clientData = clientRef.data();
      const subs = subsSnapshot.docs.map(doc => {
        const data = doc.data();
    
        const found = clientData.mainProfileList.includes(refEmail.toLowerCase());
    
        if (found) {
          return {
            id: doc.id,
            subscriptionType: data.subscriptionType || "N/A",
            startDate: data.startDate || null,
            endDate: data.endDate || null,
            store: data.store || null,
            status: data.status || "Attivo"
          };
        } else {
          const hasMatchingCatalog = data.catalogList && data.catalogList.catalogRefList && data.catalogList.catalogRefList.length > 0 && 
            data.catalogList.catalogRefList.some(catalogRef => profileCatalogsRef.includes(catalogRef));
    
          if (hasMatchingCatalog) {
            return {
              id: doc.id,
              subscriptionType: data.subscriptionType || "N/A",
              startDate: data.startDate || null,
              endDate: data.endDate || null,
              store: data.store || null,
              status: data.status || "Attivo"
            };
          } else {
            return null;
          }
        }
      }).filter(sub => sub !== null);

      // Ordina le sottoscrizioni in base al tipo
      const orderedSubs = subs.sort((a, b) => {
        const order = ["ARShades Branded License", "ARShades Additional Branded License", "ARShades Smart License"];
        return order.indexOf(a.subscriptionType) - order.indexOf(b.subscriptionType);
      });

      setSubscriptions(orderedSubs);
    } catch (error) {
      console.error("Errore durante il recupero degli abbonamenti:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteRow = async ({ id }) => {
    // Implementazione della cancellazione
  };

  const onEdit = async () => {
    // Implementazione dell'edit
  };

  const isAdmin = userInfo?.role === "Admin";

  return (
    <>
      <div className="shadow-sm m-t-20">
        <div
          className="p-20 rounded text-white"
          style={{ backgroundColor: "#42B1AC" }}
        >
          <h5>Licence</h5>
        </div>
      </div>

      <Container className="p-20">
        <Table>
          <thead>
            <tr>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Name</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Start Date</th>
              <th style={{ backgroundColor: 'white', color: 'black' }} >End Date</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Store</th>
              <th style={{ backgroundColor: 'white', color: 'black' }}>Status</th>
            </tr>
          </thead>
          <tbody>

            {subscriptions.map((subscription, index) => (
              <Accordion key={index} subscription={subscription} />
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};