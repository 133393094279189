import { SET_SELECTED_BRAND } from "../actionType";

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_BRAND:
      return {
        ...state,
        ...action.brand,
      };

    default:
      return state;
  }
};
