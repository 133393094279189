import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { brandViewerActions } from "../../redux/brandViewer/brandViewer";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../data/base";
import ModelContainerFrontEnd from "../../component/modelViewer/ModelContainerFrontEnd";
import ProjectModelViewer from "../../component/modelViewer/ProjectModelView";
import CardModelView from "../../component/modelViewer/CardModelView";
import MenuContainer from "../../component/modelViewer/MenuContainer";
import { modelGlassesActions } from "../../redux/brandViewer/modelGlasses";
import Password from "../../component/modelViewer/Password";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';

function ARShades3dsViewer({
  isEditModeOn,
  containerHeight,
  containerWidth,
  setCurrentVariant = () => { },
  _setListVariant = () => { },
}) {
  const params = useParams();
  const dispatch = useDispatch();

  const [idViewer, setIdViewer] = useState();

  const [listVariant, setListVariant] = useState([]);
  const [lock, setLock] = useState(false);
  const [initialModel, setInitialModel] = useState();
  // eslint-disable-next-line
  const [modelHistory, setModelHistory] = useState([]);
  const [pwd, setPwd] = useState("");
  // eslint-disable-next-line
  const [initialTime, setInitialTime] = useState();

  const brand = params.TokenBrand;
  const glasses = params.TokenGlasses;
  const project = useSelector((state) => state.brandViewer);

  const [glassesRef, setGlassesRef] = useState(null);
  const [lineRefs, setLineRefs] = useState(null);
  const [glassesSize, setGlassesSize] = useState(null);
  const modelInView = useSelector((state) =>
    state.modelGlasses.find((model) => model.id === initialModel)
  );

  const [initialModelTime, setInitiaModellTime] = useState(new Date());

  const viewer = document.querySelector("model-viewer");

  const [positionMenu, setPositionMenu] = useState("bottom");

  // Per salvarsi l'id del nuovo documento della sessione
  const [documentId, setDocumentId] = useState(null);

  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);

  const [modelAnimations, setModelAnimations] = useState([]);


  useEffect(() => {
    // if (window.innerWidth <= 700) {
    //   setPositionMenu("bottom");
    // } else {
    setPositionMenu(project[0]?.menu_position);
    // }
  }, [project[0]]);

  const [session, setSession] = useState({
    device_id: "",
    device: "",
    device_os: "",
    is_first_session: false,
    ref_catalogo: "",
    data_inizio_sessione: "",
    data_fine_user_session: "",
    glassesRefs: [],
    lista_occhiali_visualizzati: [],
    modelRefs: [],
    lineaRefs: [],
    glassesRefsDoc: null,
    total_time: 0,
    glassesSize: null,
  });

  const [refBrand, setRefBrand] = useState();

  useEffect(async () => {
    const visualizzatoreRef = await db
      .collection("Visualizzatori3D")
      ?.doc(brand)
      .get();
    const data = visualizzatoreRef.data();

    setIdViewer(data.id);
    dispatch(brandViewerActions.setSelectedProject(data));
    const date = new Date();
    setInitialTime(date);
  }, []);

  useEffect(async () => {
    if (idViewer) {
      const glassesRef = await db
        ?.collection("Visualizzatori3D")
        ?.doc(idViewer)
        .collection("Glasses")
        ?.doc(glasses)
        .get();

      setListVariant(glassesRef?.data()?.lista_varianti);
      setInitialModel(glassesRef?.data()?.initial_model);

      const modelRef = await db
        ?.collection("Modello")
        ?.doc(glassesRef?.data().initial_model)
        .get();
      // console.log("src", modelRef.data().urlGlbComplete);
      const animations = modelRef?.data()?.modelAnimation || [];
      console.log("animation", animations)
      setModelAnimations(animations);

      setModelHistory([...modelHistory, modelRef?.data().urlGlbComplete]);
    }
  }, [idViewer]);

  useEffect(async () => {
    const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
    const data = docRef.data();
    setLock(data.lock);
    if (data.lock) {
      setPwd(data.password);
    }
  }, []);

  useEffect(() => {
    if (listVariant) {
      listVariant.map(async (v) => {
        const variant = await db.collection("Modello").doc(v).get();
        dispatch(
          modelGlassesActions.setModel({ data: variant?.data(), id: v })
        );
      });
    }
  }, [listVariant]);

  useEffect(async () => {
    // Attempt to get device ID using IP
    let deviceID = uuidv4(); // Fallback to a UUID if fetching IP fails

    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      deviceID = res.data.ip;
    } catch (error) {
      console.error("Failed to fetch IP address:", error);
    }

    session.device_id = deviceID;

    let sameMonthYear = false;
    const padWithZero = (number) => number.toString().padStart(2, "0");

    const date = new Date(); // Usa il tuo oggetto date
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1); // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)
    const monthDataCons = date.getMonth() + 1; // Aggiungi 1 al mese, poiché i mesi sono basati su zero (0-11)

    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());

    const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
    const docBrand = docRef.data().brand;
    const brandRefDoc = db.collection("Brand").doc(docBrand);
    const brandRef = await brandRefDoc.get();
    const doc = brandRef?.data()?.data_consumption;
    const contatoriRef = await db.collection("Contatori_Brand")?.doc(doc).get();
    const data = contatoriRef.data();
    const glassesRefDoc = db.collection("Occhiale")?.doc(glasses);
    const glassesDoc = await glassesRefDoc.get();
    const sizeRefDoc = glassesDoc.data().lista_taglie[0];

    // console.log(glassesRefDoc.data().lineaRef);
    // console.log(glassesRefDoc)
    setGlassesRef(glassesRefDoc);
    setLineRefs(glassesDoc.data().lineaRef);
    setGlassesSize(sizeRefDoc);
    setRefBrand(brandRefDoc);

    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Apple Safari";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      browserName = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
    } else {
      browserName = "Unknown";
    }

    session.device_os = browserName;

    // Check if the user has visited the site before
    const isFirstSession = !Cookies.get("visited");

    if (!Cookies.get("visited")) {
      Cookies.set("visited", "true");
      // console.log(Cookies);
    }

    session.glassesSize = sizeRefDoc;
    session.is_first_session = isFirstSession;
    // console.log(isFirstSession);
    session.glassesRefsDoc = glassesRefDoc;
    session.lineaRefs.push(glassesDoc.data().lineaRef);
    session.glassesRefs.push(glassesDoc.id);
    session.ref_catalogo = brandRefDoc;
    session.data_inizio_sessione = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    data.bandwidth_3dviewer.map((bandw, i) => {
      if (bandw.month === monthDataCons && bandw.year === year) {
        sameMonthYear = true;
      }
    });
    const contatori = await db.collection("Contatori_Brand")?.doc(doc);
    if (!sameMonthYear) {
      contatori.update("bandwidth_3dviewer", [
        ...data.bandwidth_3dviewer,
        { bandwidth_month: 0, calls_number: 0, month: monthDataCons, year },
      ]);
    }
  }, []);

  useEffect(() => {
    if (viewer?.src) {
      setModelHistory([...modelHistory, viewer?.src]);
    }
  }, [viewer?.src]);

  const [listCreated, setListCreated] = useState(false);

  function getMeseCorrente() {
    const dataOdierna = new Date();
    const meseCorrente = dataOdierna.getMonth() + 1; // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return meseCorrente;
  }

  function getCurrentYear() {
    const dataOdierna = new Date();
    const annoCorrente = dataOdierna.getFullYear(); // Aggiungi 1 perché i mesi sono indicizzati da 0 a 11
    return annoCorrente;
  }

  // Velocizzato con map e non con il for (bandwidth_3dviewer)
  useEffect(() => {
    if (!initialModel || lock) return;

    const updateBandwidth = async () => {
      const variant = await db.collection("Modello")?.doc(initialModel).get();
      const src = variant.data().urlGlbComplete;
      const fileImg = await fetch(src).then((r) => r.blob());
      const sizeMb = fileImg.size / 1000000;
      const banda = parseFloat(sizeMb.toFixed(2)) + 0.5;

      const docRef = await db.collection("Visualizzatori3D")?.doc(brand).get();
      const docBrand = docRef.data().brand;
      const brandRef = await db.collection("Brand")?.doc(docBrand).get();
      const doc = brandRef?.data()?.data_consumption;
      const contatoriRef = await db.collection("Contatori_Brand")?.doc(doc).get();

      const data = contatoriRef.data();
      const size = data.bandwidth_3dviewer.length - 1;
      const meseCorrente = getMeseCorrente();
      const year = getCurrentYear();

      const updatedData = data.bandwidth_3dviewer.map((ele) =>
        ele.month === meseCorrente && ele.year === year
          ? { ...ele, bandwidth_month: ele.bandwidth_month + banda, calls_number: ele.calls_number + 1 }
          : ele
      );

      await db.collection("Contatori_Brand")?.doc(doc).update({ bandwidth_3dviewer: updatedData });
    };

    setTimeout(async () => {
      await updateBandwidth();
      await updateSession();
    }, 10);

  }, [initialModel, lock]);

  /// SESSIONE MULTIPLA AGGIORNATA ///

  const updateSession = async () => {
    // Ottieni il riferimento al documento del modello specificato
    const modelRefId = db.collection("Modello").doc(initialModel);
    const modelRef = await modelRefId.get();
    const modelData = modelRef.data();

    // Calcola il tempo trascorso dall'inizio della visualizzazione del modello
    const date = new Date();
    const timeDiff = Math.floor((date.getTime() - initialModelTime.getTime()) / 1000);

    // Aggiorna il tempo di inizio del modello
    setInitiaModellTime(date);

    // Ottieni il blob del modello e calcola la dimensione del file in MB
    const url = modelData.urlGlbComplete;
    const blob = await fetch(url).then((r) => r.blob());
    const banda = parseFloat((blob.size / 1000000).toFixed(2)); // Calcola la banda in MB

    // Se la lista non è ancora stata creata, inizializza la sessione
    if (!listCreated) {
      session.modelRefs = listVariant;
      session.device = window.navigator.userAgent;

      session.lista_occhiali_visualizzati.push({
        nome_occhiale: modelData.nomeOcchiale,
        lineaRef: session.lineaRefs[0],
        glassesRef: session.glassesRefsDoc,
        listaModelliProvati: [{
          modelRef: modelRefId,
          timeStart: date,
          totalSeconds: 0,
          tagliaRef: session.glassesSize,
        }],
        timeStart: date,
        banda_consumata: banda,
        date: date,
        totalSeconds: 0,
      });

      setListCreated(true); // Segna che la lista è stata creata
    } else {
      // Se la lista è già stata creata, aggiorna i dati della sessione
      const currentModel = session.lista_occhiali_visualizzati[0];
      const listModelTry = currentModel.listaModelliProvati;
      let modelFound = false;

      // Aggiorna il tempo per il modello corrente
      listModelTry.forEach(model => {
        if (model.timeStart) {
          const modelTimeDiff = Math.floor((date.getTime() - model.timeStart.getTime()) / 1000);
          model.totalSeconds += modelTimeDiff;
          model.timeStart = null; // Ferma il tempo per questo modello
        }
      });

      // Trova il modello corrente e aggiorna il suo tempo di inizio
      const currentModelData = listModelTry.find(model => model.modelRef.id === modelRefId.id);
      if (currentModelData) {
        currentModelData.timeStart = date;
        modelFound = true;
      } else {
        listModelTry.push({
          modelRef: modelRefId,
          timeStart: date,
          totalSeconds: 0,
          tagliaRef: session.glassesSize,
        });
      }

      // Calcola la somma dei totalSeconds per i modelli provati
      const totalModelSeconds = listModelTry.reduce((sum, model) => sum + model.totalSeconds, 0);

      session.lista_occhiali_visualizzati[0] = {
        ...currentModel,
        nome_occhiale: modelData.nomeOcchiale,
        lineaRef: lineRefs,
        glassesRef: glassesRef,
        listaModelliProvati: listModelTry,
        timeStart: date,
        banda_consumata: currentModel.banda_consumata + banda,
        totalSeconds: totalModelSeconds, // Aggiorna totalSeconds con la somma dei totalSeconds dei modelli
      };
    }
  };

  useEffect(() => {
    // Funzione asincrona per creare un documento nella collezione Firestore
    const createDocument = async () => {
      try {
        // Dati iniziali del documento
        const initialData = {};
  
        // Riferimento alla collezione 'Sessione_Visualizzatori3d'
        const sessioneRef = db.collection("Sessione_Visualizzatori3d");
  
        // Aggiunge un nuovo documento e ottiene il riferimento al documento creato
        const docRef = await sessioneRef.add(initialData);
  
        // Imposta l'ID del documento nello stato
        setDocumentId(docRef.id);
        setSessionStartTime(new Date()); // Imposta l'ora di inizio della sessione
      } catch (error) {
        // Gestione degli errori nella creazione del documento
        console.error("Error creating document:", error);
      }
    };
  
    // Se il documento non è stato creato, crea un nuovo documento
    if (documentId === null) {
      createDocument();
    }
  
    // Imposta un intervallo per chiamare 'handleUnload' ogni 10 secondi
    const intervalId = setInterval(() => {
      if (documentId !== null && !sessionExpired) {
        handleUnload(documentId);
      }
    }, 10000);
  
    // Imposta un timer per fermare la sessione dopo 4 minuti
    const expirationTimerId = setTimeout(() => {
      setSessionExpired(true);
    }, 240000); // TIMER DI 4 MINUTI
  
    // Listener per l'evento beforeunload
    const handleBeforeUnload = (event) => {
      if (documentId !== null && !sessionExpired) {
        handleUnload(documentId);
      }
  
      // Imposta il messaggio di avviso per la chiusura della pagina
      // const message = "Stai per uscire dalla pagina. Sei sicuro?";
      // event.returnValue = message;
      // return message;
    };
  
    // Aggiungi il listener per l'evento beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    // Funzione di cleanup per pulire l'intervallo, il timer e il listener quando il componente viene smontato o le dipendenze cambiano
    return () => {
      clearInterval(intervalId);
      clearTimeout(expirationTimerId);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [session, documentId, sessionExpired]);  

  async function handleUnload(docId) {
    // Se la sessione è scaduta, esci dalla funzione
    if (sessionExpired) {
      return;
    }

    // Funzione per aggiungere uno zero davanti ai numeri singoli
    const padWithZero = (number) => number.toString().padStart(2, "0");

    const date = new Date();
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1);
    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());

    // Aggiorna i dati di sessione per ogni occhiale visualizzato
    session.lista_occhiali_visualizzati.forEach(item => {
      const currentTime = new Date().getTime();

      // Aggiorna il tempo totale se c'è un tempo di inizio
      if (item.timeStart) {
        const elapsedTime = Math.floor((currentTime - item.timeStart.getTime()) / 1000);
        if (elapsedTime > 0) {
          item.totalSeconds += elapsedTime;
          item.timeStart = new Date(currentTime);
        }
      }

      // Aggiorna il tempo per ogni modello provato
      item.listaModelliProvati.forEach(model => {
        if (model.timeStart) {
          const modelElapsedTime = Math.floor((currentTime - model.timeStart.getTime()) / 1000);
          if (modelElapsedTime > 0) {
            model.totalSeconds += modelElapsedTime;
            model.timeStart = new Date(currentTime);
          }
        }
      });

      // Calcola la somma dei totalSeconds per i modelli provati
      const totalModelSeconds = item.listaModelliProvati.reduce((sum, model) => sum + model.totalSeconds, 0);
      item.totalSeconds = totalModelSeconds;
    });

    // Calcola il tempo totale della sessione
    let totalTime = 0;
    session.lista_occhiali_visualizzati.forEach(item => {
      totalTime += item.totalSeconds;
    });
    session.total_time = totalTime;
    session.data_fine_user_session = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    // Prepara i dati della sessione da salvare, escludendo i tempi di inizio
    const sessionToSave = {
      ...session,
      lista_occhiali_visualizzati: session.lista_occhiali_visualizzati.map(item => {
        const { timeStart, ...itemWithoutStartTime } = item;
        return {
          ...itemWithoutStartTime,
          listaModelliProvati: item.listaModelliProvati.map(model => {
            const { timeStart, ...modelWithoutStartTime } = model;
            return modelWithoutStartTime;
          })
        };
      })
    };

    const sessioneRef = db.collection("Sessione_Visualizzatori3d").doc(docId);

    try {
      // Aggiorna il documento della sessione in Firestore
      await sessioneRef.update(sessionToSave);
    } catch (error) {
      // Gestione degli errori nell'aggiornamento della sessione
      console.error("Error updating session in Firestore:", error);
    }
  }

  /// FINE ///

  function selecteModelInView(project, modelId) {
    setModelHistory([...modelHistory, viewer?.src]);
    setInitialModel(modelId);
  }

  function setSelectedId(modelId) {
    // console.log("selected");
    // console.log(modelId);
    selecteModelInView(project[0], modelId);
  }

  useEffect(() => {
    if (initialModel) {
      setCurrentVariant(initialModel);
      setVariantData(initialModel);
    }
  }, [initialModel]);

  useEffect(() => {
    if (listVariant && listVariant.length) {
      _setListVariant(listVariant);
    }
  }, [listVariant]);

  const setVariantData = async (initialModel) => {
    const variantRef = await db
      .collection("Visualizzatori3D")
      .doc(brand)
      .collection("Glasses")
      ?.doc(glasses)
      .collection("variants")
      .doc(initialModel)
      .get();

    const data = variantRef.data();
    if (data && data.shouldApply)
      return dispatch(brandViewerActions.setSelectedProject(data));

    const modelRef = await db
      .collection("Visualizzatori3D")
      .doc(brand)
      .collection("Glasses")
      ?.doc(params?.TokenGlasses)
      .collection("template")
      ?.doc("ARTemplate").get()

    const modelData = modelRef.data();

    if (modelData && modelData?.shouldApply)
      return dispatch(brandViewerActions.setSelectedProject(modelData));

    const visualizzatoreRef = await db
      .collection("Visualizzatori3D")
      ?.doc(brand)
      .get();
    const rootData = visualizzatoreRef.data();
    return dispatch(brandViewerActions.setSelectedProject(rootData));
  };

  function unlock() {
    setLock(false);
  }

  return (
    <div>
      {lock ? (
        <Password unlock={unlock} password={pwd} />
      ) : (
        <ModelContainerFrontEnd
          modelSelected={modelInView}
          project={project[0]}
          isEditModeOn={isEditModeOn}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
        >
          {positionMenu === "top" && (
            <MenuContainer
              style={"TOP"}
              menu={project[0]?.menu_type}
              childCount={listVariant.length}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
          {positionMenu === "left" && (
            <MenuContainer
              style={"LEFT"}
              menu={project[0]?.menu_type}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
          {!navigator.userAgent.match(/FBAV/i) && (
            <ProjectModelViewer
              multiViewer
              title={project.title}
              initialModel={initialModel}
              project={project[0]}
              paramsId={params.TokenGlasses}
              urlView={`https://studio.arshades.it/Arshades3ds/${project[0]?.id}/glasses/${params.TokenGlasses}`}
              viewerPage
              isEditModeOn={isEditModeOn}
              modelAnimations ={modelAnimations}
            ></ProjectModelViewer>
          )}
          {navigator.userAgent.match(/FBAV/i) &&
            !navigator.userAgent.match(/(iPod|iPhone|iPad)/) && (
              <div
                id={
                  project?.menu_position === "left" ||
                    project?.menu_position === "right"
                    ? "projectTwo"
                    : "project"
                }
              >
                <img
                  src={modelInView?.not_supported_image}
                  style={{ width: "100%" }}
                ></img>
                il broswer di facebook non supporta modelli 3D e realtà
                aumentata. prova con un altro broswer
              </div>
            )}
          {positionMenu === "bottom" && (
            <>
              <MenuContainer
                style={"BOTTOM"}
                menu={project[0]?.menu_type}
                key={project[0]?.menu_type}
                childCount={listVariant.length}
                selectedId={initialModel}
                setSelectedId={setSelectedId}
              >
                {listVariant?.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={initialModel}
                    project={project[0]}
                    selectModel={() =>
                      selecteModelInView(project[0], modelIdCard)
                    }
                    cardStyle={project[0]?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            </>
          )}

          {positionMenu === "right" && (
            <MenuContainer
              style={"RIGHT"}
              menu={project[0]?.menu_type}
              key={project[0]?.menu_type}
            >
              {listVariant.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={initialModel}
                  project={project[0]}
                  selectModel={() =>
                    selecteModelInView(project[0], modelIdCard)
                  }
                  cardStyle={project[0]?.menu_type}
                ></CardModelView>
              ))}
            </MenuContainer>
          )}
        </ModelContainerFrontEnd>
      )}
    </div>
  );
}

export default ARShades3dsViewer;