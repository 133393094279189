import React from "react";
import { Form, Button, Card } from "react-bootstrap";

const SettingUIComponent = (props) => {
  return (
    <div className="setting-form-container">
      <Card className="setting-card">
        <Card.Header className="card-header setting-card-header">
          <p className="card-heading">Multi Factor Authentication</p>

          <p
            className="card-sub-heading"
            style={{
              display: props.isCheckedMFA && !props.enrolled ? "block" : "none",
            }}
          >
            Multi Factor Authentication is{" "}
            {props.mfaEnabled ? "Already Enabled" : "Not Enabled"}
          </p>

          <p
            className="card-sub-heading"
            style={{
              display: props.isCheckedMFA && props.enrolled ? "block" : "none",
            }}
          >
            Multi Factor Authentication is enabled successfully
          </p>

          <p
            className="card-sub-heading"
            style={{ display: !props.isCheckedMFA ? "block" : "none" }}
          >
            Please Check Multi Factor Authentication Status
          </p>

          <p
            className="card-sub-heading"
            style={{ display: props.emailNotVerified ? "block" : "none" }}
          >
            Please Verify Email First
          </p>
        </Card.Header>
        <Card.Body>
          <p
            className="card-sub-heading"
            style={{ display: props.mfaEnabled ? "block" : "none" }}
          >
            Multi Factor Authentication is enabled with this number:{" "}
            {props.enrolledNumber}
          </p>
          <Form
            onSubmit={props.handleSubmit(props.onSubmit)}
            style={{
              display:
                !props.mfaEnabled &&
                props.isCheckedMFA &&
                !props.emailNotVerified
                  ? "block"
                  : "none",
            }}
          >
            {!props.modalOpen && !props.enrolled ? (
              <Form.Group controlId="formBasicPhone">
                <Form.Label>Enter Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Enter Phone +1234567890"
                  ref={props.register({
                    required: "phone is required",
                    pattern: {
                      value: /^[0-9+]+$/, // Update pattern to allow numbers with "+" sign
                      message: "Invalid number",
                    },
                  })}
                  isInvalid={props.errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.phone?.message}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
            {props.modalOpen ? (
              <Form.Group>
                <Form.Label>Enter OTP</Form.Label>
                <div className="otp-input-container">
                  {[...Array(6)].map((_, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      id={`otp${index}`}
                      name={`otp${index}`}
                      maxLength={1}
                      className="otp-input"
                      ref={(ref) => {
                        props.inputRefs.current[index] = ref;
                        props.register(ref, {
                          required: "OTP is required",
                          pattern: {
                            value: /^\d$/,
                            message: "OTP must be a single digit",
                          },
                        });
                      }}
                      onChange={(e) => props.handleInputChange(e, index)}
                      isInvalid={props.errors[`otp${index}`]} // Apply 'isInvalid' prop to highlight input field with red outline
                    />
                  ))}
                </div>
                {props.errors && (
                  <Form.Text className="text-danger">
                    {props.getErrorMessage("otp0")}{" "}
                  </Form.Text>
                )}
              </Form.Group>
            ) : null}

            <div className="button-container">
              <Button
                type="submit"
                className="submit-button"
                disabled={props.loading}
                style={{ display: !props.enrolled ? "block" : "none" }}
              >
                {props.modalOpen ? "confirm" : "Next"}
              </Button>

              {props.modalOpen ? (
                <Button
                  type="button"
                  className="resend-button"
                  disabled={props.loading}
                  onClick={() => props.handleSendCode(props.code)}
                  style={{ display: props.modalOpen ? "block" : "none" }}
                >
                  Resend
                </Button>
              ) : null}
            </div>
            {props.readyToResend && (
              <div id="recaptcha-container" className="recaptcha-container" />
            )}
          </Form>

          <Button
            type="button"
            className="submit-button check-mfa-button"
            disabled={props.loading}
            onClick={props.handleCheckMFA}
            style={{ display: !props.isCheckedMFA ? "block" : "none" }}
          >
            Check MFA
          </Button>
          <Button
            type="button"
            className="submit-button check-mfa-button"
            disabled={props.loading}
            onClick={props.handleVerifyEmail}
            style={{ display: props.emailNotVerified ? "block" : "none" }}
          >
            Verify Email
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SettingUIComponent;
