import firebase from "firebase/app";
import "firebase/storage";

export const uploadImage = async (path, file) => {
  try {
    const storageInstance = firebase.storage();
    const storageRef = storageInstance.ref(path);

    const snapshot = await storageRef.put(file);

    const downloadURL = await snapshot.ref.getDownloadURL();

    return downloadURL;
  } catch (error) {
    console.error("Error uploading image: ", error);
    throw error;
  }
};
