import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Edit2 } from "react-feather";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../component/common/breadcrumb/breadcrumb";
import { db, storage } from "../../data/base";
import GlassEditorModal from "./glassEditorModal";

const GlassList = () => {
  const [loading, setLoading] = useState(true);
  const [glassList, setGlassList] = useState([]);
  const [glass, setGlass] = useState(null);
  const { id: lineId } = useParams();

  const toggleGlassModal = (selectedGlass) => () => {
    setGlass(selectedGlass);
  };

  const convertColorStringArray = (array = []) => {
    return array.map((item) => {
      const [primary, secondary] = item.split(",");
      return {
        primary,
        secondary,
      };
    });
  };

  const handleDrop = (result) => {
    const { source, destination } = result;
    if (result.destination) {
      // reorder list
      const destinationIndex = destination.index;
      const sourceIndex = source.index;
      let updatedGlassList = [...glassList];
      const [removed] = updatedGlassList.splice(sourceIndex, 1);
      updatedGlassList.splice(destinationIndex, 0, removed);

      // update priority
      updatedGlassList = updatedGlassList.map((glass, index) => {
        if (glass.priority !== index + 1) {
          glass.priority = index + 1;
          db.collection("Occhiale")
            .doc(glass.id)
            .update({ priority: glass.priority });
        }
        return glass;
      });

      setGlassList(updatedGlassList);
    }
  };

  useEffect(() => {
    if (lineId) {
      // get line details
      db.collection("Linea")
        .doc(lineId)
        .get()
        .then(async (snap) => {
          const doc = { id: snap.id, ...snap.data() };
          const promiseList = doc.listaRefsOcchiale.map(async (ref) => {
            // get glasses
            const glass = await ref
              .get()
              .then((snap) => ({ id: snap.id, ...snap.data() }));

            // get public url from storage
            const p1 = storage.refFromURL(glass.url_image).getDownloadURL();

            // get glass variants
            const variantPromiseList =
              glass.lista_taglie?.map(async (ref) => {
                const variant = await ref.get().then(async (snap) => {
                  const data = snap.data();

                  // get models
                  const modelPromiseList = data.listaRefModels.map(
                    async (ref) => {
                      const model = await ref
                        .get()
                        .then((snap) => ({ modelId: snap.id, ...snap.data() }));
                      return model;
                    }
                  );

                  data.models = await Promise.all(modelPromiseList);
                  return { variantId: snap.id, ...data };
                });
                return variant;
              }) ?? [];

            const [imgUrl, ...variants] = await Promise.all([
              p1,
              ...variantPromiseList,
            ]);
            glass.imgUrl = imgUrl;
            glass.variants = variants;
            return glass;
          });

          let sizeSet = new Set();
          let frameColorSet = new Set();
          let lensColorSet = new Set();
          let glasses = (await Promise.all(promiseList)) || [];

          glasses = glasses
            .sort((a, b) => a.priority - b.priority)
            .map((glass) => {
              sizeSet.clear();
              frameColorSet.clear();
              lensColorSet.clear();

              glass.variants.forEach((variant) => {
                sizeSet.add(variant.size);
                variant.models.forEach((model) => {
                  frameColorSet.add(
                    `${model.esaColorFramePrimary},${model.esaColorFrameSecondary}`
                  );
                  lensColorSet.add(
                    `${model.esaColorLensesPrimary},${model.esaColorLensesSecondary}`
                  );
                });
              });

              glass.sizes = [...sizeSet];
              glass.frameColors = convertColorStringArray([...frameColorSet]);
              glass.lensColors = convertColorStringArray([...lensColorSet]);
              return glass;
            });
          setGlassList(glasses);
        })
        .finally(() => setLoading(false));
    }
  }, [lineId]);

  return (
    <Fragment>
      <Breadcrumb title="Glasses" />
      <Container fluid={true}>
        <Row className="mb-4">
          <Col md="6" className="products-total" />
          <Col md="6" className="text-right">
            <span className="f-w-600 m-r-5">
              Showing {glassList.length} glasses
            </span>
          </Col>
        </Row>

        <Row>
          {loading && (
            <Col className="text-center" xs={12}>
              <div className="loader-box">
                <div className="loader-3" />
              </div>
            </Col>
          )}
        </Row>

        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                className="product-wrapper-grid list-view"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {glassList.map((item, i) => (
                  <Draggable draggableId={item.id} index={i} key={item.id}>
                    {(provided, snapshot) => (
                      <div
                        className="col-xl-12"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card
                          className={`overflow-hidden mb-4 ${
                            snapshot.isDragging ? "shadow" : ""
                          }`}
                        >
                          <div className="product-box">
                            <div className="product-img">
                              <span className="ribbon ribbon-success ribbon-right">
                                Priority: {item.priority}
                              </span>
                              <img
                                className="img-fluid"
                                src={item.imgUrl}
                                alt=""
                              />
                              <div className="product-hover">
                                <ul>
                                  <li onClick={toggleGlassModal(item)}>
                                  <a
                                      href="#"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="icon-pencil" />
                                    </a>
                                    {/* <Edit2 size="14px" /> */}
                                  </li>
                                  <li>
                                    <a
                                      href={
                                        item.url_product?.includes("http")
                                          ? item.url_product
                                          : `https://${item.url_product}`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="icon-shopping-cart" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={item.url_shadow}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="icon-link" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="product-details">
                              <h5 className="font-primary">
                                {item.nome_modello}
                              </h5>
                              <div className="glass-description">
                                <div className="glass-description-block">
                                  <h5 className="title">Frame</h5>
                                  <div title="Frame" className="circle">
                                    {item.frameColors?.length}
                                  </div>
                                </div>
                                <div className="glass-description-block">
                                  <h5 className="title">Lens</h5>
                                  <div title="Lens" className="circle">
                                    {item.lensColors?.length}
                                  </div>
                                </div>
                                <div className="glass-description-block">
                                  <h5 className="title">Size</h5>
                                  <div
                                    title="size"
                                    className="circle bg-warning"
                                  >
                                    {item.sizes?.length}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
      <GlassEditorModal
        glass={glass}
        setGlassList={setGlassList}
        toggleGlassModal={toggleGlassModal}
      />
    </Fragment>
  );
};

export default GlassList;
