import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "react-feather";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import ColorIndicator from "../../component/general/dashboard/ColorIndicator";
import { db } from "../../data/base";

const GlassEditorModal = ({ glass, setGlassList, toggleGlassModal }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});

  const handleChange = ({ target: { value, name } }) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { id, descrizione_en, url_product, url_shadow, nome_modello } = state;
    setLoading(true);

    try {
      const p1 = db.collection("Occhiale").doc(id).update({
        url_product,
        url_shadow,
        descrizione_en,
        nome_modello,
      });
      const p2 = db
        .collection("Modello")
        .where("nomeOcchiale", "==", glass.nome_modello)
        .get()
        .then((snaps) =>
          snaps.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

      const [modelList] = await Promise.all([p2, p1]);
      const promiseList = modelList.map((model) =>
        db.collection("Modello").doc(model.id).set(
          {
            nomeOcchiale: nome_modello,
          },
          { merge: true }
        )
      );
      await Promise.all(promiseList);

      //   update current glass in glass list
      setGlassList((glassList) =>
        glassList.map((glass) =>
          glass.id === id ? { ...glass, ...state } : glass
        )
      );
      toast.success("Updated successfully");
      toggleGlassModal(null)();
    } catch (e) {
      toast.error("Error updating");
      toast.error(e.message);
      // console.log(e.message, e.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    setState(glass);
  }, [glass]);

  // console.log(glass);

  return (
    <Modal
      className="modal-dialog modal-lg modal-dialog-centered"
      isOpen={Boolean(glass)}
      toggle={toggleGlassModal(null)}
    >
      <ModalBody>
        <button
          className="close ml-3 absolute right-0"
          type="button"
          onClick={toggleGlassModal(null)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="product-box row mb-4">
          <Col md="6" className="product-img">
            <Media className="img-fluid" src={glass?.imgUrl} alt="" />
          </Col>
          <Col md="6" className="product-detailss  text-left">
            <div className="d-flex justify-content-between mr-3 mb-3">
              <Input
                className="d-block font-weight-bold classInput"
                type="text"
                name="nome_modello"
                value={state?.nome_modello || ""}
                placeholder="Glass Name"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <span className="font-weight-bold color-title mr-3">Frame: </span>
              <div className="d-flex flex-wrap">
                {glass?.frameColors.map((item, ind) => (
                  <ColorIndicator type="square" {...item} key={ind} />
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center mb-2">
              <span className="font-weight-bold color-title mr-3">Lens: </span>
              <div className="d-flex flex-wrap">
                {glass?.lensColors.map((item, ind) => (
                  <ColorIndicator {...item} key={ind} />
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <span className="font-weight-bold color-title mr-3">Size: </span>
              <div className="d-flex flex-wrap">
                {glass?.sizes.map((item, ind) => (
                  <div className="circle" key={ind}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="ribbon ribbon-success ribbon-rounded-both">
              Priority: {glass?.priority}
            </div>
          </Col>
        </div>

        <FormGroup className="d-flex align-items-center mb-3">
          <Label className="text-24 mb-0 mr-3">
            <i className="icon-shopping-cart" />
          </Label>
          <Input
            className="d-block"
            type="text"
            name="url_product"
            value={state?.url_product || ""}
            placeholder="https://www.example.com"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className="d-flex align-items-center mb-3">
          <Label className="text-24 mb-0 mr-3">
            <i className="icon-link" />
          </Label>
          <Input
            className="d-block"
            type="text"
            name="url_shadow"
            value={state?.url_shadow || ""}
            placeholder="https://www.example.com"
            onChange={handleChange}
            readOnly
            disabled
          />
          <CopyToClipboard text={glass?.url_shadow}>
            <Copy
              className="text-primary cursor-pointer ml-3"
              onClick={() =>
                toast.success("Code Copied to clipboard !", {
                  position: toast.POSITION.TOP_RIGHT,
                })
              }
            />
          </CopyToClipboard>
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            className="d-block mb-3"
            rows={6}
            type="textarea"
            name="descrizione_en"
            value={state?.descrizione_en || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          color="primary"
          className="w-100 d-flex justify-content-center align-items-center"
          disabled={loading}
          onClick={handleSubmit}
        >
          Save {loading && <Spinner size="sm" className="ml-2" />}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default GlassEditorModal;
