import React from "react";
import styles from "./ModelContainerFrontEnd.module.css";

function ModelContainerFrontEnd(props) {
  return (
    <div
      className={styles["canvas29985"]}
      style={
        props.isEditModeOn
          ? {
              width: props?.containerWidth,
              height: props.containerHeight,
            }
          : {}
      }
    >
      <div
        className={
          props.viewerPage ? styles["groupViewePage"] : styles["Group14291011"]
        }
        style={
          props.isEditModeOn
            ? { width: props?.containerWidth, height: props.containerHeight }
            : {}
        }
      >
        <div
          style={{
            backgroundColor: `${props.modelSelected?.background_color}`,
            backgroundImage: props.modelSelected?.background_boolean_image
              ? `url(${props.modelSelected?.background_image})`
              : "",
            backgroundPosition: props.modelSelected?.background_boolean_image
              ? "center"
              : "",
            width: props?.containerWidth,
            height: props.containerHeight,
            ...(props.isEditModeOn && {
              backgroundColor: `${props.modelSelected?.background_color}`,
            }),
          }}
          alt="Rectangle11291012"
          className={
            (props.project?.menu_position === "left" ||
              props.project?.menu_position === "right") 
            //   &&
            // window.innerWidth > 700
              ? styles["imageColum"]
              : styles["image"]
          }
        >
          {props.project?.watermark && (
            <div className="fixedPowered"> Powered By Arshades</div>
          )}

          {props.children}
        </div>
      </div>
    </div>
  );
}

export default ModelContainerFrontEnd;
