import React, {useRef, useState, useEffect } from "react";
import styles from "./ARShades3dsViewerCustom.module.css"
import lensIcon from "../../assets/icon/Polarized.svg"
import lensIcon2 from "../../assets/icon/lens2.svg"
import lensIcon3 from "../../assets/icon/lens3.svg"
import frameIcon from "../../assets/icon/montatura1.svg"
import frameIcon2 from "../../assets/icon/montatura2.svg"

import Lottie from "react-lottie-player";
import animationLottieIcon from "../../assets/lottie/iconPulse.json"
import Transition from 'react-transition-group/Transition';
import Menu from "./CustomMenu";

function ARShades3dViewerCustomThree() {
  const modelRef = useRef(null);
  const [isExploded, setIsExploded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const [currentAnimation, setCurrentAnimation] = useState(""); // Per tenere traccia dell'animazione corrente
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(-1);

  useEffect(() => {
    function handleModelLoad() {
        const modelViewer = modelRef.current;
         modelViewer.animationName = 'BLACK_GOLD_205946807521N_OPEN';
        //  handleExplodedAnimation()
        setInterval(()=> {
          setIsLoaded(true)
        }, 100)
        //  handleExplodedAnimation()
        setInterval(()=> {
          setIsLoaded(true)
        }, 100)
    }
  
    const modelViewer = modelRef.current;

    if (modelViewer) {
        modelViewer.addEventListener('load', handleModelLoad); // Aggiungi questo listener
    }

    // Questa funzione di pulizia viene eseguita quando il componente viene smontato.
    return () => {
        if (modelViewer) {
            modelViewer.removeEventListener('load', handleModelLoad); // Rimuovi anche questo listener
        }
    };
}, []);



const playAnimation = (animationName, animationLenght) => {
  const modelViewer = modelRef.current;

  setIsAnimating(true);

  if (!modelViewer) return;

  // Verifica se l'animationName è CLOSED o OPEN
  if (animationName.includes('_CLOSED')) {
      // console.log("L'animazione è di tipo 'CLOSED'.");
      
      setTimeout(() => {
        modelViewer.cameraOrbit="0.029deg 24.54deg 2.355m";
        modelViewer.cameraTarget ="0.0m 0.03m 0.0m";
        modelViewer.fieldOfView ="5deg"
      }, 0);

      modelViewer.animationName = animationName;
      modelViewer.timeScale = 1.5
      modelViewer.play({ repetitions: 1 });
  
  
      setTimeout(() => {
          modelViewer.pause();
          // modelViewer.currentTime= animationLenght*2 - 100
          setIsAnimating(false);
    
      }, ((animationLenght * 2)/3) );
      // Qui puoi aggiungere ulteriore logica specifica per le animazioni CLOSED, se necessario
  } else if (animationName.includes('_OPEN')) {
      // console.log("L'animazione è di tipo 'OPEN'.");

    setTimeout(() => {
      modelViewer.cameraOrbit="0.0deg 84.54deg -0m";
      modelViewer.cameraTarget ="0.0m 0.04m 0.0m";
      modelViewer.fieldOfView ="10deg"

    }, 2000);
    modelViewer.animationName = animationName;
    modelViewer.timeScale = 0.5
    modelViewer.play({ repetitions: 1 });


    setTimeout(() => {
        modelViewer.pause();
        modelViewer.currentTime= animationLenght*2 
        setIsAnimating(false);
  
    }, animationLenght*2);

    } else {
      console.warn("Tipo di animazione non riconosciuto.");
  }

  modelViewer.animationName = animationName;
  // modelViewer.timeScale = 1
 
};


const handleItemClick = async (index) => {
  // console.log(`Hai cliccato sul pallino ${index}`);
  setCurrentAnimationIndex(index);

  let montaggioAnimation = ''; 
  const animationLenght = 3600;

  switch(index) {
      case 0:
          montaggioAnimation = 'BLACK_GOLD_205946807521N_OPEN';
          break;
      case 1:
          montaggioAnimation = 'BLACK_SILVER_20594680752IR_OPEN';
          break;
      case 2:
          montaggioAnimation = 'BROWN_2059468075270_OPEN';
          break;
      case 3:
          montaggioAnimation = 'GREEN_205946KB752QT_OPEN';
          break;
      case 4:
          montaggioAnimation = 'HAVANA_20594608652IR_OPEN';
          break;
      case 5:
          montaggioAnimation = 'PINK_20594635J52IR_OPEN';
          break;
      default:
          console.error('Indice non valido');
          return;
  }

  // Converti "OPEN" in "CLOSED" per ottenere il nome dell'animazione di smontaggio corrispondente
    const nextMontaggioAnimation = montaggioAnimation.replace('_OPEN', '_CLOSED');

  if (isExploded) {
      playAnimation(currentAnimation, animationLenght);

      // Attendiamo che l'animazione di smontaggio sia completata prima di iniziare l'animazione di montaggio
      setTimeout(() => {
          playAnimation(montaggioAnimation, animationLenght);
          setCurrentAnimation(nextMontaggioAnimation); // Aggiorna l'animazione corrente dopo averla eseguita

      }, animationLenght - 1000 );
  } else {
      playAnimation(montaggioAnimation, animationLenght);
      setCurrentAnimation(nextMontaggioAnimation); // Aggiorna l'animazione corrente dopo averla eseguita

  }

  // console.log("FATTA", montaggioAnimation, "smontaggio prossimo", nextMontaggioAnimation, "Smontaggio Attuale", currentAnimation);
  setIsExploded(true)
};






  return (
    <div className={styles['md-animation-custom-three']}>
<Menu onItemClick={handleItemClick} isAnimating={isAnimating} currentAnimationIndex={currentAnimationIndex} />
       <div className={styles["viewer3d"]}>
      <model-viewer
        //  environment-image = "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fphoto_2023-08-31_16-48-17.jpg?alt=media&token=c1f128d0-f805-4733-bb4f-4581860e9174"
        //  skybox-image = "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fphoto_2023-08-31_16-48-17.jpg?alt=media&token=c1f128d0-f805-4733-bb4f-4581860e9174"
        //  exposure="1"
         camera-controls="true"
         interaction-prompt="none"
         animation-crossfade-duration ={1}
        interpolation-decay={300}
        timeScale={0.5}
        ref={modelRef}
        id="customModel"
        src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2FBOSS_1505_Teardown_OPEN-CLOSED.glb?alt=media&token=a2c213d6-01cf-41f1-b28e-046c86ca5ebc"
        alt="Un modello 3D con animazioni e informazioni"
        class={styles['md-container']}
        // camera-controls
        // camera-orbit="42.64deg 87.75deg 0.4182m" 
        // field-of-view="30deg"
        camera-target="0.0m 0.03m 0.0m"
        camera-orbit="0.029deg 24.54deg 2.355m" field-of-view="23.37deg"
        disable-pan
      >
      </model-viewer>
      </div>
    
    </div>
  );
}

export default ARShades3dViewerCustomThree;
