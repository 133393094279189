import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";

import logo from "../../assets/images/logo.png";

const OTPForm = (props) => {
  const inputRefs = useRef([]); // Ref for storing input field refs
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    // Handle OTP verification logic here
    setLoading(true);
    await props.handleVerifyCode(Object.values(data).join(""));
    setLoading(false);
  };

  // Function to handle input change and focus on the next input field
  const handleInputChange = (e, index) => {
    const inputLength = e.target.value.length;
    if (inputLength > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Function to get error message for the OTP fields
  const getErrorMessage = (fieldName) => {
    if (errors[fieldName]) {
      return errors[fieldName].message;
    }
    return "";
  };

  return (
    <div className="login-form-container">
      <Card className="login-card">
        <Card.Header className="card-header">
          <img src={logo} alt="logo" className="logo" />
          <p className="card-heading">Authentication</p>
          <p className="card-sub-heading">
            We have sent a 5-digit code to your mobile number
          </p>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {props.smsSent ? (
              <>
                <Form.Group>
                  <div className="otp-input-container">
                    {[...Array(6)].map((_, index) => (
                      <Form.Control
                        key={index}
                        type="text"
                        id={`otp${index}`}
                        name={`otp${index}`}
                        maxLength={1}
                        className="otp-input"
                        ref={(ref) => {
                          inputRefs.current[index] = ref;
                          register(ref, {
                            required: "OTP is required",
                            pattern: {
                              value: /^\d$/,
                              message: "OTP must be a single digit",
                            },
                          });
                        }}
                        onChange={(e) => handleInputChange(e, index)}
                        isInvalid={errors[`otp${index}`]} // Apply 'isInvalid' prop to highlight input field with red outline
                      />
                    ))}
                  </div>
                  {errors && (
                    <Form.Text className="text-danger">
                      {getErrorMessage("otp0")}{" "}
                    </Form.Text>
                  )}
                  <div className="reset-password">
                    <p className="resend-text">Didn’t receive your code?</p>
                    <button
                      className="forgot-password-anchor resend-anchor"
                      onClick={props.handleResendSms}
                      type="button"
                      disabled={loading}
                    >
                      Resend it
                    </button>
                  </div>
                </Form.Group>
                <Button
                  type="submit"
                  block
                  className="submit-button"
                  disabled={loading}
                >
                  Verify
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  block
                  className="submit-button"
                  disabled={loading}
                  onClick={props.handleSendSms}
                >
                  Send SMS
                </Button>
              </>
            )}
            {props.readyToResend && (
              <div id="recaptcha-container" className="recaptcha-container" />
            )}
          </Form>
        </Card.Body>
      </Card>

      <div className="circle" />
    </div>
  );
};

export default OTPForm;
