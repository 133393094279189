import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/firestore';

const SubscriptionForm = ({ riferimentoTipo, licenseId, clientId, subscriptionType }) => {
    const [subscriptionData, setSubscriptionData] = useState({
        allowedDomainList: [],
        allowedReferresList: [],
        catalogList: {
            catalogRefList: [],
            consumptionLimit: 0,
            skuLimit: 0,
            catalogsLimit: 0
        },
        consumption_list: {
            arpd_meter_consumption: '',
            consumptionRef: ''
        },
        endDate: null,
        isAllowIframe: false,
        isMainSubscription: riferimentoTipo !== 'additional',
        isRefactored: true,
        mainSubscriptionRef: riferimentoTipo !== 'additional' ? '' : licenseId,
        refClient: clientId,
        startDate: null,
        status: 'Active',
        store: '',
        subscriptionType: '',
        subscription_details_ref: ''
    });

    const [newAllowedDomain, setNewAllowedDomain] = useState('');
    const [newAllowedReferrer, setNewAllowedReferrer] = useState('');
    const [newCatalogRef, setNewCatalogRef] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // State to manage modal open
    const [isSubscriptionAdded, setIsSubscriptionAdded] = useState(false);

    useEffect(() => {
        const isValid = subscriptionData.subscriptionType !== '' && subscriptionData.store !== '';
        setIsFormValid(isValid);
    }, [subscriptionData]);

    useEffect(() => {
        if (isSubscriptionAdded) {
            // Reload the page when isSubscriptionAdded becomes true
            window.location.reload();
        }
    }, [isSubscriptionAdded]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubscriptionData({ ...subscriptionData, [name]: value });
    };

    const handleAddAllowedDomain = () => {
        if (newAllowedDomain.trim() !== '') {
            setSubscriptionData(prevState => ({
                ...prevState,
                allowedDomainList: [...prevState.allowedDomainList, newAllowedDomain.trim()]
            }));
            setNewAllowedDomain('');
        }
    };

    const handleAddAllowedReferrer = () => {
        if (newAllowedReferrer.trim() !== '') {
            setSubscriptionData(prevState => ({
                ...prevState,
                allowedReferresList: [...prevState.allowedReferresList, newAllowedReferrer.trim()]
            }));
            setNewAllowedReferrer('');
        }
    };

    const handleAddCatalog = () => {
        if (newCatalogRef.trim() !== '') {
            setSubscriptionData(prevState => ({
                ...prevState,
                catalogList: {
                    catalogRefList: [...prevState.catalogList.catalogRefList, newCatalogRef.trim()],
                    consumptionLimit: prevState.catalogList.consumptionLimit,
                    skuLimit: prevState.catalogList.skuLimit
                }
            }));
            setNewCatalogRef('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const startDate = firebase.firestore.Timestamp.now();
        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);

        const filteredSubscriptionData = {
            allowedDomainList: subscriptionData.allowedDomainList.filter(item => item.trim() !== ''),
            allowedReferresList: subscriptionData.allowedReferresList.filter(item => item.trim() !== ''),
            catalogList: {
                catalogRefList: subscriptionData.catalogList.catalogRefList.length > 0 ? subscriptionData.catalogList.catalogRefList : [],
                consumptionLimit: subscriptionData.catalogList.consumptionLimit || 0,
                skuLimit: subscriptionData.catalogList.skuLimit || 0
            },
            consumption_list: subscriptionData.consumption_list,
            endDate: firebase.firestore.Timestamp.fromDate(endDate),
            isAllowIframe: subscriptionData.isAllowIframe,
            isMainSubscription: subscriptionData.isMainSubscription,
            isRefactored: subscriptionData.isRefactored,
            mainSubscriptionRef: subscriptionData.mainSubscriptionRef,
            refClient: subscriptionData.refClient,
            startDate: startDate,
            status: subscriptionData.status,
            store: subscriptionData.store,
            subscriptionType: subscriptionData.subscriptionType,
            subscription_details_ref: subscriptionData.subscription_details_ref
        };

        try {
            const newSubscriptionRef = await firebase.firestore().collection('ARShades_Subscription').add(filteredSubscriptionData);
            await firebase.firestore().collection('Client').doc(clientId).update({
                licenseList: firebase.firestore.FieldValue.arrayUnion(newSubscriptionRef.id)
            });

            setSubscriptionData({
                allowedDomainList: [],
                allowedReferresList: [],
                catalogList: {
                    catalogRefList: [],
                    consumptionLimit: 0,
                    skuLimit: 0,
                    catalogsLimit: 0
                },
                consumption_list: {
                    arpd_meter_consumption: '',
                    consumptionRef: ''
                },
                endDate: null,
                isAllowIframe: false,
                isMainSubscription: riferimentoTipo !== 'additional',
                isRefactored: true,
                mainSubscriptionRef: riferimentoTipo !== 'additional' ? '' : licenseId,
                refClient: clientId,
                startDate: null,
                status: 'Active',
                store: '',
                subscriptionType: '',
                subscription_details_ref: ''
            });

            setNewAllowedDomain('');
            setNewAllowedReferrer('');
            setNewCatalogRef('');

            // Open the modal for the brand form after adding the subscription
            setModalOpen(true);

            // Set isSubscriptionAdded to true to trigger page reload
            setIsSubscriptionAdded(true);
        } catch (error) {
            console.error("Error adding subscription: ", error);
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="subscriptionType">Subscription Type:</Label>
                    <Input
                        type="select"
                        name="subscriptionType"
                        value={subscriptionData.subscriptionType}
                        onChange={handleChange}
                    >
                        <option value="">Select Type</option>
                        {riferimentoTipo === 'additional' ? (
                            <>
                                {subscriptionType === 'ARShades Branded License' && (
                                    <option value="ARShades Branded Additional License">ARShades Branded Additional License</option>
                                )}
                                {subscriptionType === 'ARShades Smart License' && (
                                    <option value="ARShades Smart Additional License">ARShades Smart Additional License</option>
                                )}
                            </>
                        ) : (
                            <>
                                <option value="ARShades Branded License">ARShades Branded License</option>
                                <option value="ARShades Smart License">ARShades Smart License</option>
                                <option value="ARShades ARPDmeter License">ARShades ARPDmeter License</option>
                            </>
                        )}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="store">Store:</Label>
                    <Input
                        type="text"
                        name="store"
                        value={subscriptionData.store}
                        onChange={handleChange}
                        placeholder="Store"
                    />
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="isAllowIframe" checked={subscriptionData.isAllowIframe} onChange={() => setSubscriptionData({ ...subscriptionData, isAllowIframe: !subscriptionData.isAllowIframe })} />{' '}
                        Allow Iframe
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label for="allowedDomainList">Allowed Domain List:</Label>
                    <div className="d-flex align-items-center">
                        <Input type="text" value={newAllowedDomain} onChange={(e) => setNewAllowedDomain(e.target.value)} placeholder="Add domain" />
                        <Button type="button" color="info" onClick={handleAddAllowedDomain} style={{ marginLeft: '5px' }}>
                            Add Domain
                        </Button>
                    </div>
                    <ul className="list-unstyled mt-2">
                        {subscriptionData.allowedDomainList.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Label for="allowedReferresList">Allowed Referrers List:</Label>
                    <div className="d-flex align-items-center">
                        <Input type="text" value={newAllowedReferrer} onChange={(e) => setNewAllowedReferrer(e.target.value)} placeholder="Add referrer" />
                        <Button type="button" color="info" onClick={handleAddAllowedReferrer} style={{ marginLeft: '5px' }}>
                            Add Referrer
                        </Button>
                    </div>
                    <ul className="list-unstyled mt-2">
                        {subscriptionData.allowedReferresList.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </FormGroup>
                <Button type="submit" color="primary" disabled={!isFormValid}>Add Subscription</Button>
            </Form>
        </>
    );
};

export default SubscriptionForm;