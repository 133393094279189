import { SET_SELECTED_BRAND } from "../actionType";

export const setSelectedBrand = (brand) => (dispatch) => {
  const modifiedBrand = { ...brand };
  //Elimino elementi che possono causare errori circolari al lancio di stringify che non supporta i reference
  delete modifiedBrand.listaRefsLinea;
  delete modifiedBrand?.take_pic_template;
  delete modifiedBrand?.skin;
  localStorage.setItem("brand", !!brand ? JSON.stringify(modifiedBrand) : null);
  dispatch({
    type: SET_SELECTED_BRAND,
    brand,
  });
};
