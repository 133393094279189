import React from "react";
import { Col, Spinner } from "reactstrap";
import ReactTooltip from 'react-tooltip';

const removeSecondsFromTimeString = (label, value) => {
  return label?.toLowerCase()?.includes("time") &&
    value?.toString()?.split(":")?.length > 2
    ? value?.toString()?.slice(0, -3)
    : value;
};

const SummaryCard = ({ active, label, value, onClick, loading }) => {
  const valueText = removeSecondsFromTimeString(label, value);
  return (
    <Col sm={4} xs={6} md={3} xl={2} className="cursor-pointer">
      <div
        onClick={onClick}
        style={{
          backgroundColor: active ? "#42B1AC" : "#EAEAEA",
          color: active ? "white" : "#241F45",
          padding: "15px",
          height: "130px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "0.5rem",
          borderRadius: "9px",
          transition: "all 0.2s",
        }}
        className={active ? "card-tip" : ""}
      >
        <h1 className="font-weight-bold" style={{ margin: 0 }}>
          {loading ? <Spinner type="grow" /> : valueText}
        </h1>
        <h6>{label}</h6>
        
      </div>
    </Col>
  );
};

export default SummaryCard;
