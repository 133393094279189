import React from "react";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";

export const FormField = ({
  label,
  name,
  placeholder,
  type = "text",
  error,
  innerRef,
  disabled,
}) => {
  return (
    <FormGroup row>
      <Label for={name} sm={2} style={{ color: "#838282" }}>
        {label}
      </Label>
      <Col sm={5}>
        <Input
          {...{ type, name, placeholder, disabled }}
          invalid={!!error}
          innerRef={innerRef}
        />
        <FormFeedback>{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};
