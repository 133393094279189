import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/auth';

const ClientForm = ({ existingClient, handleSubmit }) => {
    const [allProfileList, setAllProfileList] = useState(existingClient ? existingClient.allProfileList : []);
    const [catalogList, setCatalogList] = useState(existingClient ? existingClient.catalogList : []);
    const [licenseList, setLicenseList] = useState(existingClient ? existingClient.licenseList : []);
    const [mainProfileList, setMainProfileList] = useState(existingClient ? existingClient.mainProfileList : []);

    const [profileData, setProfileData] = useState({
        _email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: ''
    });

    // Initialize registration and account creation dates based on existing client or current time
    const registrationDate = existingClient ? existingClient.registrationDate : new Date().getTime();
    const accountCreationDate = existingClient ? existingClient.accountCreationDate : new Date().getTime();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    };

    // Handle submission of password | create Firebase user
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        const { _email, password, confirmPassword } = profileData;

        if (password !== confirmPassword) {
            alert("Passwords don't match!");
            return;
        }

        try {
            // Create user in Firebase Authentication
            await firebase.auth().createUserWithEmailAndPassword(_email, password);

            // After user creation, proceed with client creation
            handleSubmit(e);
        } catch (error) {
            console.error('Error creating user:', error.message);
            alert('Error creating user: ' + error.message);
        }
    };

    return (
        <Form onSubmit={handlePasswordSubmit} style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input
                            type="text"
                            name="city"
                            id="city"
                            defaultValue={existingClient ? existingClient.address.city : ''}
                            placeholder="City"
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="country">Country</Label>
                        <Input
                            type="text"
                            name="country"
                            id="country"
                            defaultValue={existingClient ? existingClient.address.country : ''}
                            placeholder="Country"
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="postalCode">Postal Code</Label>
                        <Input
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            defaultValue={existingClient ? existingClient.address.postalCode : ''}
                            placeholder="Postal Code"
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="state">State</Label>
                        <Input
                            type="text"
                            name="state"
                            id="state"
                            defaultValue={existingClient ? existingClient.address.state : ''}
                            placeholder="State"
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="street">Street</Label>
                <Input
                    type="text"
                    name="street"
                    id="street"
                    defaultValue={existingClient ? existingClient.address.street : ''}
                    placeholder="Street"
                    required
                />
            </FormGroup>

            <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    defaultValue={existingClient ? existingClient.companyName : ''}
                    placeholder="Company Name"
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="pec">PEC (Posta Elettronica Certificata)</Label>
                <Input
                    type="email"
                    name="pec"
                    id="pec"
                    defaultValue={existingClient ? existingClient.pec : ''}
                    placeholder="PEC"
                    required
                />
            </FormGroup>

            <FormGroup>
                <Label for="vatNumber">VAT Number</Label>
                <Input
                    type="number"
                    name="vatNumber"
                    id="vatNumber"
                    defaultValue={existingClient ? existingClient.vatNumber : ''}
                    placeholder="VAT Number"
                />
            </FormGroup>

            <FormGroup>
                <Label for="_email">Email:</Label>
                <Input
                    type="email"
                    name="_email"
                    id="_email"
                    autoComplete="off"
                    value={profileData._email}
                    onChange={handleChange}
                    placeholder='Email'
                    required
                />
            </FormGroup>

            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="firstName">First Name:</Label>
                        <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={profileData.firstName}
                            onChange={handleChange}
                            placeholder='First Name'
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="lastName">Last Name:</Label>
                        <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={profileData.lastName}
                            onChange={handleChange}
                            placeholder='Last Name'
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Password"
                            autoComplete="new-password"
                            value={profileData.password}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            autoComplete="new-password"
                            value={profileData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Button color="primary" type="submit" style={{ padding: '0.5rem 1rem' }}>Add Client</Button>
        </Form>
    );
};

export default ClientForm;