import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import firebase from "firebase/app";
import "firebase/auth";
import { toast } from "react-toastify";

import SettingUIComponent from "./mfaUIComponents";

const Setting = () => {
  const [enrolled, setEnrolled] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [code, setCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [mfaEnabled, setMFAEnabled] = useState(false);
  const [isCheckedMFA, setIsCheckedMFA] = useState(false);
  const [readyToResend, setReadyToResend] = useState(true);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [enrolledNumber, setEnrolledNumber] = useState(false);
  const inputRefs = useRef([]);

  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    if (!modalOpen) {
      await handleSendCode(data.phone);
      setCode(data.phone);
    } else {
      await handleEnrollment(Object.values(data).join(""));
    }
    setLoading(false);
  };

  const handleInputChange = (e, index) => {
    const inputLength = e.target.value.length;
    if (inputLength > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Function to get error message for the OTP fields
  const getErrorMessage = (fieldName) => {
    if (errors[fieldName]) {
      return errors[fieldName].message;
    }
    return "";
  };

  const handleSendCode = async (phoneNumber) => {
    try {
      const auth = firebase.auth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("User is not signed in.");
      }
      // Check if email is verified
      const emailVerified = currentUser.emailVerified;
      if (!emailVerified) {
        setEmailNotVerified(true);
        throw new Error("Email is not verified. Please Verify Email first");
      }
      const verifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );

      const multiFactor = currentUser.multiFactor;

      if (!multiFactor) {
        throw new Error("User does not have MFA enabled.");
      }
      const session = await multiFactor.getSession();
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber, session },
        verifier
      );
      setReadyToResend(false);
      setVerificationId(verificationId);
      setModalOpen(true);
      setReadyToResend(true);
      toast.success("Code sent successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEnrollment = async (code) => {
    try {
      const auth = firebase.auth();

      const phoneCredential = new firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        code
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(phoneCredential);
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User is not signed in.");
      }
      const multiFactor = currentUser.multiFactor;
      if (!multiFactor) {
        throw new Error("User does not have MFA enabled.");
      }

      const session = await multiFactor.getSession();
      if (session.multiFactor?.enrolledFactors?.length > 0) {
        await session.enroll(multiFactorAssertion, "phone");
        setEnrolled(true);
      } else {
        await currentUser.multiFactor.enroll(multiFactorAssertion);
        setEnrolled(true);
      }
      setModalOpen(false);
      toast.success("MFA Enabled!");
      const recaptcha = document.getElementById("recaptcha-container");
      recaptcha.parentNode.removeChild(recaptcha);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const auth = firebase.auth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User is not signed in.");
      }
      await currentUser.sendEmailVerification();
      toast.success("Verification email sent successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCheckMFA = () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser && currentUser.multiFactor) {
      const isPhoneEnrolled = currentUser.multiFactor.enrolledFactors.some(
        (factor) => {
          if (factor.factorId === "phone") {
            setEnrolledNumber(factor?.phoneNumber);
            return true;
          }
          return false;
        }
      );
      if (isPhoneEnrolled) {
        setMFAEnabled(true);
      } else {
        setMFAEnabled(false);
      }
    } else {
      setMFAEnabled(false);
    }
    setIsCheckedMFA(true);
  };

  return (
    <SettingUIComponent
      {...{
        isCheckedMFA,
        enrolled,
        mfaEnabled,
        emailNotVerified,
        handleSubmit,
        onSubmit,
        modalOpen,
        register,
        errors,
        inputRefs,
        handleInputChange,
        getErrorMessage,
        loading,
        handleSendCode,
        code,
        readyToResend,
        handleCheckMFA,
        handleVerifyEmail,
        enrolledNumber,
      }}
    />
  );
};

export default Setting;
