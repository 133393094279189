import {
  SET_ANALYTICS_FILTER_OPTIONS,
  SET_LINE_GLASS_MODEL,
  VAR_ARRAY,
  GLASS_ARRAY
} from '../actionType'

export const setAnalyticsFilterOptions = (options) => (dispatch) => {
  dispatch({
    type: SET_ANALYTICS_FILTER_OPTIONS,
    options
  })
}

export const setLineGlassModel = (data) => (dispatch) => {
  dispatch({
    type: SET_LINE_GLASS_MODEL,
    payload: data
  })
}

export const setGlassArr = (data)=>(dispatch)=>{

  dispatch({
    type: GLASS_ARRAY,
    payload: data
  })
}
export const setVarArr = (data)=>(dispatch)=>{
  dispatch({
    type: VAR_ARRAY,
    payload: data
  })
}

