import React, { useEffect, useState } from 'react';
import { db } from "../../data/base";

export const Accordion = ({ subscription }) => {
  const [expanded, setExpanded] = useState(false);
  const [serviceArshades, setServiceArshades] = useState([]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (subscription.service_list) {
        const serviceArshades = [];
        const servicePromises = subscription.service_list.map(async (service) => {
          const res = await db.collection("Service_ARShades").doc(service).get();
          if (res.exists) {
            serviceArshades.push({
              id: res.id,
              service: res.data().service,
              icon: res.data().icon
            });
          }
        });

        await Promise.all(servicePromises);

        const order = [
          'ARShades Branded License',
          'ARShades Additional Branded License',
          'ARShades Smart License'
        ];

        serviceArshades.sort((a, b) => {
          const indexA = order.indexOf(a.service);
          const indexB = order.indexOf(b.service);

          if (indexA === -1 && indexB === -1) {
            return a.service.localeCompare(b.service);
          }

          if (indexA === -1) return 1;
          if (indexB === -1) return -1;

          return indexA - indexB;
        });

        setServiceArshades(serviceArshades);
      }
    };

    fetchData();
  }, [subscription.service_list]);

  const getTime = (date) => {
    if (!date || !date.seconds) return "N/A";
    const milliseconds = date.seconds * 1000 + Math.round(date.nanoseconds / 1000000);
    const output = new Date(milliseconds);
    return output.toLocaleString();
  };

  const isExpired = () => {
    if (!subscription.endDate || !subscription.endDate.seconds) return false;
    const milliseconds = subscription.endDate.seconds * 1000 + Math.round(subscription.endDate.nanoseconds / 1000000);
    const date = new Date(milliseconds);
    const currentDate = new Date();
    return date < currentDate;
  };

  return (
    <>
      <tr style={{ textAlign: 'center' }} className="accordion" onClick={handleClick}>
        <td className='vertical-td'>
          <div className="accordion-brand">{subscription.subscriptionType || "N/A"}</div>
        </td>
        <td className='vertical-td'>
          <div className="accordion-dates">
            {getTime(subscription.startDate)}
          </div>
        </td>
        <td className='vertical-td'>
          <div className="accordion-dates">
            {getTime(subscription.endDate)}
          </div>
        </td>
        <td className='vertical-td'>
          <div className="store">
            {subscription.store?.toUpperCase() || "N/A"}
          </div>
        </td>
        <td className='vertical-td'>
          <div className={`accordion-dates license_status ${isExpired() ? 'expired_service' : 'active_service'}`}>
            {isExpired() ? 'Expired' : 'Active'}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Accordion;