import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  colore_aste: "",
  colore_frame: "",
  colore_lenti: "",
  esa_color_frame_primary: "",
  esa_color_frame_secondary: "",
  esa_color_lenses_primary: "",
  esa_color_lenses_secondary: "",
  nome_occhiale: "",
  url_glb: "",
  url_glb_complete: "",
  url_usdz: "",
  url_usdz_complete: "",
};

const modello = createSlice({
  name: "modello",
  initialState,
  reducers: {
    setModello(state, action) {
      const {coloreAste, coloreFrame, coloreLenti, esaColorFPrimary, esaColorFSecondary, esaColorLPrimary, esaColorLSecondary, nomeOcchiale, urlGlb, urlGlbComplete, urlUsdz, urlUsdzComplete} = action.payload;
      state.colore_aste = coloreAste;
      state.colore_frame = coloreFrame;
      state.colore_lenti = coloreLenti;
      state.esa_color_frame_primary = esaColorFPrimary;
      state.esa_color_frame_secondary = esaColorFSecondary;
      state.esa_color_lenses_primary = esaColorLPrimary;
      state.esa_color_lenses_secondary = esaColorLSecondary;
      state.nome_occhiale = nomeOcchiale;
      state.url_glb = urlGlb;
      state.url_glb_complete = urlGlbComplete;
      state.url_usdz = urlUsdz;
      state.url_usdz_complete = urlUsdzComplete;
    },
    // setColoreAste(state, action) {
    //   state.colore_aste = action.payload;
    // },
    // setColoreFrame(state, action) {
    //   state.colore_frame = action.payload;
    // },
    // setColoreLenti(state, action) {
    //   state.colore_lenti = action.payload;
    // },
    // setEsaColorFramePrimary(state, action) {
    //   state.esa_color_frame_primary = action.payload;
    // },
    // setEsaColorFrameSecondary(state, action) {
    //   state.esa_color_frame_secondary = action.payload;
    // },
    // setEsaColorLensesPrimary(state, action) {
    //   state.esa_color_lenses_primary = action.payload;
    // },
    // setEsaColorLensesSecondary(state, action) {
    //   state.esa_color_lenses_secondary = action.payload;
    // },
    // setNomeOcchiale(state, action) {
    //   state.nome_occhiale = action.payload;
    // },
    // setUrlGlb(state, action) {
    //   state.url_glb = action.payload;
    // },
    // setUrlGlbComplete(state, action) {
    //   state.url_glb_complete = action.payload;
    // },
    // setUrlUsdz(state, action) {
    //   state.url_usdz = action.payload;
    // },
    // setUrlUsdzComplete(state, action) {
    //   state.url_usdz_complete = action.payload;
    // },
  },
});

export const modelloActions = modello.actions;

export default modello.reducer;
