import React from "react";
import styles from "./ModalQR.module.css";
import {QRCodeSVG} from 'qrcode.react';

function ModalQR(props) {

  return (
    <div>
      {props.isLarge &&
        <div className={styles.modal}>
          <div className={styles["modal-dialog"]}>
            <div className={styles["modal-body"]}>{props.children}</div>
          </div>
        </div>
       }

        {props.typeQr ? (
           <div className={styles["modal-small"]}>
           <div className={styles["modal-dialog-small"]}>
             <div className={styles["modal-body-small"]}>
               <div>
                 {/* <div className={styles["container-model-ar"]}>
                   <div className={styles["text-modal-view"]}>
                     Try this experience in <br />
                     <span>Argumented Reality</span>
                   </div>
                 </div> */}
                 <div className={styles['container-qr']}>
                   <div className={styles['qr-code']}>
                    <QRCodeSVG value={props.link} bgColor={props.bgColor} size={120} fgColor={props.fgColor} style={{borderRadius:"10px"}}/>
                   </div>
                
                 <div className={styles['text-qr']}>
   {props.message}              </div>
                 </div>
                 {/* <div className={styles["container-model-ar"]}>
                   <p className={styles["by-spaarkly"]}>
                     {" "}
                     by{" "}
                     <a
                       href="https://www.spaarkly.it/it/"
                       target="_blank"
                       rel="noreferrer"
                     >
                       Spaarkly
                     </a>
                   </p>
                 </div> */}
               </div>
             </div>
           </div>
         </div>
        ): (
      <div className={styles["modal-small"]}>
        <div className={styles["modal-dialog-small"]}>
          <div className={styles["modal-body-small"]}>
            <div>
              {/* <div className={styles["container-model-ar"]}>
                <div className={styles["text-modal-view"]}>
                  Try this experience in <br />
                  <span>Argumented Reality</span>
                </div>
              </div> */}
              <div className={styles['container-qr']}>
                <div className={styles['qr-code']}>
                 <QRCodeSVG value={props.link} bgColor={props.bgColor} size={120} fgColor={props.fgColor} style={{borderRadius:"10px"}}/>
                </div>
             
              <div className={styles['text-qr']}>
{props.message}              </div>
              </div>
              {/* <div className={styles["container-model-ar"]}>
                <p className={styles["by-spaarkly"]}>
                  {" "}
                  by{" "}
                  <a
                    href="https://www.spaarkly.it/it/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Spaarkly
                  </a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      )} 
    </div>
  );
}

export default ModalQR;
