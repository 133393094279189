import { createSlice } from "@reduxjs/toolkit";

const brands = localStorage.getItem("brands");
const initialState = {
  listaBrand: brands?.split(",") ?? [],
  isIconView: true
};

const config = createSlice({
  name: "config",
  initialState,
  reducers: {
    setListaBrand(state, action) {
      state.listaBrand = action.payload;
    },
    setMenuIcon(state,action){
      state.isIconView = action.payload;
    }
  },
});

export const configActions = config.actions;

export default config.reducer;
