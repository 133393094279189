import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: "general",
    projectOnEdit: "",
    isDataProjectIsLoad: false,
    qrCode: false,
    qrForeGroundColor: '#000000',
    qrBackGroundColor: '#ffffff',
    modalQr: false,
  },
];

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setProjectOnEdit(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.projectOnEdit = value;
      }
    },
    setFirebaseDataLoad(state, action){
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.isDataProjectIsLoad = value;
      }
    },
    setQrCode(state, action) {
      const { idConfig } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrCode = !selectConfig.qrCode;
      }
    },
    setQrBackGroundColor(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrBackGroundColor = value;
      }
    },
    setQrForeGroundColor(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrForeGroundColor = value;
      }
    },
    setModalQr(state, action) {
      const { idConfig } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.modalQr = !selectConfig.modalQr;
      }
    }
  },
});

export const generalActions = configSlice.actions;

export default configSlice.reducer;
