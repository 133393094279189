import {
  SET_ANALYTICS_FILTER_OPTIONS,
  SET_LINE_GLASS_MODEL,
  OVERVIEW_DATA,
  GLASS_ARRAY,
  VAR_ARRAY,

} from '../actionType'

const INIT_STATE = {
  filterOptions: {
    line: '',
    glass: '',
    variant: ''
  },
  lineList: [],
  glassList: [],
  modelList: [],
  overview:{
    uniqueUser:0,
    newUser:0,
    sessions:0,
    avgTime:0
  },
  glassArray: new Map(),
  variantArray:new Map()
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ANALYTICS_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.options
      }
    case SET_LINE_GLASS_MODEL:
      return {
        ...state,
        ...action.payload
      }
    case OVERVIEW_DATA: 
      return {
        ...state,
        ...action.payload
      }
    case GLASS_ARRAY: 
    
    state.glassArray = action.payload
    
      return {
        ...state,
        
      }
    case VAR_ARRAY: 

    state.variantArray = action.payload  
      return {
        ...state,
      }

    default:
      return state
  }
}
