import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import ChartFilter from "../../general/dashboard/chartFilter";
import { format } from 'date-fns';

const Filters = ({
  typeData,
  dateRange,
  onChangeService,
  onChangeDate,
  onClearFilter,
}) => (
  <Row>
    <Col lg={3} sm={12}>
      <ChartFilter onChangeService={onChangeService} typeData={typeData}/>
    </Col>
    <Col lg={3} sm={12}>
      <FormGroup>
        <Label for="selectorInput">Select Service</Label>
        <Input
          type="select"
          onChange={(e) => {
            const selectedService = e.target.value;
            // console.log('Selected Service:', selectedService);
            onChangeService(selectedService);
          }}
          value={typeData}
        >
          <option value="app">VTO Apps</option>
          <option value="3d">3D/AR Viewer</option>
          <option value="WebVto">WEB VTO</option>
          <option value="all">ALL</option>
        </Input>
      </FormGroup>
    </Col>
    <Col lg={3} sm={12}>
      <FormGroup style={{ display: "flex", flexDirection: "column" }}>
        <Label for="selectorInput">Select Date Range</Label>
        <Button
          color="primary"
          onClick={() => {
            // console.log('Date Range Selected:', dateRange);
            onChangeDate();
          }}
        >
          {dateRange
            ? format(new Date(dateRange.startDate), 'dd MMM, yyyy') +
            ' - ' +
            format(new Date(dateRange.endDate), 'dd MMM, yyyy')
            : 'Select Date Range'}
        </Button>
      </FormGroup>
    </Col>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "2px",
        marginTop: "0.5rem",
      }}
    >
      <Button
        color="dark"
        outline
        onClick={() => {
          // console.log('Filters Cleared');
          onClearFilter();
        }}
      >
        Clear Filter
      </Button>
    </div>
  </Row>
);

export default Filters;