import React from 'react';
import styles from './Menu.module.css';

const Menu = ({ onItemClick, isAnimating ,currentAnimationIndex  }) => {
  return (
    <div className={styles["menu"]}>
      {[...Array(6)].map((_, index) => (
        <div
          key={index}
          className={`${styles["menu-item"]} ${styles[`item-${index + 1}`]} ${index === currentAnimationIndex ? styles["selected"] : ""}`}
          onClick={() => onItemClick(index)}
          style={{ pointerEvents: isAnimating ? 'none' : 'auto' }}
        />
      ))}
    </div>
  );
};

export default Menu;