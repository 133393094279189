import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { db } from "../data/base";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import { BsCode } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import styles from "./CardBrandPage.module.css";
import ColumnSubTab from "../component/modelViewer/ColumnSubTab";
import ModalIframe from "../component/modelViewer/ModalIframe";
import SearchComponent from "../component/Search";
import { ACTIVE_BASE_URL } from "../root";

function CardBrandPage() {
  const brands = [];

  const [glasses, setGlasses] = useState([]);
  const [model, setModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedModelIndex, setSelectedModelIndex] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null)
  const [modelsBrandSelected, setModelsBrandSelected] = useState([]);
  const [modalIframe, setModalIframe] = useState(false);
  const [glassesIdList, setGlassesIdList] = useState([]);
  const [indexModelExportCode, setIndexModelExportCode] = useState();
  const [brandIdSelected, setBrandIdSelected] = useState();
  const [variantDepth, setVariantDept] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const { selectedBrand } = useSelector((state) => state);

  const rxBrands = useSelector((state) => state.config.listaBrand);
  // console.log(rxBrands);

  const history = useHistory();

  // Check
  const handleOnChangeBrandId = async () => {
    setLoading(true);

    const listGlasses = [];
    const listIdsGlasses = [];
    const brandId = selectedBrand?.id || "all";

    // Creiamo un array di ID validi da rxBrands se brandId è "all"
    const validIds = brandId === "all" ? new Set(rxBrands) : new Set();

    if (brandId || validIds.size > 0) {
      try {
        // Recuperiamo tutti i documenti dalla collezione Visualizzatori3D
        const visualizzatoreRef = await db.collection("Visualizzatori3D").get();
        setLastDoc(null);

        // Promesse per recuperare gli occhiali
        const glassesPromises = visualizzatoreRef.docs.map(async (doc) => {
          const data = doc.data();

          // Controlliamo la proprietà 'brand' del documento
          if (data && (brandId === "all" ? validIds.has(data.brand) : data.brand === brandId)) {
            const glassesRef = await db
              .collection("Visualizzatori3D")
              .doc(doc.id)
              .collection("Glasses")
              .get();

            glassesRef.forEach((g) => {
              listGlasses.push({
                ...g.data(),
                brandId: doc.id,
                glassID: g.id,
              });
              listIdsGlasses.push(g.id);
            });
          }
        });

        // Attendiamo il completamento di tutte le promesse
        await Promise.all(glassesPromises);

        // Debug: Mostriamo i risultati nel log
        // console.log("Brand id - Lista Glasses", brandId, listGlasses);

        setGlasses(listGlasses);
        setGlassesIdList(listIdsGlasses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching glasses:", error);
        setLoading(false);
        toast.error("Error fetching glasses. Please try again later.");
      }
    }
  };

  useEffect(() => {
    // console.log("Selected Brand:", selectedBrand);
    setModel([]);
    setModelsBrandSelected([]);
    setLastDoc(null);
    handleOnChangeBrandId();
  }, [selectedBrand?.id]);

  useEffect(() => {
    handleOnGlassChange();
  }, [glasses]);

  useEffect(() => {
    handleOnSelectModelIndex();
  }, [selectedModelIndex]);

  // useEffect(() => {
  //   console.log("Selected Brand:", selectedBrand);
  //   handleOnChangeBrandId();
  // }, [selectedBrand?.id]);

  const isModelView = useMemo(() => {
    return selectedModelIndex === null || selectedModelIndex === "undefined";
  }, [selectedModelIndex]);

  const filteredModelAndVariants = useMemo(
    () =>
      [...(isModelView ? model : modelsBrandSelected)].filter((glass) => {
        return glass.nomeOcchiale
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());
      }),
    [isModelView, model, modelsBrandSelected, searchQuery]
  );

  const handleOnGlassChange = async () => {
    setLoading(true);

    const modelsRef = db.collection("Modello");

    try {
      const result = await modelsRef.get();

      const models = [];
      if (glasses) {
        result.docs.forEach((m) => {
          const data = m.data();
          const foundGlass = glasses.find((g) => g.initial_model === m.id);
          if (foundGlass) {
            models.push({ ...data, brandId: foundGlass.brandId, glassID: foundGlass.glassID });
          }
        });
      }

      setModel(models);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching models:", error);
      setLoading(false);
      toast.error("Error fetching models. Please try again later.");
    }
  };

  useEffect(() => {
    handleOnGlassChange();
  }, [glasses]);

  const handleOnSelectModelIndex = async () => {

    const selectedModel = model[selectedModelIndex];

    setLoading(true)
    const models = [];
    const modelsId = [];
    if (typeof selectedModel !== 'undefined') {
      // console.log("SELECTED", selectedModel);
      const modelsRef = await db.collection("Modello").get()

      modelsRef.docs.map((m) => {
        const data = m.data();
        // console.log("DATA",data);

        if (typeof data.loadingId !== 'undefined') {
          // console.log( "DATA Loading", data.loadingId)
          const glassesIDCode = data.loadingId.substring(0, 5);
          const selGlassesCode = selectedModel.loadingId.substring(0, 5);

          if (glassesIDCode === selGlassesCode) {
            // console.log("TROVATO")
            models.push({
              ...data,
              brandId: selectedModel?.brandId,
              glassID: selectedModel?.glassID,
              id: m.id
            });
            modelsId.push(m.id);
          }
        }
      });
    }
    setLoading(false);
    setModelsBrandSelected([...models]);
  };

  // const handleOnSelectModelIndex = async () => {
  //   const selectedModel = model[selectedModelIndex];
  //   const modelsRef = await db.collection("Modello").get();
  //   const models = [];
  //   const modelsId = [];
  //   modelsRef.docs.map((m) => {
  //     const data = m.data();
  //     if (data.nomeOcchiale === selectedModel?.nomeOcchiale) {
  //       models.push({
  //         ...data,
  //         brandId: selectedModel.brandId,
  //         glassID: selectedModel.glassID,
  //       });
  //       modelsId.push(m.id);
  //     }
  //   });
  //   setModelsBrandSelected([...models]);
  // };

  useEffect(() => {
    handleOnSelectModelIndex();
  }, [selectedModelIndex]);

  function modelPageHandler(i) {
    // console.log("index", i);
    if (variantDepth === 0) {
      setSelectedModelIndex(i);
      setIndexModelExportCode(i);
      setVariantDept(variantDepth + 1);
    }
  }

  function handleExportCode(i, m) {
    if (m.stato !== "Incompleto") {
      setBrandIdSelected(m.brandId);
      setModalIframe(!modalIframe);
      setIndexModelExportCode(i);
      setSelectedModel(m);
    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  function handleTextArea() { }

  function copyHandler(type) {
    let copyText = null;
    if (type === "single") {
      copyText = document.getElementById("code-iframe-single");
    } else {
      copyText = document.getElementById("code-iframe");
    }
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast.success("Text copied to your clipboard");
  }

  function handleGenerateLink(i, m) {
    if (m.stato !== "Incompleto") {
      setBrandIdSelected(m?.brandId);
      setIndexModelExportCode(i);

      if (variantDepth == 1) {
        window.open(
          `${process.env.PUBLIC_URL}/Arshades3d/${m?.brandId}/glasses/${m.glassID}/variant/${m.id}`,
          "_blank"
        );
        // console.log("Variant");
      } else {
        // console.log("Glasses")
        window.open(
          `${process.env.PUBLIC_URL}/Arshades3ds/${m?.brandId}/glasses/${m.glassID}`,
          "_blank"
        );
      }

    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  function handleReturnOnGlasses() {
    // console.log("VARIANT DEPTH", variantDepth)
    setSelectedModelIndex(null)
    setVariantDept(variantDepth - 1);
  }

  function handleEditARView(i, m) {
    if (m.stato !== "Incompleto") {
      setBrandIdSelected(m?.brandId);
      history.push(`/editARShadesViewer/${m?.brandId}/glasses/${m.glassID}`);
    } else {
      toast.error("Glass is in Incomplete State");
    }
  }

  let iframeString = `<iframe id="spaarkly-container" src="${ACTIVE_BASE_URL}/Arshades3ds/${selectedModel?.brandId}/glasses/${selectedModel?.glassID}"
  allow="xr-spatial-tracking" style="height: 900px; width: 100%; cursor: default;"> </iframe>
  <style>
  @media screen and (max-width: 992px){   
    #spaarkly-container{ 
      height: 650px; 
      width: 100%;
    }
  }
  </style>`;

  let iframeVariantString = `<iframe id="spaarkly-container" src="${ACTIVE_BASE_URL}/Arshades3d${selectedModel?.brandId}/glasses/${selectedModel?.glassID}/variant/${selectedModel?.id}"
  allow="xr-spatial-tracking" style="height: 900px; width: 100%; cursor: default;"> </iframe>
  <style>
  @media screen and (max-width: 992px){   
    #spaarkly-container{ 
      height: 650px; 
      width: 100%;
    }
  }
  </style>`;

  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(20); // Default items per page
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = filteredModelAndVariants.slice(indexOfFirstItem, indexOfLastItem);

  /*** NEW IMPLEMENTATION */
  const handleScroll = () => {

    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 50 && !loading) { // Threshold for loading more
      handleOnGlassChange();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
  }, [loading]);
  /**END OF NEW IMPLEMNATION */

  const Iframe = (i) => (
    <>
      {modalIframe && (
        <ModalIframe>
          <div className="modal-iframe">
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div>
                <p className="modal-iframe-heading">IFrame Code</p>
              </div>
              <div
                style={{
                  position: "relative",
                  top: "30px",
                  right: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setModalIframe(!modalIframe)}
              >
                <RxCross2 size={20} color="black" />
              </div>
            </div>
            <div className="iframe-container-wrapper">
              <div className="iframe-container">
                <textarea
                  id="code-iframe"
                  type="text"
                  rows="10"
                  value={variantDepth === 0 ? iframeString : iframeVariantString}
                  onChange={() => handleTextArea(i)}
                />
              </div>
            </div>
            <div className="button-container">
              <Button onClick={() => copyHandler("multi")}>
                <FaCopy className="copy-icon" />
                <span>Copy</span>
              </Button>
            </div>
          </div>
        </ModalIframe>
      )}
    </>
  );

  return (
    <>
      <div className="search-container">
        <SearchComponent
          placeholder={`Search ${isModelView ? "Model" : "Variant"}`}
          onHandleSubmit={setSearchQuery}
        />
      </div>
      {isModelView ? (
        <>
          <div className="container-heading-wrapper">
            <p className="page-heading-text">Models</p>
          </div>

          {filteredModelAndVariants.length === 0 && (
            <div> Currently, there are no validated 3D assets. Please go to the Frame Validation page to validate your assets. Afterwards, you will find the list of 3D viewers here.</div>
          )}
          <div className={styles.flexGrid}>

            {filteredModelAndVariants.map((m, i) => {
              if (m.stato?.toLowerCase() !== "incompleto") {
                return (
                  <div className={styles["row-element"]} key={i}>
                    <div className={styles["model"]}>
                      <div
                        className={`${styles["model-image"]} model-container`}
                      >
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span className={styles["label"]}>
                            {m.nomeOcchiale}
                          </span>
                        </div>
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span
                            className={`${styles["model-state"]} ${m.stato === "Incompleto"
                              ? "incomplete-modal-status"
                              : m.stato === "In Pubblicazione" || m.stato === "Pubblicato"
                                ? "published-modal-status"
                                : "ready-modal-status"
                              }`}
                          >
                            {m.stato}
                          </span>
                        </div>
                        <div
                          className={styles["model-img"]}
                          style={{
                            backgroundImage: `url(${m.poster})`,
                          }}
                          onClick={() => modelPageHandler(i)}
                        ></div>
                      </div>
                    </div>
                    <ColumnSubTab>
                      <BsCode
                        onClick={() => handleExportCode(i, m)}
                        className="action-icons"
                      />

                      <AiOutlineEye
                        onClick={() => handleGenerateLink(i, m)}
                        className="action-icons"
                      />
                      <FiEdit
                        className="action-icons"
                        onClick={() => handleEditARView(i, m)}
                      />
                    </ColumnSubTab>
                    {modalIframe && Iframe(i)}
                  </div>
                );
              } else {
                return null;
              }
            })}
            {(loading || filteredModelAndVariants.length == 0) && (
              <div className='d-flex justify-content-center align-items-center p-5 '>
                <Spinner />
              </div>
            )}
            {/* Pagination */}
            <nav style={{ marginTop: 50 }}>

              {/* <ul className="pagination">
                {Array.from({ length: Math.ceil(filteredModelAndVariants.length / itemsPerPage) }).map((_, index) => (
                  <li key={index} className={`page-item`}>
                    <button className={`page-link  ${currentPage === index + 1 ? 'bg-success text-white' : 'text-secondary'}`} onClick={() => paginate(index + 1)}>{index + 1}</button>
                  </li>
                ))}
              </ul> */}
            </nav>
          </div>
        </>
      ) : (
        <>
          <div className="container-heading-wrapper">
            <MdOutlineArrowBackIosNew
              onClick={() => handleReturnOnGlasses()}
              className="back-icon"
            />
            <p className="page-heading-text">Variants</p>
          </div>
          <div className={styles.flexGrid}>
            {filteredModelAndVariants?.map((m, i) => {
              if (m.stato?.toLowerCase() !== "incompleto") {
                return (
                  <div className={styles["row-element"]} key={i}>
                    <div className={styles["model"]}>
                      <div
                        className={`${styles["model-image"]} model-container`}
                      >
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span className={styles["label"]}>
                            {m.nomeOcchiale}
                          </span>
                        </div>
                        <div
                          className={styles["Group"]}
                          style={{ zIndex: "2" }}
                          onClick={() => modelPageHandler(i)}
                        >
                          <span
                            className={`${styles["model-state"]} ${m.stato === "Incompleto"
                              ? "incomplete-modal-status"
                              : m.stato === "In Pubblicazione"
                                ? "published-modal-status"
                                : "ready-modal-status"
                              }`}
                          >
                            {m.stato}
                          </span>
                        </div>
                        <div
                          className={styles["model-img"]}
                          style={{
                            backgroundImage: `url(${m.poster})`,
                          }}
                          onClick={() => modelPageHandler(i)}
                        ></div>
                      </div>
                    </div>
                    <ColumnSubTab>
                      <BsCode
                        onClick={() => handleExportCode(i, m)}
                        className="action-icons"
                      />
                      <AiOutlineEye
                        onClick={() => handleGenerateLink(i, m)}
                        className="action-icons"
                      />
                      <FiEdit
                        className="action-icons"
                        onClick={() => handleEditARView(i, m)}
                      />
                    </ColumnSubTab>
                    {modalIframe && Iframe(i)}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}
    </>
  );
}

export default CardBrandPage;