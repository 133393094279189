import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { FormField } from "./FormField";
import { useForm } from "react-hook-form";
import { db } from "../../../data/base";
import { toast } from "react-toastify";

export const UserInfoForm = ({ userInfo, setUserInfo }) => {
  const {
    reset,
    register,
    handleSubmit,
    errors,
    formState: { dirty },
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset(userInfo);
  }, [userInfo, reset]);


  const submit = async data => {
    try {
      setLoading(true);

      const collectionRef = db?.collection("Client");
       
      const docRef = collectionRef?.doc(userInfo.collectionId);
      await docRef.update(data);
      const temp = { ...userInfo };
      temp.phone_number = data.phone_number;
      setUserInfo(temp);
      toast.success("Data Added Successfully!");
    } catch (error) {
      toast.error("Error adding data: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="p-20">
      <FormField
        label="First Name"
        placeholder={userInfo?.firstName ?? ""}
        disabled
      />
      <FormField
        label="Surname"
        placeholder={userInfo?.lastName ?? ""}
        disabled
      />
      <FormField
        label="Company"
        placeholder={userInfo?.company ?? ""}
        disabled
      />
      <FormField label="Role" placeholder={userInfo?.role ?? ""} disabled />
      <FormField
        label="Email"
        placeholder={localStorage.getItem("email")?.trim() ?? ""}
        disabled
      />
      <Form onSubmit={handleSubmit(submit)}>
        {/* <FormField
          name="phone_number"
          label="Phone Number"
          placeholder="Phone Number"
          error={errors?.phone_number?.message}
          innerRef={register({
            required: "Phone Number is required",
            pattern: {
              value: /^\+?\d+$/, // Allow numbers starting with "+" sign
              message: "Invalid number",
            },
            minLength: { value: 7, message: "Too Short" },
            maxLength: { value: 15, message: "Too Long" },
          })}
          disabled={loading}
        /> */}
        <Row>
          <Col sm={3} />
          <Col sm={5} style={{ display: "flex", gap: "1rem" }}>
            <Button
              type="button"
              color="dark"
              outline
              disabled={!dirty || loading}
              onClick={() => reset(userInfo)}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={!dirty || loading}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
