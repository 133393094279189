import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap';
import {
  setAnalyticsFilterOptions,
  setLineGlassModel,
} from '../../../redux/analytics/actions';
import { toast } from 'react-toastify';
import { db } from '../../../data/base';
import firebase from 'firebase';

function ChartFilter(props, { className }) {
  const [filteredGlassList, setFilteredGlassList] = useState([]);
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const dispatch = useDispatch();
  const filterOptions = useSelector((state) => state.analytics.filterOptions);
  const lineList = useSelector((state) => state.analytics.lineList);
  const glassList = useSelector((state) => state.analytics.glassList);
  const modelDocList = useSelector((state) => state.analytics.modelList);
  const selectedBrand = useSelector((state) => state.selectedBrand);
  const listaRefsLinea = useSelector(
    (state) => state.selectedBrand?.listaRefsLinea
  );

  const rxBrands = useSelector((state) => state.config.listaBrand);

  const { line, glass } = filterOptions;

  const handleSelectionChange = (optionName, value) => () => {

    const updatedFilterOptions = { ...filterOptions };
    updatedFilterOptions[optionName] = value || '';
    if (optionName === 'line') {
      updatedFilterOptions.line = value ?? '';
      updatedFilterOptions.glass = '';
      updatedFilterOptions.variant = '';
      // console.log("update", updatedFilterOptions)
    } else if (optionName === 'glass') {
      const glass = glassList.find((item) => item.id === value);
      const line = lineList.find((item) => item.id === glass?.lineaRef.id);

      updatedFilterOptions.glass = glass?.id ?? '';
      updatedFilterOptions.line = line?.id ?? '';
      updatedFilterOptions.variant = '';
    } else if (optionName === 'variant') {
      // glassId is available in modelList that is created from sessionList
      const model = modelDocList.find((item) => item.id === value);

      const glass = glassList.find((item) => item.id === model?.glassId);
      const line = lineList.find((item) => item.id === glass?.lineaRef.id);
      updatedFilterOptions.variant = model?.id ?? '';
      updatedFilterOptions.glass = glass?.id ?? '';
      updatedFilterOptions.line = line?.id ?? '';
    }
    dispatch(setAnalyticsFilterOptions(updatedFilterOptions));
  };

  // const handleSelectionChangeOld = (optionName, value) => () => {
  //   const updatedOptions = { ...filterOptions, [optionName]: value };
  //   if (optionName === 'line') {
  //     updatedOptions.glass = '';
  //     updatedOptions.variant = '';
  //   } else if (optionName === 'glass') {
  //     const glassDoc = glassList.find((g) => g.id === value);
  //     const lineDoc = glassDoc
  //       ? lineList.find((l) => l.id === glassDoc?.lineaRef?.id)
  //       : '';
  //     updatedOptions.line = lineDoc.id;
  //     updatedOptions.variant = '';
  //   }
  //   dispatch(setAnalyticsFilterOptions(updatedOptions));
  // };
  // Converting data into chunks
  const chunkArray = (array, size = 10) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const getAllLineModels = async () => {
    try {
      const chunks = chunkArray(rxBrands);
      let allBrands = [];

      for (const chunk of chunks) {
        const snapshot = await db
          .collection('Brand')
          .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
          .get();
        const brands = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        allBrands = allBrands.concat(brands);
      }

      const promises = allBrands.map((brand) =>
        getLineGlassModel(brand?.listaRefsLinea)
      );

      const allData = await Promise.all(promises);

      const lines = [];
      const glasses = [];
      const models = [];

      allData.forEach((item) => {
        lines.push(...(item?.lineList ? item.lineList : []));
        glasses.push(...(item?.glassList ? item.glassList : []));
        models.push(...(item?.modelList ? item.modelList : []));
      });

      dispatch(
        setLineGlassModel({
          lineList: lines,
          glassList: glasses,
          modelList: models,
        })
      );
    } catch (e) {
      // toast.error(e?.message ?? 'Error while fetching data!');
    }
  };

  const getLineGlassModelUtility = async (listRef) => {
    const linePromises = listRef.map((ref) => ref.get());

    const lineDocs = await Promise.all(linePromises);
    const lines = lineDocs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const glassPromises = lines.flatMap((line) =>
      line.listaRefsOcchiale.map((ref) => ref.get())
    );
    const glassDocs = await Promise.all(glassPromises);
    const glasses = glassDocs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const variantPromises = glasses.flatMap((glass) =>
      glass.lista_taglie.map((ref) => ref.get())
    );
    const variantDocs = await Promise.all(variantPromises);

    const modelPromises = variantDocs.flatMap((doc) =>
      doc.data().listaRefModels.map((ref) => ref.get())
    );
    const modelDocs = await Promise.all(modelPromises);
    const models = modelDocs.map((doc) => ({ id: doc.id, ...doc.data() }));

    return {
      lineList: lines,
      glassList: glasses,
      modelList: models,
    };
  };

  const getLineGlassModel = async (listRef) => {
    try {
      setIsFilterLoading(true);
      const obj = await getLineGlassModelUtility(listRef ?? listaRefsLinea);
      if (listRef) {
        return obj;
      } else {
        const { lineList, glassList, modelList } = obj;
        dispatch(
          setLineGlassModel({
            lineList,
            glassList,
            modelList,
          })
        );
      }
    } catch {
      // toast.error('Error while fetching data');
    } finally {
      setIsFilterLoading(false);
    }
  };

  useEffect(() => {
    // console.log("Call chart effect 1")

    if (selectedBrand?.id === 'all') {
      getAllLineModels();
    } else if (listaRefsLinea) {
      getLineGlassModel();
    }
  }, [listaRefsLinea, selectedBrand]);

  useEffect(() => {
    // console.log("Call chart effect 2")
    if (!!line) {
      const lineDoc = lineList.find((item) => item.id === line);
      const lineGlassList = lineDoc?.listaRefsOcchiale?.map((item) => item.id);
      const filteredGlassList = glassList.filter((item) =>
        lineGlassList?.includes(item.id)
      );
      setFilteredGlassList(filteredGlassList);
    } else {
      // console.log("Call")
      setFilteredGlassList(glassList);
    }
  }, [glassList, line, lineList]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const lineDoc = useMemo(
    () => lineList.find((item) => item.id === line),
    [line, lineList]
  );

  const resetFilter = () => {
    dispatch(
      setAnalyticsFilterOptions({
        line: '',
        glass: '',
        variant: '',
      })
    );
  };

  const resetModels = () => {
    dispatch(
      setAnalyticsFilterOptions({
        line,
        glass: '',
        variant: '',
      })
    );
  };

  const dropdownToggleText = () => {
    const isGlassSelected = !!filteredGlassList.find(
      (item) => glass === item.id
    )?.id;
    if (isGlassSelected) {
      return (
        isGlassSelected?.nome_modello ??
        lineDoc?.nome_linea
      );
    }
    return lineDoc?.nome_linea ?? 'All';
  };

  return (
    <FormGroup>
      <Label for='selectorInput'>
        Line / Model Selector
        {isFilterLoading && <Spinner size='sm' style={{ marginLeft: '10px' }} />}
      </Label>
      <div className='d-flex align-items-center'>
        <Dropdown
          style={{ width: '100%' }}
          isOpen={isDropdownOpen}
          toggle={() => setIsDropdownOpen(!isDropdownOpen)}
          direction='down'
        >
          <div>
            <DropdownToggle
              caret={!isFilterLoading}
              style={{ width: '100%' }}
              outline
              disabled={!lineList?.length || isFilterLoading}
            >
              {isFilterLoading ? <Spinner size='sm' /> : dropdownToggleText()}
            </DropdownToggle>
          </div>
          <DropdownMenu
            style={{
              outline: '0.01em solid black',
              minWidth: '100%',
              maxHeight: '20rem',
              overflow: 'scroll',
              scrollbarWidth: '0px',
              msOverflowStyle: 'none',
              '&::WebkitScrollbar?': {
                display: 'none',
              },
            }}
          >
            <DropdownItem
              active={!line}
              onClick={resetFilter}
              style={{ marginTop: '0.5rem' }}
              key={"allLineFilter"}

            >
              All Lines
            </DropdownItem>
            {lineList?.map((item, idx) => (
              <DropdownItem
                key={`${item.nome_linea}_${idx}_${item.id}`}
                active={!!item?.id && line === item.id}
                disabled={!item.id}
                onClick={handleSelectionChange('line', item.id)}
              >
                {item?.nome_linea ?? ''}
              </DropdownItem>
            ))}
            <DropdownItem
              active={!glass}
              onClick={resetModels}
              style={{ marginTop: '0.5rem' }}
              key={"allModelFilter"}
            >
              All Models
            </DropdownItem>

            {filteredGlassList.map((item, idx) => (
              <DropdownItem
                key={`${item.nome_modello}_${idx}_${item.id}`}
                active={!!item?.id && glass === item.id}
                disabled={!item.id}
                onClick={handleSelectionChange('glass', item.id)}
                value={item.id}
              >
                {item.nome_modello}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {line ? (
          <span
            style={{ color: 'black', marginLeft: 10, fontSize: 20 }}
            className='cursor-pointer'
            onClick={() => {
              dispatch(
                setAnalyticsFilterOptions({
                  line: '',
                  glass: '',
                  variant: '',
                })
              );

            }}
          >
            &times;
          </span>
        ) : null}
      </div>
    </FormGroup>
  );
}

export default ChartFilter;