import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import app from "../data/base";

import logo from "../assets/images/logo.png";

const ForgotPasswordForm = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await app.auth().sendPasswordResetEmail(data.email);
      toast.success("Password reset email sent. Please check your inbox.");
    } catch (error) {
      toast.error("Failed to send password reset email. Please try again.");
    }
    setLoading(false);
  };

  const goBack = () => {
    history.goBack();
  };

  const [loading, setLoading] = useState(false);

  return (
    <div className="login-form-container">
      <Card className="login-card">
        <Card.Header className="card-header">
          <img src={logo} alt="logo" className="logo" />
          <p className="card-heading">Forgot Password?</p>
          <p className="card-sub-heading">
            We will email you a password reset link to your email address.
          </p>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              block
              className="submit-button"
              disabled={loading}
            >
              Confirm
            </Button>
            <Button
              type="button"
              block
              className="back-to-login"
              disabled={loading}
              onClick={goBack}
            >
              Back to Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="circle" />
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordForm;
