import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  descrizione: "",
  descrizione_en: "",
  id: "",
  img_url: "",
  linear_ref: {},
  lista_categorie: [],
  glasses_inclination_coeff: 0,
  nome_modello: "",
  priority: 0,
  url_product: "",
  url_shadow: "",
  url_image: "",
  taglia: {},
  indexTagliaSelezionata: 0,
  brand: {},
  taglia_list: [],
};

const occhiale = createSlice({
  name: "occhiale",
  initialState,
  reducers: {
    setDescrizione(state, action) {
      state.descrizione = action.payload;
    },
    setDescrizioneEn(state, action) {
      state.descrizione_en = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setImgUrl(state, action) {
      state.img_url = action.payload;
    },
    setLinearRef(state, action) {
      state.linear_ref = action.payload;
    },
    setListaCategorie(state, action) {
      state.lista_categorie = action.payload;
    },
    setGlassesInclinationCoeff(state, action) {
      state.glasses_inclination_coeff = action.payload;
    },
    setNomeModello(state, action) {
      state.nome_modello = action.payload;
    },
    setPriority(state, action) {
      state.priority = action.payload;
    },
    setUrlProduct(state, action) {
      state.url_product = action.payload;
    },
    setUrlShadow(state, action) {
      state.url_shadow = action.payload;
    },
    setUrlImage(state, action) {
      state.url_image = action.payload;
    },
    setTaglia(state, action) {
      state.taglia = action.payload;
    },
    setIndexTagliaSelezionata(state, action) {
      state.indexTagliaSelezionata = action.payload;
    },
    setBrand(state, action) {
      state.brand = action.payload;
    },
    setTagliaList(state, action) {
      state.taglia_list = action.payload;
    },
    pushToTagliaList(state, action) {
      state.taglia_list.push(action.payload);
    },
    setTagliaListElement(state, action) {
      const {index, value} = action.payload;
      state.taglia_list[index] = value;
    },
    spliceToTagliaList(state) {
      const i = state.taglia_list.length;
      state.taglia_list.splice(i - i - 1);
    },
  },
});

export const occhialeActions = occhiale.actions;

export default occhiale.reducer;
