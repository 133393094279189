import { differenceInDays, addDays, format } from "date-fns";
import { db } from "../../../../data/base";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { getLinearGraphData } from "../../../charts/chartjscharts/chartjsData";
import { useState } from "react";
import * as XLSX from 'xlsx';


export const endDate = new Date();
endDate.setHours(23, 59, 59); // Imposta le ore a 23:59:59.999
export const startDate = new Date(endDate);

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  return format(dateObject, "yyyy-MM-dd");
};

const formatDateTwo = (dateString) => {
  const dateObject = new Date(dateString);
  return format(dateObject, "yyyy/MM/dd");
};

const getLineNames = async (lineRefs) => {
  const lineNames = {};

  // Usa Promise.all per recuperare tutti i documenti in parallelo
  const promises = lineRefs.map(async (lineRef) => {
    const docRef = db.collection("Linea").doc(lineRef);
    const docSnap = await docRef.get();
    if (docSnap.exists) {
      lineNames[lineRef] = docSnap.data().nome_linea;
    } else {
      lineNames[lineRef] = "Unknown Linea";
    }
  });

  await Promise.all(promises);
  return lineNames;
};

// Funzioni di supporto aggiuntive
const calculateAverageSessionTime = (totalTime, numberOfSessions) => {
  return numberOfSessions === 0 ? 0 : totalTime / numberOfSessions;
};

const calculateAverageSessionsPerUser = (
  numberOfSessions,
  uniqueUsersCount
) => {
  return uniqueUsersCount === 0 ? 0 : numberOfSessions / uniqueUsersCount;
};

const calculateAverageEngagementTime = (totalTime, uniqueUsersCount) => {
  return uniqueUsersCount === 0 ? 0 : totalTime / uniqueUsersCount;
};

export const fetchData = async (date, brandId, props) => {
  try {
    const startDate = new Date(date);
    startDate.setHours(0, 0, 1);

    const endDate = new Date(date);
    endDate.setHours(23, 59, 59);

    const startDateTwo = format(startDate, "yyyy/MM/dd 00:00:00");
    const endDateTwo = format(endDate, "yyyy/MM/dd 23:59:59");

    const formattedDate = date; // "yyyy/MM/dd"
    // console.log("Call fetchData Brand")
    const brandRef = db.collection("Brand").doc(brandId);
    const brandDoc = await brandRef.get();
    const brandData = brandDoc.data();
    const brandCodePath = brandData.code_path;

    const modelsMap = await fetchModelDocuments(brandCodePath);
    const allData = await fetchAllData(brandRef, startDateTwo, endDateTwo);

    if (
      allData["Sessione"].length === 0 &&
      allData["Sessione_WebVto"].length === 0 &&
      allData["Sessione_Visualizzatori3d"].length === 0
    ) {
      return false;
    }

    for (const [collection, sessionsData] of Object.entries(allData)) {
      const uniqueUsers = new Set(
        sessionsData.map(
          (session) =>
            `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device
            }`
        )
      );

      const service =
        collection === "Sessione"
          ? "APP"
          : collection === "Sessione_WebVto"
            ? "WEB VTO"
            : "3D VIEWER";
      const aggregatedDataExists = await checkIfAggregatedDataExists(
        formattedDate,
        brandRef,
        service
      );
      if (aggregatedDataExists) {
        return false;
      }

      const metricsData = await calculateMetrics(
        sessionsData,
        uniqueUsers,
        modelsMap,
        service,
        brandRef
      );
      // console.log(metricsData)
      await uploadAnalyticsToFirebase(
        metricsData,
        service,
        formattedDate,
        brandRef
      );
    }

    return true;
  } catch (error) {
    console.error("Errore nel recupero dei dati delle sessioni:", error);
    return false;
  }
};

const fetchDataFromCollection = async (
  collectionName,
  brandRef,
  startDateTwo,
  endDateTwo
) => {
  const dbRef = db
    .collection(collectionName)
    .where("ref_catalogo", "==", brandRef)
    .where("data_inizio_sessione", ">=", startDateTwo)
    .where("data_inizio_sessione", "<=", endDateTwo)
    .orderBy("data_inizio_sessione");

  const querySnapshot = await dbRef.get();
  const sessionsData = [];

  querySnapshot.forEach((doc) => {
    const session = doc.data();
    sessionsData.push({ id: doc.id, ...session });
  });

  // console.log("collectionName", collectionName, sessionsData.length)

  return sessionsData;
};

const fetchAllData = async (brandRef, startDateTwo, endDateTwo) => {
  const collections = [
    "Sessione",
    "Sessione_WebVto",
    "Sessione_Visualizzatori3d",
  ];
  const allData = {};

  for (const collection of collections) {
    const sessionsData = await fetchDataFromCollection(
      collection,
      brandRef,
      startDateTwo,
      endDateTwo
    );
    allData[collection] = sessionsData;
  }

  return allData;
};

// Funzione per estrapolare il browser dai dati del dispositivo
const extractBrowserFromDevice = (device) => {
  const browserMatch = device.match(
    /(Firefox|Chrome|Safari|Opera|Edge|Trident|FBAV|Instagram|SamsungBrowser)\/?\s*(\d+)/i
  );
  if (browserMatch) {
    let browser = browserMatch[1];
    // Identifica se è mobile o desktop
    let deviceType = "Desktop";
    if (
      /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari/i.test(
        device
      )
    ) {
      deviceType = "Mobile";
    }
    // console.log(`${browser} (${deviceType})`, device )
    return `${browser} (${deviceType})`;
  }
  return "Unknown";
};

const fetchModelDocuments = async (brandCodePath) => {
  const modelsMap = new Map();
  // console.log("Call fetchData Modello")

  const modelDocsRef = db
    .collection("Modello")
    .where("loadingId", ">=", `${brandCodePath}0000000000000`)
    .where("loadingId", "<", `${parseInt(brandCodePath) + 1}0000000000000`);

  const querySnapshot = await modelDocsRef.get();
  querySnapshot.forEach((doc) => {
    const modelData = doc.data();
    modelsMap.set(doc.id, modelData);
  });

  return modelsMap;
};

// Funzione per filtrare occhiali basata su lineaRef
const filterOcchialiByLineaRef = (occhiali, validLineaRefs) => {
  // console.log('Filtering occhiali:', occhiali);
  // console.log('Valid lineaRefs:', validLineaRefs);
  return occhiali.filter((occhiale) => {
    const lineaRefId = occhiale.lineaRef && occhiale.lineaRef.id;
    // console.log('Checking occhiale:', occhiale);
    // console.log('lineaRefId:', lineaRefId);
    return validLineaRefs.includes(lineaRefId);
  });
};

// Definisci i valori di lineaRef validi per ciascun brandRef
const validLineaRefsForBrand = {
  rBf3geYISXtkue0WGgFT: [
    "EVW9rjBgsniZsnAjPWH2",
    "dWi02Aq4RFgYAGZbARuo",
    "Hdpt0OVId4SBueQObzDJ",
    "Rh3tjgtWCIXz0QX6bCZF",
    "e1nr6YatnWeXN9QdVvUd",
    "SUTMvKlcQ9WAl45vQeHg",
    "4QNS5YCaJfqBqFVra1ot",
    "SODMSCfYr9x0da8saeB3",
    "nYZivzRPTJ3qAbEFDUpZ",
  ],
  qmDTd8ciaSs31akT3Eqq: [
    "n66oxhJgPyXJcT1UbS72",
    "BfC4Zie62Ai8HMPxpHwk",
    "wXvdZoM0PvtatPWSZsVK",
    "mUPKV87BSyMotmn3UHC2",
    "k5BB0LXiVy8TxWNGmjKi",
    "hpjqsiNYFMUIjz4PMF7L",
    "5XN78gw7rupJArC66kty",
    "oP6IeFnBHZYpwMCrdBJ9",
    "aNY4n03jKOeZsUE2CpWk",
    "Nft8HyvXPr3VRfOqK9Ba",
    "beMSQNwdNekTzGl0A4R7",
    "pD2j4dJJd8SkfyKLdlkA",
    "5NpnHkVuqowJqWvJZRVz",
    "Au17IuezBz9bSyvsodBU",
    "LHXAMh5fnc7cXenqVy6B",
  ],
};

export const calculateMetrics = async (
  sessions,
  totalDevice,
  modelsMap,
  service,
  brandRef
) => {
  const MIN_SESSION_DURATION = 4; // Durata minima della sessione in secondi (4 minuti)
  const MAX_SESSION_DURATION = 450;
  // Filtra le sessioni che durano meno di 4 minuti
  const filteredSessions = sessions.filter(
    (session) => session.total_time >= MIN_SESSION_DURATION
  );

  if (filteredSessions.length === 0) {
    // Se non ci sono sessioni valide, ritorna i valori di default
    return {
      totalSessions: 0,
      totalUniqueUsers: 0,
      newUsersCount: 0,
      averageSessionTime: { minutes: 0, seconds: 0 },
      averageEngagementTime: { minutes: 0, seconds: 0 },
      averageSessionsPerUser: 0,
      lineDataArray: [],
      glassesDataArray: [],
      variantsDataArray: [],
      deviceData: [],
    };
  }

  let deviceDataMap = {};

  let totalSeconds = 0;
  let uniqueUsers = new Set();
  let newUsers = new Set();
  let lineDataMap = {};
  let glassesDataMap = {};
  let variantsDataMap = {};
  let lineRefs = new Set();

  // Aggiorna le sessioni basate su brandRef
  const updatedSessions = filteredSessions.map((session) => {
    // console.log('--- Processing session ---');
    // console.log('session.id:', session.id);
    // console.log('brandRef:', brandRef.id);
    // console.log('session.lista_occhiali_visualizzati:', session.lista_occhiali_visualizzati);

    if (
      (brandRef.id === "rBf3geYISXtkue0WGgFT" ||
        brandRef.id === "qmDTd8ciaSs31akT3Eqq") &&
      service === "APP"
    ) {
      const validRefs = validLineaRefsForBrand[brandRef.id];
      const updatedSession = {
        ...session,
        lista_occhiali_visualizzati: filterOcchialiByLineaRef(
          session.lista_occhiali_visualizzati,
          validRefs
        ),
      };
      // console.log(`Updated session for ${brandRef.id}:`, updatedSession);
      return updatedSession;
    }
    return session; // Per altri valori di brandRef, non fare nulla
  });

  updatedSessions.forEach((session) => {
    const sessionUniqueUsers = new Set();
    const sessionTotalSeconds = session.lista_occhiali_visualizzati.reduce(
      (total, occhiale) => {
        const occhialeSeconds = occhiale.listaModelliProvati.reduce(
          (acc, modello) => acc + modello.totalSeconds,
          0
        );

        if (
          occhialeSeconds <= MAX_SESSION_DURATION &&
          occhialeSeconds > MIN_SESSION_DURATION
        ) {
          total += occhialeSeconds;

          // Aggrega i dati per linea
          let lineaRef;
          if (service === "APP") {
            lineaRef = occhiale.lineaRef.id;
          } else if (service === "WEB VTO") {
            lineaRef = occhiale.lineaRefs.id;
          } else if (service === "3D VIEWER") {
            lineaRef = occhiale.lineaRef.id;
          }

          lineRefs.add(lineaRef);
          if (!lineDataMap[lineaRef]) {
            lineDataMap[lineaRef] = {
              lineRef: lineaRef,
              time: 0,
              visualization: 0,
              sessions: new Set(),
              uniqueUsers: new Set(),
              newUsers: new Set(),
            };
          }
          lineDataMap[lineaRef].time += occhialeSeconds;
          lineDataMap[lineaRef].visualization +=
            occhiale.listaModelliProvati.length;
          lineDataMap[lineaRef].sessions.add(session.id);
          lineDataMap[lineaRef].uniqueUsers.add(session.device_id);
          if (session.is_first_session) {
            lineDataMap[lineaRef].newUsers.add(session.device_id);
          }

          // Aggrega i dati per occhiale
          let glassesRef;
          if (service === "APP") {
            glassesRef = occhiale.glassesRef.id;
          } else if (service === "WEB VTO") {
            glassesRef = occhiale.glassesRefs.id;
          } else if (service === "3D VIEWER") {
            glassesRef = occhiale.glassesRef;
          }

          if (!glassesDataMap[glassesRef]) {
            glassesDataMap[glassesRef] = {
              lineRef: lineaRef,
              glassesRef: glassesRef,
              nome_occhiale: occhiale.nome_occhiale,
              time: 0,
              visualization: 0,
              sessions: new Set(),
              uniqueUsers: new Set(),
              newUsers: new Set(),
            };
          }
          glassesDataMap[glassesRef].time += occhialeSeconds;
          glassesDataMap[glassesRef].visualization +=
            occhiale.listaModelliProvati.length;
          glassesDataMap[glassesRef].sessions.add(session.id);
          glassesDataMap[glassesRef].uniqueUsers.add(session.device_id);
          if (session.is_first_session) {
            glassesDataMap[glassesRef].newUsers.add(session.device_id);
          }

          // Aggrega i dati per varianti (modelli provati)
          occhiale.listaModelliProvati.forEach((modello) => {
            const variantKey = `${modello.modelRef.id}-${modello.tagliaRef.id}`;
            const modelData = modelsMap.get(modello.modelRef.id);
            if (!variantsDataMap[variantKey]) {
              variantsDataMap[variantKey] = {
                modelRef: modello.modelRef.id,
                tagliaRef: modello.tagliaRef.id,
                occhialeRef: occhiale.id,
                esaColorFramePrimary: modelData
                  ? modelData.esaColorFramePrimary
                  : null,
                esaColorFrameSecondary: modelData
                  ? modelData.esaColorFrameSecondary
                  : null,
                esaColorLensesPrimary: modelData
                  ? modelData.esaColorLensesPrimary
                  : null,
                esaColorLensesSecondary: modelData
                  ? modelData.esaColorLensesSecondary
                  : null,
                time: 0,
                visualization: 0,
                sessions: new Set(),
                uniqueUsers: new Set(),
                newUsers: new Set(),
                glassesRef: glassesRef,
                lineRef: lineaRef,
              };
            }
            variantsDataMap[variantKey].time += modello.totalSeconds;
            variantsDataMap[variantKey].visualization += 1;
            variantsDataMap[variantKey].sessions.add(session.id);
            variantsDataMap[variantKey].uniqueUsers.add(session.device_id);
            if (session.is_first_session) {
              variantsDataMap[variantKey].newUsers.add(session.device_id);
            }
          });
        }

        sessionUniqueUsers.add(session.device_id);
        return total;
      },
      0
    );

    totalSeconds += sessionTotalSeconds;

    // Aggiungi utente unico
    uniqueUsers.add(
      `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device
      }`
    );
    if (session.is_first_session) {
      newUsers.add(
        `${formatDate(session.data_inizio_sessione)}|${session.device_id}|${session.device
        }`
      );
    }

    // Aggrega i dati per dispositivo
    if (service === "APP") {
      const deviceType = session.device_os;
      if (!deviceDataMap[deviceType]) {
        deviceDataMap[deviceType] = {
          type: deviceType,
          count: 0,
          uniqueDevices: new Set(),
        };
      }
      deviceDataMap[deviceType].count += 1;
      deviceDataMap[deviceType].uniqueDevices.add(session.device_id);
      // deviceDataMap[deviceType].visualizations += session.lista_occhiali_visualizzati.length;
    } else if (service === "WEB VTO" || service === "3D VIEWER") {
      const browserType = extractBrowserFromDevice(session.device);
      if (!deviceDataMap[browserType]) {
        deviceDataMap[browserType] = {
          type: browserType,
          count: 0,
          uniqueDevices: new Set(),
        };
      }
      deviceDataMap[browserType].count += 1;
      deviceDataMap[browserType].uniqueDevices.add(session.device_id);
      // deviceDataMap[browserType].visualizations += session.lista_occhiali_visualizzati.length;
    }
  });

  const avgSecondsSession = totalSeconds / filteredSessions.length;
  const avgSecondsEngagement = totalSeconds / totalDevice.size;
  const avgSessionsPerUser = parseFloat(
    (filteredSessions.length / uniqueUsers.size).toFixed(2)
  );

  // Calcola minuti e secondi per averageSessionTime
  const minutesSession = Math.floor(avgSecondsSession / 60);
  const secondsSession = Math.round(avgSecondsSession % 60);

  // Calcola minuti e secondi per averageEngagementTime
  const minutesEngagement = Math.floor(avgSecondsEngagement / 60);
  const secondsEngagement = Math.round(avgSecondsEngagement % 60);

  // Recupera i nomi delle linee da Firebase
  const lineNames = await getLineNames([...lineRefs]);

  // Aggiungi i nomi delle linee a lineDataMap
  Object.keys(lineDataMap).forEach((lineRef) => {
    lineDataMap[lineRef].nome_linea = lineNames[lineRef] || "Unknown Linea";
    const lineData = lineDataMap[lineRef];
    lineData.numberOfSessions = lineData.sessions.size;
    lineData.numberOfUniqueUsers = lineData.uniqueUsers.size;
    lineData.newUsersCount = lineData.newUsers.size;
    lineData.averageSessionTime = calculateAverageSessionTime(
      lineData.time,
      lineData.numberOfSessions
    );
    lineData.averageSessionsPerUser = calculateAverageSessionsPerUser(
      lineData.numberOfSessions,
      lineData.numberOfUniqueUsers
    );
    lineData.averageEngagementTime = calculateAverageEngagementTime(
      lineData.time,
      lineData.numberOfUniqueUsers
    );
  });

  // Converti l'oggetto lineDataMap in un array
  const lineDataArray = Object.values(lineDataMap).map((lineData) => ({
    lineRef: lineData.lineRef,
    nome_linea: lineData.nome_linea,
    time: lineData.time,
    visualization: lineData.visualization,
    numberOfSessions: lineData.numberOfSessions,
    numberOfUniqueUsers: lineData.numberOfUniqueUsers,
    newUsersCount: lineData.newUsersCount,
    averageSessionTime: lineData.averageSessionTime,
    averageSessionsPerUser: lineData.averageSessionsPerUser,
    averageEngagementTime: lineData.averageEngagementTime,
  }));

  // Aggiungi le metriche per glassesDataMap
  Object.keys(glassesDataMap).forEach((glassesRef) => {
    const glassesData = glassesDataMap[glassesRef];
    glassesData.numberOfSessions = glassesData.sessions.size;
    glassesData.numberOfUniqueUsers = glassesData.uniqueUsers.size;
    glassesData.newUsersCount = glassesData.newUsers.size;
    glassesData.averageSessionTime = calculateAverageSessionTime(
      glassesData.time,
      glassesData.numberOfSessions
    );
    glassesData.averageSessionsPerUser = calculateAverageSessionsPerUser(
      glassesData.numberOfSessions,
      glassesData.numberOfUniqueUsers
    );
    glassesData.averageEngagementTime = calculateAverageEngagementTime(
      glassesData.time,
      glassesData.numberOfUniqueUsers
    );
  });

  // Converti l'oggetto glassesDataMap in un array
  const glassesDataArray = Object.values(glassesDataMap).map((glassesData) => ({
    glassesRef: glassesData.glassesRef,
    nome_occhiale: glassesData.nome_occhiale,
    time: glassesData.time,
    visualization: glassesData.visualization,
    numberOfSessions: glassesData.numberOfSessions,
    numberOfUniqueUsers: glassesData.numberOfUniqueUsers,
    newUsersCount: glassesData.newUsersCount,
    averageSessionTime: glassesData.averageSessionTime,
    averageSessionsPerUser: glassesData.averageSessionsPerUser,
    averageEngagementTime: glassesData.averageEngagementTime,
    lineRef: glassesData.lineRef,
  }));

  // Aggiungi le metriche per variantsDataMap
  Object.keys(variantsDataMap).forEach((variantKey) => {
    const variantData = variantsDataMap[variantKey];
    variantData.numberOfSessions = variantData.sessions.size;
    variantData.numberOfUniqueUsers = variantData.uniqueUsers.size;
    variantData.newUsersCount = variantData.newUsers.size;
    variantData.averageSessionTime = calculateAverageSessionTime(
      variantData.time,
      variantData.numberOfSessions
    );
    variantData.averageSessionsPerUser = calculateAverageSessionsPerUser(
      variantData.numberOfSessions,
      variantData.numberOfUniqueUsers
    );
    variantData.averageEngagementTime = calculateAverageEngagementTime(
      variantData.time,
      variantData.numberOfUniqueUsers
    );
  });

  // Converti l'oggetto variantsDataMap in un array e filtra le varianti con tempo minore di MIN_SESSION_DURATION
  const variantsDataArray = Object.values(variantsDataMap)
    .filter((variantData) => variantData.time >= MIN_SESSION_DURATION)
    .map((variantData) => ({
      modelRef: variantData.modelRef,
      glassesRef: variantData.glassesRef,
      lineRef: variantData.lineRef,
      tagliaRef: variantData.tagliaRef,
      esaColorFramePrimary: variantData.esaColorFramePrimary,
      esaColorFrameSecondary: variantData.esaColorFrameSecondary,
      esaColorLensesPrimary: variantData.esaColorLensesPrimary,
      esaColorLensesSecondary: variantData.esaColorLensesSecondary,
      time: variantData.time,
      visualization: variantData.visualization,
      numberOfSessions: variantData.numberOfSessions,
      numberOfUniqueUsers: variantData.numberOfUniqueUsers,
      newUsersCount: variantData.newUsersCount,
      averageSessionTime: variantData.averageSessionTime,
      averageSessionsPerUser: variantData.averageSessionsPerUser,
      averageEngagementTime: variantData.averageEngagementTime,
    }));

  const deviceDataArray = Object.values(deviceDataMap).map((deviceData) => ({
    type: deviceData.type,
    count: deviceData.count,
    uniqueDevices: deviceData.uniqueDevices.size,
  }));

  // Dati aggregati
  const totalSessions = filteredSessions.length;
  const totalUniqueUsers = uniqueUsers.size;
  const newUsersCount = newUsers.size;

  return {
    totalSessions,
    totalUniqueUsers,
    newUsersCount,
    averageSessionTime: { minutes: minutesSession, seconds: secondsSession },
    averageEngagementTime: {
      minutes: minutesEngagement,
      seconds: secondsEngagement,
    },
    averageSessionsPerUser: avgSessionsPerUser,
    lineDataArray,
    glassesDataArray,
    variantsDataArray,
    deviceData: deviceDataArray,
  };
};

export const uploadAnalyticsToFirebase = async (
  metricsData,
  service,
  date,
  brandRef
) => {
  const {
    totalSessions,
    totalUniqueUsers,
    newUsersCount,
    averageSessionTime,
    averageSessionsPerUser,
    averageEngagementTime,
    lineDataArray,
    glassesDataArray,
    variantsDataArray,
    deviceData,
  } = metricsData;

  // // Controlla se tutte le metriche sono zero
  const allMetricsZero =
    totalSessions === 0 &&
    totalUniqueUsers === 0 &&
    newUsersCount === 0 &&
    averageSessionTime.minutes === 0 &&
    averageSessionTime.seconds === 0 &&
    averageSessionsPerUser === 0 &&
    averageEngagementTime.minutes === 0 &&
    averageEngagementTime.seconds === 0;

  if (allMetricsZero) {
    // console.log('Tutte le metriche sono zero. Il dato aggregato non sarà caricato.');
    return;
  }

  try {
    // Crea il documento principale nella collezione Aggregate_Session
    const aggregateSessionDocRef = await db
      .collection("Aggregate_Session")
      .add({
        totalSessions,
        totalUniqueUsers,
        newUsersCount,
        avgSessionTime: averageSessionTime,
        averageSessionsPerUser,
        avgEngagementTime: averageEngagementTime,
        lineArray: lineDataArray,
        service,
        date,
        brand: brandRef.id,
        deviceData: deviceData,
      });

    // Ottieni l'ID del documento creato
    const aggregateSessionDocId = aggregateSessionDocRef.id;

    // Crea il documento nella sottocollezione glassesAnalytics
    await db
      .collection("Aggregate_Session")
      .doc(aggregateSessionDocId)
      .collection("glassesAnalytics")
      .add({
        glassesArray: glassesDataArray,
        variantsArray: variantsDataArray,
      });
    // console.log("LOAD ON FIREBASE")
    // // Crea il documento nella sottocollezione variantAnalytics
    // await db.collection('Aggregate_Session').doc(aggregateSessionDocId).collection('variantAnalytics').add({
    //     variantsArray: variantsDataArray
    // });

    // console.log('Dati caricati con successo su Firebase.');
  } catch (error) {
    console.error("Errore nel caricamento dei dati su Firebase:", error);
  }
};

const checkIfAggregatedDataExists = async (
  formattedDate,
  brandRef,
  service
) => {
  const querySnapshot = await db
    .collection("Aggregate_Session")
    .where("date", "==", formattedDate)
    .where("brand", "==", brandRef.id)
    .where("service", "==", service)
    .get();

  //   console.log( !querySnapshot.empty)
  return !querySnapshot.empty;
};

export const getAggregatedData = async (brandIds, startDate, endDate) => {
  try {
    //   console.log("Init Call Aggregate_Session");

    const query = db
      .collection("Aggregate_Session")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate);

    const snapshot = await query.get();
    const filteredResult = snapshot.docs.filter((session) =>
      brandIds.includes(session.data().brand)
    );
    const aggregatedData = await Promise.all(
      filteredResult.map(async (doc) => ({ ...doc.data(), id: doc.id }))
    );

    return aggregatedData;
  } catch (error) {
    console.error("Error fetching aggregated data:", error);
    return [];
  }
};

const fetchSubcollectionData = async (docId, subcollectionName) => {
  try {
    const subcollectionSnapshot = await db
      .collection("Aggregate_Session")
      .doc(docId)
      .collection(subcollectionName)
      .get();
    const subcollectionData = subcollectionSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return subcollectionData;
  } catch (error) {
    console.error(
      `Error fetching subcollection data (${subcollectionName}):`,
      error
    );
    return [];
  }
};

export const enrichDataWithSubcollections = async (
  aggregateData,
  setLoading
) => {
  try {
    const enrichedDataPromises = aggregateData.map(async (doc) => {
      const glassesAnalytics = await fetchSubcollectionData(
        doc.id,
        "glassesAnalytics"
      );

      const glassesArray =
        glassesAnalytics.length > 0
          ? glassesAnalytics[0].glassesArray || []
          : [];
      const variantsArray =
        glassesAnalytics.length > 0
          ? glassesAnalytics[0].variantsArray || []
          : [];

      return { ...doc, glassesArray, variantsArray };
    });

    const enrichedData = await Promise.all(enrichedDataPromises);
    setLoading(false);
    return enrichedData;
  } catch (error) {
    console.error("Error enriching data with subcollections:", error);
    return aggregateData;
  }
};

// export const fetchSubCollection = async (collection, dataId, subCollectionName) => {
//     //saves the sub collection extracted
//     let allSubCollections = [];
//     const isVariant = subCollectionName == "variantAnalytics";
//     //gets the subscollection
//     console.log("Call SubCollection")

//     const aggRef = await db.collection(collection + "/" + dataId + "/" + subCollectionName).get();
//     if (aggRef.size == 1) {

//         return isVariant ? aggRef.docs[0].data().variantsArray : aggRef.docs[0].data().glassesArray;
//     }
//     else if (aggRef.size > 1) {

//         aggRef.docs.forEach((sub) => {
//             allSubCollections.push(isVariant ? aggRef.docs[0].data().variantsArray : sub.data().glassesArray);
//         })
//         return allSubCollections;
//     }

//     return allSubCollections;
// };

// export const fetchByGlassSubCollection = async (collection, dataId, subCollectionName,glassId) => {
//     //saves the sub collection extracted
//     let allSubCollections = [];
//     const isVariant = subCollectionName == "variantAnalytics";
//     //gets the subscollection

//     console.lòog("Call fetchByGlassSubCollection")

//     const aggRef = await db.collection(collection + "/" + dataId + "/" + subCollectionName).get();
//     if (aggRef.size == 1) {

//         return isVariant ? aggRef.docs[0].data().variantsArray.filter((variant)=> variant.modelRef == glassId) : aggRef.docs[0].data().glassesArray.filter((glass)=> glass.glassesRef == glassId);
//     }
//     else if (aggRef.size > 1) {

//         aggRef.docs.forEach((sub) => {
//             allSubCollections.push(isVariant ? aggRef.docs[0].data().variantsArray : sub.data().glassesArray);
//         })
//         return allSubCollections;
//     }

//     return allSubCollections;
// };

const calculateTotals = (data) => {
  const totals = data.reduce(
    (acc, entry) => {
      acc.totalUniqueUsers += entry.totalUniqueUsers;
      acc.totalSessions += entry.totalSessions;
      acc.newUsersCount += entry.newUsersCount;
      acc.totalSessionTimeSeconds +=
        (entry.avgSessionTime.minutes * 60 + entry.avgSessionTime.seconds) *
        entry.totalSessions;
      return acc;
    },
    {
      totalUniqueUsers: 0,
      totalSessions: 0,
      newUsersCount: 0,
      totalSessionTimeSeconds: 0,
    }
  );

  // Calcola le medie globali
  if (totals.totalSessions > 0) {
    const avgSessionTimeSeconds =
      totals.totalSessionTimeSeconds / totals.totalSessions;
    totals.avgSessionTimeMinutes = Math.floor(avgSessionTimeSeconds / 60);
    totals.avgSessionTimeSeconds = Math.floor(avgSessionTimeSeconds % 60);
  } else {
    totals.avgSessionTimeMinutes = 0;
    totals.avgSessionTimeSeconds = 0;
  }

  if (totals.totalUniqueUsers > 0) {
    const avgEngagementTimeSeconds =
      totals.totalSessionTimeSeconds / totals.totalUniqueUsers;
    totals.avgEngagementTimeMinutes = Math.floor(avgEngagementTimeSeconds / 60);
    totals.avgEngagementTimeSeconds = Math.floor(avgEngagementTimeSeconds % 60);
    totals.avgSessionsPerUser = (
      totals.totalSessions / totals.totalUniqueUsers
    ).toFixed(2);
  } else {
    totals.avgEngagementTimeMinutes = 0;
    totals.avgEngagementTimeSeconds = 0;
    totals.avgSessionsPerUser = 0;
  }

  return totals;
};

export const aggregateDataForAllBrands = async (
  startDate,
  endDate,
  setLoadingMessage,
  setSkippedDays,
  incrementCompletedOperations,
  props,
  setTotalOperations
) => {
  try {
    const brandsSnapshot = await db.collection("Brand").get();
    const brandIds = brandsSnapshot.docs.map((doc) => doc.id);

    const totalOperations = calculateTotalOperations(
      startDate,
      endDate,
      brandIds
    );
    setTotalOperations(totalOperations);

    for (const brandId of brandIds) {
      await aggregateDataForBrand(
        startDate,
        endDate,
        brandId,
        setLoadingMessage,
        setSkippedDays,
        incrementCompletedOperations,
        props
      );
    }
  } catch (error) {
    console.error("Errore nel recupero dei dati dei brand:", error);
  }
};

const calculateTotalOperations = (startDate, endDate, brandIds) => {
  const numberOfDays =
    differenceInDays(new Date(endDate), new Date(startDate)) + 1;
  return numberOfDays * brandIds.length;
};

export const aggregateDataForBrand = async (
  startDate,
  endDate,
  brandId,
  setLoadingMessage,
  setSkippedDays,
  incrementCompletedOperations,
  props
) => {
  const numberOfDays =
    differenceInDays(new Date(endDate), new Date(startDate)) + 1;
  const skippedDays = [];

  for (let i = 0; i < numberOfDays; i++) {
    const currentDate = addDays(new Date(startDate), i);
    const formattedDate = format(currentDate, "yyyy/MM/dd");
    setLoadingMessage(
      `Processing date: ${formattedDate} for brand: ${brandId}`
    );

    const success = await fetchData(formattedDate, brandId, props);
    if (!success) {
      skippedDays.push(formattedDate);
    }
    incrementCompletedOperations();
  }

  setSkippedDays((prevSkippedDays) => [
    ...prevSkippedDays,
    { brandId, skippedDays },
  ]);
  setLoadingMessage(`Processing complete for brand: ${brandId}`);
};

///DOWNLOAD REPORT///

export async function generateReport(startDate, endDate, selectedBrands, selectedOptions) {
  // Formattazione delle date
  const formatDate = date =>
    new Date(date).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '/');

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  try {
    // Assicurati che selectedBrands sia un array di stringhe
    const brandsArray = Array.isArray(selectedBrands) ? selectedBrands.map(String) : [];

    // Eseguo una query per le sessioni nel range di date
    const sessionRef = firebase.firestore().collection('Aggregate_Session')
      .where('date', '>=', formattedStartDate)
      .where('date', '<=', formattedEndDate)
      .get();

    const querySnapshot = await sessionRef;
    const aggregateList = querySnapshot.docs.map(doc => doc.data());

    // Recupero i dati dei brand in base agli ID
    const promises = brandsArray.map(async id => {
      try {
        const brandDataRef = await db.collection("Brand").doc(id).get();
        return { id, ...brandDataRef.data() };
      } catch (error) {
        console.error(`Errore nel recupero dei dati del brand per l'ID ${id}:`, error);
        return null;
      }
    });

    const validResults = (await Promise.all(promises)).filter(Boolean);
    let resultsArray = [];

    // Filtro i dati aggregati per ogni brand e calcolo le metriche
    validResults.forEach(brand => {
      const filteredList = aggregateList.filter(data => data.brand === brand.id);
      const result = getMetricsForReport(filteredList);

      resultsArray.push({
        avgEngTimeTotal: result.avgEngTimeTotal,
        avgSessionPerUserTotal: result.avgSessionPerUserTotal,
        avgSessionTimeTotal: result.avgSessionTimeTotal,
        totalNewUsers: result.totalNewUsers,
        totalSession: result.totalSession,
        totalUsers: result.totalUsers,
        nomeBrand: brand.nome_brand
      });
    });

    // Calcolo le metriche totali
    const brandIds = new Set(validResults.map(brand => brand.id));
    const filteredAggregateList = aggregateList.filter(item => brandIds.has(item.brand));
    const totals = getMetricsForReport(filteredAggregateList);

    resultsArray.push({
      avgEngTimeTotal: totals.avgEngTimeTotal,
      avgSessionPerUserTotal: totals.avgSessionPerUserTotal,
      avgSessionTimeTotal: totals.avgSessionTimeTotal,
      totalNewUsers: totals.totalNewUsers,
      totalSession: totals.totalSession,
      totalUsers: totals.totalUsers,
      nomeBrand: "TOTALS"
    });

    // Selezione dei fogli basata su selectedOptions
    const allData = {};
    const sheetData = {};
    const selectedCategories = [];

    const addCategoryData = (category, data) => {
      const categoryResults = validResults.map(brand => {
        const filteredCategoryList = data.filter(item => item.brand === brand.id);
        const result = getMetricsForReport(filteredCategoryList);
        return {
          avgEngTimeTotal: result.avgEngTimeTotal,
          avgSessionPerUserTotal: result.avgSessionPerUserTotal,
          avgSessionTimeTotal: result.avgSessionTimeTotal,
          totalNewUsers: result.totalNewUsers,
          totalSession: result.totalSession,
          totalUsers: result.totalUsers,
          nomeBrand: brand.nome_brand
        };
      });

      const categoryTotals = getMetricsForReport(data);
      categoryResults.push({
        avgEngTimeTotal: categoryTotals.avgEngTimeTotal,
        avgSessionPerUserTotal: categoryTotals.avgSessionPerUserTotal,
        avgSessionTimeTotal: categoryTotals.avgSessionTimeTotal,
        totalNewUsers: categoryTotals.totalNewUsers,
        totalSession: categoryTotals.totalSession,
        totalUsers: categoryTotals.totalUsers,
        nomeBrand: "TOTALS"
      });

      allData[category] = categoryResults;
      sheetData[category] = data;
      selectedCategories.push(category);
    };

    if (selectedOptions.includes('all')) {
      // Se "ALL SERVICES" è selezionato, aggiungi tutti i dati aggregati
      allData['ALL SERVICES'] = resultsArray;
      ['WEB VTO', '3D VIEWER', 'APP'].forEach(category => {
        addCategoryData(category, aggregateList.filter(item => item.service === category));
      });
    } else {
      selectedOptions.forEach(option => {
        addCategoryData(option, aggregateList.filter(item => item.service === option));
      });
      if (selectedCategories.length > 1 && !selectedOptions.includes('all')) {
        const option1 = selectedCategories[0];
        const option2 = selectedCategories[1];
        addCategoryData("ALL SERVICES", aggregateList.filter(item => (item.service === option1 || item.service === option2)));
      }
    }

    // Genera il file Excel con i dati raccolti
    generateExcelFile(allData, selectedOptions);

  } catch (error) {
    console.error('Errore nella generazione del report:', error);
    throw new Error('Impossibile generare il report');
  }
}

function generateExcelFile(data, selectedOptions) {
  const wb = XLSX.utils.book_new();

  Object.keys(data).forEach(category => {
    const formattedData = data[category].map(item => ({
      nomeBrand: item.nomeBrand,
      avgEngTimeTotal: item.avgEngTimeTotal,
      avgSessionPerUserTotal: item.avgSessionPerUserTotal,
      avgSessionTimeTotal: item.avgSessionTimeTotal,
      totalNewUsers: item.totalNewUsers,
      totalSession: item.totalSession,
      totalUsers: item.totalUsers
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: ["nomeBrand", "avgEngTimeTotal", "avgSessionPerUserTotal", "avgSessionTimeTotal", "totalNewUsers", "totalSession", "totalUsers"]
    });

    const sheetNames = {
      'ALL SERVICES': 'ALL SERVICES',
      'WEB VTO': 'WEB VTO',
      '3D VIEWER': '3D-AR VIEWER',
      'APP': 'VTO APPS',
      'Combined': 'COMBINED DATA'
    };

    const sheetName = sheetNames[category] || 'Report';
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const dateString = `${year}-${month}-${day}`;

  let fileName = `ARShades_Report_${dateString}`;

  // Lista delle opzioni che rappresentano tutte le scelte, ora con 'VTO APPS' al posto di 'APP'
  const allOptions = ['ALL SERVICES', 'WEB VTO', '3D VIEWER', 'VTO APPS'];

  // Controlla se selectedOptions contiene tutte le opzioni, includendo la verifica per 'all' in minuscolo
  const allOptionsSelected = allOptions.every(option => selectedOptions.map(o => o.toUpperCase()).includes(option.toUpperCase())) ||
    selectedOptions.map(o => o.toUpperCase()).includes('ALL');

  if (allOptionsSelected) {
    fileName += '-(ALL)';
  } else if (selectedOptions.length) {
    let optionsString = selectedOptions.map(option => option.toUpperCase()).join(',');
    // Sostituisci 'all' con 'ALL' e 'APP' con 'VTO APPS' nella stringa delle opzioni se presenti
    optionsString = optionsString.replace(/\ball\b/g, 'ALL').replace(/\bAPP\b/g, 'VTO APPS');
    fileName += `-(${optionsString})`;
  }

  fileName += '.xlsx';

  XLSX.writeFile(wb, fileName);
}

// METRICS CON SOLO I TOTALI
function getMetricsForReport(aggregateList) {
  let totalUsers = 0;
  let totalNewUsers = 0;
  let totalSession = 0;
  let totalAvgSessionTime = 0;
  let totalAvgEngTime = 0;
  let totalSessionPerUser = 0;

  // console.log(aggregateList.length);

  // Itera attraverso l'elenco aggregato per calcolare i totali
  aggregateList.forEach(item => {
    totalUsers += item.totalUniqueUsers;
    totalNewUsers += item.newUsersCount;
    totalSession += item.totalSessions;
    totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
    totalSessionPerUser += item.totalSessions / (item.totalUniqueUsers || 1);
    totalAvgEngTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
  });

  // Calcola il tempo medio di sessione e l'engagement time medio
  const avgSessionTimeTotal = totalSession ? convertiSecondiAMinuti(totalAvgSessionTime / totalSession) : 0;
  const avgEngTimeTotal = totalUsers ? convertiSecondiAMinuti(totalAvgEngTime / totalUsers) : 0;
  const avgSessionPerUserTotal = totalUsers ? (totalSession / totalUsers).toFixed(2) : 0;

  // Ritorna solo i totali
  return {
    totalUsers,
    totalNewUsers,
    totalSession,
    avgSessionTimeTotal,
    avgEngTimeTotal,
    avgSessionPerUserTotal
  };
}

// function getMetricsForReport(aggregateList) {
//   const userRecord = {};
//   const newUserRecord = {};
//   const sessionRecord = {};
//   const avgTimeRecord = {};
//   const avgEngTimeRecord = {};
//   const avgSessionPerUserRecord = {};
//   const dateCount = {};
//   let date = new Date();
//   let key = '';
//   let data = null;
//   let sortedData = {};
//   const userSet = new Set();
//   const userVisitTracker = new Set();
//   const userIsNew = new Set();
//   let totalUsers = 0;
//   let totalNewUsers = 0;
//   let totalSession = 0;
//   let totalAvgEngTime = 0;
//   let totalAvgSessionTime = 0;
//   let totalSessionPeruser = 0;

//   const months = [
//     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
//   ];
//   // console.log(aggregateList);
//   aggregateList.map((item) => {
//     // console.log(item)
//     const dateF = new Date(item.date);
//     date = `${dateF.getDate()}/${months[dateF.getMonth()]}/${dateF.getFullYear()}`;

//     totalUsers += item.totalUniqueUsers;
//     totalSession += item.totalSessions;
//     totalNewUsers += item.newUsersCount;
//     totalAvgSessionTime += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
//     totalSessionPeruser += item.averageSessionsPerUser;
//     totalAvgEngTime += getTimeInSecs(item.avgEngagementTime);

//     key = `${date}`;
//     userRecord[date] = userRecord[date] ? userRecord[date] + item.totalUniqueUsers : item.totalUniqueUsers;
//     newUserRecord[date] = newUserRecord[date] ? newUserRecord[date] + item.newUsersCount : item.newUsersCount;
//     sessionRecord[date] = sessionRecord[date] ? sessionRecord[date] + item.totalSessions : item.totalSessions;

//     if (!avgTimeRecord[date]) {
//       avgTimeRecord[date] = { totalSeconds: 0, totalSessions: 0 };
//     }
//     avgTimeRecord[date].totalSeconds += getTimeInSecs(item.avgSessionTime) * item.totalSessions;
//     avgTimeRecord[date].totalSessions += item.totalSessions;

//     if (!avgEngTimeRecord[date]) {
//       avgEngTimeRecord[date] = 0;
//     }
//     avgEngTimeRecord[date] += getTimeInSecs(item.avgEngagementTime) / item.totalUniqueUsers;

//     avgSessionPerUserRecord[date] = (avgSessionPerUserRecord[date] || 0) + (item.totalUniqueUsers === 0 ? 0 : (item.totalSessions / item.totalUniqueUsers));
//   });

//   const avgTimeRecordCopy = JSON.parse(JSON.stringify(avgTimeRecord));

//   // Calculate final average times after the loop
//   Object.keys(avgTimeRecord).forEach(date => {
//     avgTimeRecord[date] = convertiSecondiAMinuti(avgTimeRecord[date].totalSeconds / avgTimeRecord[date].totalSessions);
//   });

//   // Convert avgEngTimeRecord to minutes and fix to 2 decimal places
//   Object.keys(avgEngTimeRecord).forEach(date => {
//     avgEngTimeRecord[date] = convertiSecondiAMinuti(avgTimeRecordCopy[date].totalSeconds / userRecord[date]);
//   });

//   // set user data
//   data = getLinearGraphData('User', userRecord);

//   // sum duplicates and sort
//   sortedData = cleanData({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;

//   const userData = { data, total: totalUsers };

//   // set new user data
//   data = getLinearGraphData('New User', newUserRecord);

//   // sum duplicates and sort
//   sortedData = cleanData({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;

//   const newUserData = { data, total: totalNewUsers };

//   // set session data
//   data = getLinearGraphData('Session', sessionRecord);
//   // sort by date
//   sortedData = sortArrByDate({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;
//   const sessionData = { data, total: totalSession };

//   // set avg time data
//   data = getLinearGraphData('Avg Time', avgTimeRecord);

//   // sum duplicates and sort
//   sortedData = cleanData({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;

//   const avgTimeData = {
//     data,
//     total: convertiSecondiAMinuti(totalAvgSessionTime / totalSession)
//   };

//   // set avg eng time data
//   data = getLinearGraphData('Avg Eng Time', avgEngTimeRecord);

//   // sum duplicates and sort
//   sortedData = cleanData({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;

//   const avgEngTimeData = {
//     data,
//     total: convertiSecondiAMinuti(totalAvgSessionTime / totalUsers),
//   };

//   data = getLinearGraphData('Avg S/U', avgSessionPerUserRecord);

//   // sum duplicates and sort
//   sortedData = cleanData({
//     data: data.datasets[0].data,
//     labels: data.labels,
//   });
//   data.labels = sortedData.labels;
//   data.datasets[0].data = sortedData.data;

//   const avgSessionPerUserData = {
//     data,
//     total: totalSession !== 0 && totalUsers !== 0 ? (totalSession / totalUsers).toFixed(2) : 0,
//   };

//   return { avgSessionPerUserData, avgEngTimeData, avgTimeData, newUserData, sessionData, userData };
// }

export const getTimeInSecs = (data) => {
  let total = data.seconds ?? 0;
  if (data.minutes) {
    total += data.minutes * 60;
  }
  return total;
}

export function convertiSecondiAMinuti(secondi) {
  var minuti = Math.floor(secondi / 60); // Calcola il numero intero dei minuti
  var secondiRimanenti = secondi % 60; // Calcola i secondi rimanenti

  var risultato = minuti + secondiRimanenti / 100; // Combina i minuti e i secondi

  var risultatoLimitato = risultato.toFixed(2); // Limita il risultato ai primi due numeri decimali
  return parseFloat(risultatoLimitato); // Restituisce il valore numerico come float
}
export function getMinSecObject(secondi) {
  var minuti = Math.floor(secondi / 60); // Calcola il numero intero dei minuti
  var secondiRimanenti = secondi % 60; // Calcola i secondi rimanenti
  return {minutes: minuti, seconds:secondiRimanenti}; // Restituisce il valore numerico come float
}

const cleanData = ({ data, labels }) => {
  // sum duplicates
  const uniqueData = sumDuplicates({ data, labels });
  // sort by date
  const sortedData = sortArrByDate({
    data: uniqueData.data,
    labels: uniqueData.labels,
  });
  return sortedData;
};

const sortArrByDate = ({ data, labels }) => {
  const dataArray = labels.map((label, index) => ({
    label,
    value: data[index],
  }));
  dataArray.sort((a, b) => Date.parse(a.label) - Date.parse(b.label));
  return {
    data: dataArray.map((item) => item.value),
    labels: dataArray.map((item) => item.label),
  };
};

const sumDuplicates = ({ data, labels }) => {
  const labelSumMap = {};

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const value = data[i];
    if (labelSumMap[label]) {
      labelSumMap[label] += value;
    } else {
      labelSumMap[label] = value;
    }
  }

  const uniqueLabels = Object.keys(labelSumMap);
  return {
    data: uniqueLabels.map((label) => labelSumMap[label]),
    labels: uniqueLabels,
  };
};
///FINE DOWNLOAD REPORT///