import { Card } from "react-bootstrap";
import React, { useRef, useEffect } from "react";

const HomeCard = ({ leftTitle, leftSubtitle, rightTitle, rightSubtitle, onClickFunction }) => {
  const leftSubtitleRef = useRef(null);
  const rightSubtitleRef = useRef(null);
  const hasBothSubtitles =
    leftSubtitle && rightSubtitle && leftTitle && rightTitle;

  useEffect(() => {
    if (leftSubtitleRef.current || rightSubtitleRef.current) {
      const subtitleWidth =
        (leftSubtitleRef.current?.offsetWidth || 0) +
        (rightSubtitleRef.current?.offsetWidth || 0);
      const cardWidth = subtitleWidth + (hasBothSubtitles ? 450 : 166);
      const cardElement = leftSubtitleRef.current.closest(".custom-card");
      if (cardElement) {
        cardElement.style.width = `${cardWidth}px`;
      }
    }
  }, []);

  // console.log({ leftTitle, leftSubtitle, rightTitle, rightSubtitle });
  return (
    <Card onClick={onClickFunction}>
      <Card.Body className="card-inner">
        <div className="d-flex flex-row">
          <div className="left-content">
            {leftTitle && (
              <Card.Title>
                <h2 className="card-text">{leftTitle}</h2>
              </Card.Title>
            )}
            {leftSubtitle && (
              <Card.Subtitle
                className="mb-2 text-muted card-text"
                ref={leftSubtitleRef}
              >
                {leftSubtitle}
              </Card.Subtitle>
            )}
          </div>
          {hasBothSubtitles && <div className="both-subtitle" />}
          <div className="right-content extend-right-content">
            {rightTitle && (
              <Card.Title>
                <h2 className="card-text">
                  {rightTitle === "NaN" ? 0 : rightTitle}
                </h2>
              </Card.Title>
            )}
            {rightSubtitle && (
              <Card.Subtitle
                className="mb-2 text-muted card-text"
                ref={rightSubtitleRef}
              >
                {rightSubtitle}
              </Card.Subtitle>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default HomeCard;
