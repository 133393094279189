import { format } from "date-fns";
import { db } from "../../../data/base";

const AGGREGATE_SESSION_COLLECTION = "Aggregate_Session";
export const fetchDataByDateBrand = async (collection, brandId, date) => {

    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() + 1);// april 15@000000 + 1 = april 16@000000
    //the specific date query
    const processDate = format(
        new Date(date),
        "yyyy/MM/dd 00:00:00"
    );
    //the date after query
    const processDate2 = format(
        previousDate,
        "yyyy/MM/dd 00:00:00"
    );
    //saves the sessions extracted
    let sessions = [];

    const brandRef = db.collection("Brand").doc(brandId);
    //gets the session where the current date is greater or equal to the set date and lower than the day after
    let dbRef = db
        .collection(collection)
        .where("ref_catalogo", '==', brandRef)
        .where("data_inizio_sessione", ">=", processDate)
        .where("data_inizio_sessione", "<", processDate2)
        .orderBy("data_inizio_sessione");
    const querySnapshot = await dbRef.get();


    querySnapshot.docs.forEach((doc) => {
        const session = doc.data();
        sessions.push({ id: doc.id, ...session, service: getServiceType(collection) });
    });

    await saveDataAggregation(sessions)
    return sessions;
};




const saveDataAggregation = async (sessionList) => {
    let userRecord = 0;
    let newUserRecord = 0;
    let sessionRecord = 0;
    let totalTimeRecord = 0;
    let avgEngTimeRecord = 0;
    let avgSessionPerUserRecord = 0;
    let service = "";
    let date = new Date();
    let key = '';
    const userVisitTracker = new Set();
    const userIsNew = new Set();
    let brandRef = null;
    const mobileData = [];


    //variable will be used to collect all required data.
    const data = {
        lineData: {},
        lineaMap: new Map(),
        lineUsers: new Set(),
        lineSessions: new Set(),
        lineNewUsers: new Set(),
        lineDesktop: 0,
        //glass props
        glassMap: new Map(),
        glassUsers: new Set(),
        glassSessions: new Set(),
        glassNewUsers: new Set(),
        glassDevices: new Set(),
        glassData: {},

        modelNewUsers: new Set(),
        modelUsers: new Set(),
        modelData: {},
        modelMap: new Map(),
        glassId: null,

        lineId: null,
        modelId: null,
        totalLineTime: 0,
        glassTime: 0,
        totalGlassSession: 0,
        totalLineSession: 0,

    }
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];



    for (let i = 0; i < sessionList.length; i++) {
        const item = sessionList[i];
        // count unique user by day
        if (typeof item.total_time === 'undefined') {
            // console.log("TOTAL TIME",item.total_time)
            item.total_time = 1;
        }
        if (brandRef == null) {
            brandRef = item.ref_catalogo;
        }

        const dateF = new Date(item.data_inizio_sessione);
        date = `${dateF.getDate()}/${months[dateF.getMonth()]
            }/${dateF.getFullYear()}`;


        key = `${date}|${item.device_id}|${item.device}`;

        if (!userVisitTracker.has(key)) {
            userRecord = userRecord ? userRecord + 1 : 1;
        }
        if (item.is_first_session && !userIsNew.has(key)) {
            userIsNew.add(item.device_id);
        }

        // Store Mobile Data
        //find if mobile data already exist in the list else update count by 1
        const mobileIndex = mobileData.findIndex((mbData) => mbData.userAgent === getMobileAgent(item.device));
        if (mobileIndex !== -1) {
            mobileData[mobileIndex].totalView += 1;
        } else {
            mobileData.push({ totalViews: 1, userAgent: getMobileAgent(item.device), isMobile: isMobileAgent(item.device) });
        }



        // count total user
        userVisitTracker.add(key);
        // count new user
        if (item.is_first_session) {
            newUserRecord = newUserRecord ? newUserRecord + 1 : 1;
        }

        //set service type;
        if (!service.includes(item.service)) {
            service = item.service
        }
        // count session by day
        sessionRecord = sessionRecord ? sessionRecord + 1 : 1;
        // count avg time by day divided  by session
        // console.log(item);
        totalTimeRecord = (totalTimeRecord || 0) + item.total_time;





        //get line glass amd model data
        await getLineGlassModelData(item, data)

    };

    // count avg session per user by day
    // count avg eng time by day divided by user
    avgSessionPerUserRecord = sessionRecord / userRecord;
    avgEngTimeRecord = (totalTimeRecord / userRecord) / 60;
    const avgTimeRecord = (totalTimeRecord / sessionRecord) / 60;

    //log all data
    // console.log("Line Glas Data ", data);
    // console.log("ARray Line MAP 2 ", data.glassMap.values());
    // console.log("Map ", data.glassTime);
    // console.log("LINEAR ", data.lineaMap.values())
    // console.log(Array.from(data.lineaMap.values())[0])
    // console.log(Array.from(data.glassMap.values())[0])
    // console.log(Array.from(data.modelMap.values())[0])

    await db.collection(AGGREGATE_SESSION_COLLECTION).add({
        user: userRecord,
        newUser: userIsNew.size,
        avgTime: avgTimeRecord,
        avgEngTime: avgEngTimeRecord,
        avgSU: avgSessionPerUserRecord,
        date: date,
        session: sessionRecord,
        brandRef: brandRef,
        services: service,
        line_list: Array.from(data.lineaMap.values()),
        glasses_list: Array.from(data.glassMap.values()),
        variant_list: Array.from(data.modelMap.values()),
        listOfDevice: mobileData

    }).then((result) => {
        // console.log(result);
    })
}


const getServiceType = (collection) => {
    switch (collection) {
        case "Sessione":
            return "VTO Apps";
        case "Sessione_Visualizzatori3d":
            return "3D/AR View"

        case "Sessione_WebVto":
            return "WebVto";
    }
}


const getLineGlassModelData = async (session, data) => {


    //loop through glass list added
    for (let i = 0; i < session.lista_occhiali_visualizzati.length; i++) {
        const item = session.lista_occhiali_visualizzati[i];
        data.glassId = item.glassesRef?.id ?? item.glassesRefs?.id;
        data.glassUsers.add(`${data.glassId}-${item.device_id}`); // users of glass 
        data.glassSessions.add(`${data.glassId}-${item.id}`); //gets session of glass to the list
        data.glassDevices.add(`${data.glassId}-${item.device_id}`); //gets devices of glass to the list

        //increment mobile and desktop if the devices does not exist
        if (isMobileAgent(session.device) && (!data.lineUsers.has(`${data.lineId}-${session.device_id}`))) {
            data.lineMobile = (data.lineMobile || 0) + 1;
        }
        //if not mobile and it has not been added previously 
        if (!isMobileAgent(session.device) && (!data.lineUsers.has(`${data.lineId}-${session.device_id}`))) {
            data.lineDesktop = (data.lineDesktop || 0) + 1;
        }
        data.lineId = item.lineaRef?.id ?? item.lineaRefs?.id;
        data.lineUsers.add(`${data.lineId}-${session.device_id}`); // users of line and devices 
        data.lineSessions.add(`${data.lineId}-${session.id}`); //gets session of line to the list


        //increments if user is new for line and glass
        if (session.is_first_session) {
            data.lineNewUsers.add(`${data.lineId}-${session.device_id}`);
            data.glassNewUsers.add(`${data.glassId}-${session.device_id}`);
        }




        data.glassSessionNumber = item.listaModelliProvati?.length;
        // calculate modal data
        data.totalGlassSession += item.listaModelliProvati?.length || 0;
        for (let i = 0; i < item.listaModelliProvati.length; i++) {
            const variantSession = item.listaModelliProvati[i];
            try {
                data.modelId = variantSession.modelRef.id;
                data.modelUsers.add(`${data.modelId}-${session.device_id}`); // users of line 
                // data.totalGlassTime += variantSession.totalSeconds;
                data.glassTime += variantSession.totalSeconds;
                if (session.is_first_session) {
                    data.modelNewUsers.add(`${data.modelId}-${session.device_id}`);
                }

                let modelData = data.modelMap.get(data.modelId);
                const modelSnapShot = await db.collection("Modello").doc(data.modelId).get();
                const modello = modelSnapShot.data();
                //create a new modeldata if it does not exist
                if (!modelData) {
                    data.modelMap.set(data.modelId, {
                        views: 1, time: variantSession.totalSeconds, nameOfVariant: modello.nomeOcchiale,
                        firstFrameEsa: modello.esaColorFramePrimary, sendondFrameEsa: modello.esaColorFrameSecondary,
                        firstLensEsa: modello.esaColorLensesPrimary, secondLensEsa: modello.esaColorLensesSecondary,
                        view: 1, lineRef: item.lineaRef,
                        glassesRef: item.glassesRef, VariantRef: modelSnapShot.ref
                    })
                } else {
                    modelData = {
                        time: (modelData.time || 0) + (variantSession.totalSeconds || 0), nameOfLine: modello.nomeOcchiale,
                        firstFrameEsa: modello.esaColorFramePrimary, sendondFrameEsa: modello.esaColorFrameSecondary,
                        firstLensEsa: modello.esaColorLensesPrimary, secondLensEsa: modello.esaColorLensesSecondary,
                        view: modelData.view + 1, lineRef: item.lineaRef,
                        glassesRef: item.glassesRef, VariantRef: modelSnapShot.ref
                    }
                    //       
                    data.modelMap.set(data.modelId, modelData);

                }


            } catch (error) {
                // console.log(list)
            }
            // calculate glass data
            data.totalLineTime += data.glassTime;
            data.totalLineSession += data.glassSessionNumber || 0;

            //save glass data
            let glassData = data.glassMap.get(data.glassId);
            if (!glassData) {
                data.glassMap.set(data.glassId, {
                    views: data.glassSessionNumber, total: data.glassTime, nameOfGlasses: item.nome_occhiale,
                    user: data.glassUsers.size, session: data.glassSessions.size, newUser: data.glassNewUsers.size, avgTime: (session.total_time / (data.glassSessionNumber ?? 1)),
                    avgSU: data.glassSessions.size / data.glassUsers.size, totalDevice: data.glassDevices.size, mobileDevice: isMobileAgent(session.device) ? 1 : 0,
                    desktopDevice: isMobileAgent(session.device) ? 0 : 1
                })
            } else {
                glassData = {
                    views: glassData.views + data.glassSessionNumber,
                    total: glassData.total + data.glassTime, nameOfLine: item.nome_occhiale,
                    user: glassData.user + data.glassUsers.size, session: glassData.session + data.glassSessions.size,
                    newUser: glassData.newUser + data.glassNewUsers.size,
                    avgTime: (glassData.avgTime) + (data.glassTime / data.glassSessionNumber),
                    avgSU: (glassData.avgSU) + (data.glassSessions.size / data.glassUsers.size), totalDevice: data.glassUsers.size,
                    mobileDevice: isMobileAgent(session.device) ? glassData.mobileDevice + 1 : glassData.mobileDevice,
                    desktopDevice: isMobileAgent(session.device) ? glassData.desktopDevice : glassData.desktopDevice + 1
                }
                //       
                data.glassMap.set(data.glassId, glassData)

            }


        }
    }

    let lineData = data.lineaMap.get(data.lineId);
    const lineSnapShot = await db.collection("Linea").doc(data.lineId).get();
    const line = lineSnapShot.data();

    // create line data if it does not exist
    if (!lineData) {
        data.lineaMap.set(data.lineId, {
            views: data.totalLineSession, total: data.totalLineTime, nameOfLine: line.nome_linea,
            user: data.lineUsers.size, session: data.lineSessions.size, newUser: data.lineNewUsers.size, avgTime: data.totalLineTime / data.totalLineSession,
            avgSU: data.totalLineTime / data.lineUsers.size, totalDevice: data.lineUsers.size, mobileDevice: data.lineMobile,
            desktopDevice: data.lineDesktop
        })


    } else {
        lineData = {
            views: lineData.views + data.totalLineSession,
            total: lineData.total + data.totalLineTime, nameOfLine: line.nome_linea,
            user: lineData.user + data.lineUsers.size, session: lineData.session + data.lineSessions.size,
            newUser: lineData.newUser + data.lineNewUsers.size,
            avgTime: lineData.avgTime + (data.totalLineTime / data.lineSessions.size),
            avgSU: lineData.avgSU + (data.lineSessions.size / data.lineUsers.size), totalDevice: data.lineUsers.size,
            mobileDevice: lineData.mobileDevice + data.lineMobile,
            desktopDevice: (lineData.desktopDevice || 0) + (data.lineDesktop ?? 0)
        }
        //       
        data.lineaMap.set(lineSnapShot, lineData)

    }

    return data;

}


const getSessionAndDuration = (data, key, time, sessionCount, glassId) => {
    if (data['total']) {
        data['total'].sessions += sessionCount || 1;
        data['total'].duration += time;
    } else {
        data['total'] = {
            sessions: sessionCount || 1,
            duration: time,
        };
    }

    if (data[key]) {
        data[key].sessions += sessionCount || 1;
        data[key].duration += time;
    } else {
        data[key] = {
            sessions: sessionCount || 1,
            duration: time,
        };
    }
    if (glassId) {
        data[key].glassId = glassId;
    }

    return data;
};


function isMobileAgent(data) {
    const keywords = ['iPhone', 'iPad', 'Android', 'Mobile', 'SDK'];

    for (const keyword of keywords) {
        if (data.includes(keyword)) {
            return true;
        }
    }

    return false;
}
function getMobileAgent(data) {
    const keywords = ['iPad', 'iPhone', 'Android', 'Windows', 'MacBook'];

    for (const keyword of keywords) {
        if (data.includes(keyword)) {
            return keyword;
        }
    }

    return 'Android';
}


export const getTimeInSecs = (data) => {
    let total = data.seconds ?? 0;
    if (data.minutes) {
        total += data.minutes * 60;
    }
    return total;
}