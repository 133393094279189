import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { startOfDay } from "date-fns";
import { Button } from "react-bootstrap";

import { fetchSubs, getData, getMeterSDKLicense, getStartDate, getSubscriptionData } from "../../services/dataConsumption";
import { Col, FormGroup, Input, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { ListAllBrands, getBrandsByRef } from "../../services/home";



const Charts = () => {
  // const rxBrands = useSelector((state) => state.config.listaBrand);
  // const { selectedBrand } = useSelector((state) => state);
  // const [barChartData, setBarChartData] = useState([]);
  // const [lineChartData, setLineChartData] = useState([]);
  // const [startDateLicence, setStartDateLicence] = useState(null)
  // const [bandwidthDetails, setBandwidthDetail] = useState({
  //   totalBandWidth: 0,
  //   dataRange: "",
  // })

  // useEffect(async() => {

  //   await getData({
  //     startDate,
  //     endDate,
  //     setCallDetails,
  //     setLineChartData,
  //     setBarChartData,
  //     selectedBrandId:
  //       selectedBrand.id === "all" ? "all" : selectedBrand.data_consumption,

  //     bandwidthType,
  //     brandList,
  //     setBandwidthDetail
  //   });
  // }, [selectedBrand.data_consumption, selectedBrand.id, brandList]);

  // useEffect(() => {
  //   console.log("BRAND LIST",rxBrands);
  //   ListAllBrands(rxBrands, [], setBrandList);
  // }, [rxBrands]);

  const [brandList, setBrandList] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [meterSubs, setMeterSubs] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );

  const [initialDate, setInitialDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [bandwidthType, setBandwidthType] = useState("all");

  const [callsDetails, setCallDetails] = useState({
    totalCalls: 0,
    dateRange: "",
  });

  const [displayData, setDisplayData] = useState([]);
  const [meterData, setMeterData] = useState([]);

  const getTime = (date) => {
    const milliseconds = date.seconds * 1000 + Math.round(date.nanoseconds / 1000000);
    const output = new Date(milliseconds);
    return output.getMonth() + '/' + output.getFullYear();
  }



  const handleApplyFilter = async () => {
    const allData = [];
    // console.log(brandList);
    brandList.map(async (brands) => {
      if (brands.length > 0) {

        const { barChartData, lineChartData, bandwidthDetails, callsDetails } = await getSubscriptionData({ startDate, endDate, bandwidthType, brandList: brands, subscriptions: subscriptions });
        allData.push({ barChartData, lineChartData, bandwidthDetails, callsDetails });

        setDisplayData([...allData]);

      }
    })

  };

  const handleClearFilter = async () => {
    // Reset filters
    setStartDate(null);
    setEndDate(null);
    setBandwidthType("all");
    setStartDate(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    );
    setEndDate(new Date());
    const allData = [];
    brandList.map(async (brands) => {
      if (brands.length > 0) {

        const { barChartData, lineChartData, bandwidthDetails, callsDetails } = await getSubscriptionData({ startDate, endDate, bandwidthType, brandList: brands, subscriptions: subscriptions });
        allData.push({ barChartData, lineChartData, bandwidthDetails, callsDetails });

        setDisplayData([...allData]);

      }
    })
  };

  useEffect(() => {
    const allData = [];
    const allBrands = [];
    const userBrands = localStorage.getItem("brands"); // catalogList del profilo
    // subitem = sottoscrizioni

    // console.log("brands", userBrands);
    fetchSubs(setSubscriptions)
      .then((fetched) => {
        const activeSubscriptions = [];
        fetched.forEach((subItem, index) => {
          // console.log("Fetched brand SOTTOSCRIZIONE", subItem.catalogList.catalogRefList, "Sottoscrizione", subItem.store);
          // console.log("PROFILE brands", userBrands);
          if (subItem.catalogList && subItem.catalogList.catalogRefList && subItem.catalogList.catalogRefList.length > 0 && subItem.catalogList.catalogRefList.some(ref => userBrands.includes(ref))) {
            // console.log("Prova fetched aggiornata SOTTOSCRIZIONE", subItem.catalogList.catalogRefList, "Sottoscizione", subItem.store);

            getBrandsByRef(subItem.catalogList.catalogRefList, [])
              .then(async (brands) => {


                // Assicurati che solo la lista dei brand dell'utente venga accessibile
                if (brands.length > 0) {
                  allBrands.push(brands);
                  setBrandList([...allBrands]);
                  // console.log("brand", brands);



                  const { barChartData, lineChartData, bandwidthDetails, callsDetails } = await getSubscriptionData({
                    startDate,
                    endDate,
                    bandwidthType,
                    brandList: brands,
                    subStartDate: subItem?.startDate,
                    subscriptions: subItem
                  });
                  // console.log("all brands", allBrands);

                  allData.push({ barChartData, lineChartData, bandwidthDetails, callsDetails, brands });
                  activeSubscriptions.push(subItem);
                  // console.log("all data", allData);
                  setDisplayData([...allData]);
                }
              });
          }
        })
        setSubscriptions(activeSubscriptions);
      });
  }, [])

  useEffect(() => {
    const allData = [];

    fetchSubs(setMeterSubs, false)
      .then(async (fetched) => {
        for (let i = 0; i < fetched.length; i++) {
          const subItem = fetched[i];

          if (!subItem.arpd_meter_consumption) {
            allData.push({});
            return
          }
          const { barChartData, callsDetails } = await getMeterSDKLicense({ startDate, endDate, bandwidthType, subscription: subItem });
          // console.log(barChartData, callsDetails, "barChartData, callsDetails")
          allData.push({ barChartData, callsDetails });
          // console.log("ASSINGING", allData);
          setMeterData([...allData])
        }
      });

  }, [])



  const optionLine = {
    responsive: true,
  }

  return (
    <div className="charts-container">
      <div style={{ position: "relative" }}>
        <div className="date-range-filter">
          <p className="page-heading-text">
            Select Monthly Range and Bandwidth Type:
          </p>
          <Row style={{ rowGap: "10px" }}>
            <Col lg={2} md={4} xs={6}>
              <DatePicker
                id="start-date"
                className="filter-datepicker"
                selected={startDate}
                onChange={(date) => setStartDate(startOfDay(date))}
                selectsStart
                startDate={startDate}
                placeholderText="Choose month"
                minDate={initialDate}
                endDate={endDate}
                maxDate={endDate}
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
            </Col>
            <Col lg={2} md={4} xs={6}>
              <DatePicker
                id="end-date"
                className="filter-datepicker"
                selected={endDate}
                onChange={(date) => setEndDate(startOfDay(date))}
                selectsEnd
                placeholderText="Choose month"
                startDate={startDate}
                endDate={endDate}
                minDate={initialDate}
                showMonthYearPicker
                maxDate={new Date()}
                dateFormat="MM/yyyy"
              />
            </Col>
            {/* <Col lg={3} md={4}>
              <FormGroup>
                <Input
                  type="select"
                  placeholder="Select brand..."
                  onChange={(e) => setSelectedBrandId(e.target.value)}
                >
                  {brandList.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.nome_brand}
                    </option>
                  ))}
                  <option value="">ALL</option>
                </Input>
              </FormGroup>
            </Col> */}
            <Col lg={3} md={4}>
              <FormGroup>
                <Input
                  type="select"
                  placeholder="Select brand..."
                  onChange={(e) => setBandwidthType(e.target.value)}
                  value={bandwidthType}
                >
                  {[
                    { value: "all", name: "All" },
                    {
                      value: "bandwidth_3dviewer",
                      name: "3D/AR Viewer",
                    },
                    {
                      value: "storage_data",
                      name: "Storage Data",
                    },
                    { value: "bandwidth_array", name: "APPs" },
                    { value: "bandwidth_webVto", name: " WebVto" },
                  ].map((brand) => (
                    <option key={brand.value} value={brand.value}>
                      {brand.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={5} className="d-flex" style={{ gap: "1rem" }}>
              <Button
                type="button"
                onClick={handleApplyFilter}
                className="filter-form-button apply-button"
                disabled={!startDate || !endDate}
              >
                Apply Filter
              </Button>
              <Button
                type="button"
                onClick={handleClearFilter}
                className="filter-form-button clear-button"
                disabled={!startDate || !endDate}
              >
                Clear Filter
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      {/* SENZA CONTROLLO */}

      {/* {subscriptions.map((subItem, index) => {
        // Controlla se lo stato del documento di ARShades_Subscription è diverso da Active, se lo è mostriamo
        // solo i nomi e non la tabella col grafico
        if (subItem.status !== "Active") {
          return (
            <div key={index} style={{ marginBottom: 50 }}>
              <h4 className="text-danger">{subItem.subscriptionType} - {subItem.store} </h4>
              <h7> Current status: {subItem.status} </h7>
            </div>
          );
        } */}

      {/* CONTROLLO DELLE SOTTOSCRIZIONI CON STESSO MAINSUBSCRIPTIONREF - MOSTRANDO TABELLE */}

      {subscriptions.map((subItem, index) => {
        // Controllo se lo stato del documento di ARShades_Subscription è diverso da Active
        if (subItem.status !== "Active") {
          return (
            <div key={index} style={{ marginBottom: 50 }}>
              <h4 className="text-danger">{subItem.subscriptionType} - {subItem.store}</h4>
              <h6> Current status: {subItem.status} </h6>
            </div>
          );
        }

        // // Trova duplicati con lo stesso mainSubscriptionRef
        // const duplicateSubscriptions = subItem.mainSubscriptionRef
        //   ? subscriptions.filter(
        //     (item, idx) => item.mainSubscriptionRef === subItem.mainSubscriptionRef && idx !== index
        //   )
        //   : [];

        // // Mostra solo la prima sottoscrizione e raccogli i duplicati
        // if (
        //   subItem.mainSubscriptionRef &&
        //   duplicateSubscriptions.length > 0 &&
        //   subscriptions.slice(0, index).some(prevSub => prevSub.mainSubscriptionRef === subItem.mainSubscriptionRef)
        // ) {
        //   return null; // Non mostrare se c'è già una sottoscrizione con lo stesso mainSubscriptionRef
        // }

        return (
          <div key={index} style={{ marginBottom: 50 }}>
            <h4 className="text-danger">{subItem.subscriptionType} - {subItem.store}</h4>
            {/* {duplicateSubscriptions.length > 0 && (
              <div>
                <h5>Altre licenze con lo stesso mainSubscriptionRef:</h5>
                {duplicateSubscriptions.map((dupItem, dupIndex) => (
                  <p key={dupIndex} className="text-primary">
                    {dupItem.subscriptionType} - {dupItem.store}
                  </p>
                ))}
              </div>
            )} */}
            {displayData?.[index] != null && (
              <Row style={{ rowGap: "10px" }}>
                <Col lg={6} md={6} xs={12}>
                  <div className="pie-chart">
                    <div className="pie-chart-content">
                      <div className="chart-header">
                        <p className="chart-header-text">Bandwidth Chart</p>
                      </div>
                      <div className="bar-chart-heading-container">
                        <div className="bar-chart-heading-title">
                          {displayData?.[index].bandwidthDetails?.totalBandWidth}{" "}Gb
                        </div>
                        <div className="bar-chart-heading-subTitle">
                          /{subItem.catalogList.consumptionsLimit} Gb{" "}
                        </div>
                        <div className="bar-chart-data">
                          {getTime(subItem.startDate)} - {getTime(subItem.endDate)}
                        </div>
                      </div>
                      <div className="pie-chart-container">
                        {displayData?.[index].lineChartData?.datasets?.length &&
                          !displayData?.[index].lineChartData?.datasets[0]?.data?.every(
                            (item) => item === null || item === undefined
                          ) ? (
                          <Line data={displayData?.[index].lineChartData} option={optionLine} />
                        ) : (
                          <p className="chart-no-data-found-text">No Data Found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <div className="pie-chart">
                    <div className="pie-chart-content">
                      <div className="chart-header">
                        <p className="chart-header-text">Number of Calls Made</p>
                      </div>
                      <div className="bar-chart-heading-container">
                        {displayData?.[index].barChartData?.datasets?.length &&
                          !displayData?.[index].barChartData?.datasets[0]?.data?.every(
                            (item) => item === null || item === undefined
                          ) ? (
                          <>
                            <div className="bar-chart-heading-title">
                              {displayData?.[index].callsDetails.totalCalls.toLocaleString()}
                            </div>
                            <div className="bar-chart-heading-subTitle">
                              Calls{" "}
                            </div>
                            <div className="bar-chart-data">
                              {displayData?.[index].callsDetails.totalCalls ? callsDetails.dateRange : ""}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="pie-chart-container">
                        {displayData?.[index].barChartData?.datasets?.length &&
                          !displayData?.[index].barChartData?.datasets[0]?.data?.every(
                            (item) => item === null || item === undefined
                          ) ? (
                          <Bar data={displayData?.[index].barChartData} />
                        ) : (
                          <p className="chart-no-data-found-text">No Data Found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        );
      })}

      {meterSubs.map((subItem, index) => {
        return (meterData?.[index] != null && <div key={index} style={{ marginBottom: 50 }}>
          <h4 className="text-danger">{subItem.type} - {subItem.subscription_title}</h4>
          <Row style={{ rowGap: "10px", }}>
            <Col lg={6} md={6} xs={12}>

              <div className="pie-chart">
                <div className="pie-chart-content">
                  <div className="chart-header">
                    <p className="chart-header-text">Number of Calls Made</p>
                  </div>
                  <div className="bar-chart-heading-container">
                    {meterData?.[index].barChartData?.datasets?.length &&
                      !meterData?.[index].barChartData?.datasets[0]?.data?.every(
                        (item) => item === null || item === undefined
                      ) ? (
                      <>
                        <div className="bar-chart-heading-title">
                          {meterData?.[index].callsDetails.totalCalls.toLocaleString()}
                        </div>

                        <div className="bar-chart-heading-subTitle">
                          Calls{" "}
                        </div>
                        <div className="bar-chart-data">
                          {meterData?.[index].callsDetails.totalCalls ? callsDetails.dateRange : ""}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="pie-chart-container">
                    {meterData?.[index].barChartData?.datasets?.length &&
                      !meterData?.[index].barChartData?.datasets[0]?.data?.every(
                        (item) => item === null || item === undefined
                      ) ? (
                      <Bar data={meterData?.[index].barChartData} />
                    ) : (
                      <p className="chart-no-data-found-text">No Data Found</p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>)
      })}
    </div>
  );
};

export default Charts;