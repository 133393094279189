import React, { useState, useEffect } from "react";
import logoAr from "../../assets/images/logo/ARSHADES_AR_LOGO.svg";
import BackDropTransparent from "./BackDropTransparent";
import ModalQR from "./ModalQR";
import { useSelector } from "react-redux";
// import { generalActions } from "../../redux/generalConfig";
import styles from "./ButtonAr.module.css";
import { isMobile } from "react-device-detect";
import  {useLocation}  from 'react-router-dom';

function ButtonAr(props) {
  // const idConfig = useSelector((state) =>
  //   state.config.find((config) => config.id === "general")
  // );

  // const currentUrl = window.parent.location.href;

  //   const iframe = document.getElementById("myIframe");
  // const currentUrl = iframe?.contentWindow?.location.href ?? window.parent.location.href ;
  // console.log("QRCODE",currentUrl);

  // oppure

  // const iframe = document.getElementById("myIframe");
  // const currentUrl = iframe.contentWindow.location.href;
  // console.log(currentUrl);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const mirror = queryParams?.get('mirror') ? true :false;


  const [isWebView, setIsWebView] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function modalHandler() {
    setModalOpen(!modalOpen);
  }

  const project = useSelector((state) => state.brandViewer);

  const currentUrl = props.urlView;
  // console.log(currentUrl);

  const bgColor = project[0]?.qrBackGroundColor ?? "#ffffff";
  const fgColor = project[0]?.qrForeGroundColor ?? "#000000";

  function closeModal() {
    //  dispatch(generalActions.setModalQr({ idConfig: "general" }));
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isWebView = /\bwv\b/.test(userAgent);
    if (isWebView) {
      setIsWebView(true);
    }
  });

  return (
    <>
      {!isWebView && (
        <>
          {(isMobile && !mirror) && (
            <button slot="ar-button" className={styles.buttonARBottom}>
              <img
                src={project[0]?.ar_icon || logoAr}
                style={{ width: "40px", height: "40px" }}
                alt="ar"
              />
            </button>
          )}
          {(!isMobile || mirror) &&
            !(
              props.project?.menu_position === "left" ||
              props.project?.menu_position === "right"
            ) && (
              <div className={styles["button-right-bottom"]}>
                <button className={styles.button} onClick={modalHandler}>
                  <img
                    src={project[0]?.ar_icon || logoAr}
                    style={{ width: "40px", height: "40px" }}
                    alt="ar"
                  />
                </button>

                {modalOpen && (
                  <ModalQR
                    fgColor={fgColor}
                    bgColor={bgColor}
                    project={props.project}
                    url={currentUrl}
                  />
                )}
                {modalOpen && (
                  <BackDropTransparent grey onCancel={modalHandler} />
                )}
              </div>
            )}
        </>
      )}

      {isWebView && (
        <button slot="ar-button" className={styles.buttonARBottomWebView}>
          View in AR
        </button>
      )}
    </>
  );
}

export default ButtonAr;
